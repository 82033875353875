//Common
export const SET_ERRORS = "SET_ERRORS"

//Admins
export const PRODUCT_PARAGRAPH = "PRODUCT_PARAGRAPH"
export const GET_ALL_CONTACT_US_RESPONSE = "GET_ALL_CONTACT_US_RESPONSE"
export const SET_ADMIN = "SET_ADMIN"
export const ADMIN_LOADING = "ADMIN_LOADING"
export const ADMIN_LOADED = "ADMIN_LOADED"

export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const DELETE_AWARD = "DELETE_AWARD"
export const DELETE_PROJECT = "DELETE_PROJECT"
export const DELETE_INTERLOCK = "DELETE_INTERLOCK"
export const DELETE_INTERLOCK_DESIGN = "DELETE_INTERLOCK_DESIGN"
export const DELETE_BLOCK = "DELETE_BLOCK"
export const GET_ALL_CAREER_RESPONSE = "GET_ALL_CAREER_RESPONSE"
export const DELETE_CONTACT_US = "DELETE_CONTACT_US"
export const DELETE_CAREER = "DELETE_CAREER"
export const GET_DASHBOARD = "GET_DASHBOARD"


//Public
export const GET_ALL_BLOCKS = "GET_ALL_BLOCKS"
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS"
export const GET_ALL_AWARDS = "GET_ALL_AWARDS"
export const GET_ALL_INTERLOCKS = "GET_ALL_INTERLOCKS"
export const GET_SINGLE_AWARDS = "GET_SINGLE_AWARDS"
export const GET_ALL_INTERLOCK_DESIGN = "GET_ALL_INTERLOCK_DESIGN"
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY"
export const GET_HOME_CONTENT = "GET_HOME_CONTENT"
export const GET_ABOUT_CONTENT = "GET_ABOUT_CONTENT"
export const GET_CONTACTUS_CONTENT = "GET_CONTACTUS_CONTENT"
export const GET_SINGLE_BLOCK = "GET_SINGLE_BLOCK"
export const GET_SINGLE_INTERLOCK = "GET_SINGLE_INTERLOCK"
export const GET_CAREER_CONTENT = "GET_CAREER_CONTENT"
