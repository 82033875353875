import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getCareerContent } from '../../../store/actions/publicActions'
import { updateCareerPageSetting } from '../../../store/actions/adminActions'
import PropTypes from 'prop-types';
import Spinner from '../../common/Spinner';
import { NotificationManager } from 'react-notifications';
import { getBase64, uploadedImageName } from '../../common/Base64'

class CareerPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            id: '',
            email: '',
            imageFileName: '',
            image: '',
            description: '',
            careerPage: {}
        }
    }

    componentDidMount() {
        this.props.getCareerContent()
    }
    static getDerivedStateFromProps(props, state) {
        const { loading, careerPage } = props.admin;

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        if (JSON.stringify(careerPage) !== JSON.stringify(state.careerPage)) {
            state.careerPage = careerPage;
            state.id = careerPage.id;
            state.imageFileName = careerPage.image;
            state.email = careerPage.email
            state.description = careerPage.description
        }

        return state
    }

    handleValidation() {
        const fields = this.state;
        let formIsValid = true;
        if (!fields["description"]) {
            formIsValid = false;
            NotificationManager.error('description field is required')
        }
        if (!fields["email"]) {
            formIsValid = false;
            NotificationManager.error('Email field is required')
        }

        return formIsValid;
    }


    imageHandler = event => {
        this.setState({
            [event.target.name]: event.target.files[0]
        })
    }
    onSubmitHandler = async (e) => {
        e.preventDefault()
        if (!this.handleValidation()) {
            return
        }
        const image = await getBase64(this.state.image);
        const data = {
            id: this.state.id,
            email: this.state.email,
            description: this.state.description,
            image
        }
        console.log('data', data)
        this.props.updateCareerPageSetting(data, this.props.history)
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    render() {
        const { loading, description, email, image, imageFileName } = this.state;
        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="card contact-card">
                    <form onSubmit={this.onSubmitHandler}>
                        <div className="card-body">
                            <div className="form-group small-section" style={{ display: 'none' }}>
                                <label>Email :</label>
                                <input type="email" onChange={this.onChangeHandler} name="email" value={email} className="form-control" placeholder="Please Enter Email" />
                            </div>
                            <div className="form-group">
                                <label>Image</label>
                                <div className="upload-btn-wrapper">
                                    <button className="btn">{image?.name ?? uploadedImageName(imageFileName)}</button>
                                    <input
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        onChange={this.imageHandler}
                                    />
                                </div>

                            </div>
                            <div className="form-group">
                                <label>Paragraph Text:</label>
                                <textarea type="text" onChange={this.onChangeHandler} name="description" value={description} className="form-control" placeholder="Enter Paragraph" ></textarea>
                            </div>
                        </div>

                        <div className="card-footer">
                            <button type="submit" className="btn submit-btn mr-2">Submit</button>
                            <button type="reset" className="btn cancel-btn">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Career Page</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}

CareerPage.propTypes = {
    getCareerContent: PropTypes.func.isRequired,
    updateCareerPageSetting: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { getCareerContent, updateCareerPageSetting })(withRouter(CareerPage))