import React, { Component } from 'react'
import { getAllAwards } from '../../../store/actions/publicActions'
import { deleteAward } from '../../../store/actions/adminActions'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Spinner from '../../common/Spinner';
import ShowMoreText from 'react-show-more-text';


class Awards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            awards: []
        }
    }
    componentDidMount() {
        this.props.getAllAwards()
    }

    static getDerivedStateFromProps(props, state) {
        const { loading, awards } = props.admin;

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        if (JSON.stringify(awards) !== JSON.stringify(state.awards)) {
            state.awards = awards
        }
        return state
    }

    EditHandler = (id) => {
        this.props.history.push("/admin/awards/edit/" + id)
    }

    render() {
        const { loading, awards } = this.state;

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="add-btn-container">
                    <Link to="/admin/awards/add">
                        <button type="button">Add Awards</button>
                    </Link>
                </div>
                <div className="card contact-card message-response all-awards">
                    <form>
                        <div className="card-body">
                            {awards && awards.length ? <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="name">Name</th>
                                        <th scope="col" className="image">Image</th>
                                        <th scope="col" className="image">Logo</th>
                                        <th scope="col" className="description">Description</th>
                                        <th scope="col" className="action-col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {awards.map((item, index) => {
                                        return <tr key={index}>
                                            <td>{item.name}</td>
                                            <td className="image-td">
                                                <img src={item.image1} alt="award" className="green-img" />
                                            </td>
                                            <td className="image-td">
                                                <img src={item.logo} alt="award logo" className="green-img" />
                                            </td>

                                            <td> <ShowMoreText
                                                more='Show More'
                                                less='Show Less'
                                            >{item.description}</ShowMoreText> </td>
                                            <td className="action-btn">
                                                <button type="button" onClick={() => this.EditHandler(item.id)} className="form-control">Edit</button>
                                                <button type="button" onClick={() => { if (window.confirm('Delete the Award?')) { this.props.deleteAward(item.id) } }} className="form-control"> Delete</button>
                                            </td>
                                        </tr>
                                    })}

                                </tbody>
                            </table> : <h2>No Awards is Available in Database</h2>}
                        </div>
                    </form>
                </div>
            </div>
        }
        return (
            <div className="dashboard-container">
                <div className="dashboard-content">
                    <div className="content-header">
                        <div className="top-content">
                            <h5>Add Awards</h5>
                        </div>
                    </div>

                    {pageContent}
                </div>
            </div>

        )
    }
}

Awards.propTypes = {
    getAllAwards: PropTypes.func.isRequired,
    deleteAward: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    admin: state.admin
});
export default connect(mapStateToProps, { getAllAwards, deleteAward })(withRouter(Awards))