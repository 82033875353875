import React, { Component } from 'react'

class adminPageSetting extends Component {
    render() {
        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Dashboard</h5>
                            </div>
                        </div>
                        <div className="dashboard-main-content">
                            <div className="card contact-card">
                                <div className="card-header">
                                    Admin Settings
                                </div>

                                <form>
                                    <div className="card-body">
                                        <div className="form-group small-section">
                                            <label>Name:</label>
                                            <input type="text" name="tel" className="form-control" placeholder="Enter name" />
                                        </div>

                                        <div className="form-group small-section right-part">
                                            <label>Email:</label>
                                            <input type="email" name="tel" className="form-control" placeholder="Enter email" />
                                        </div>

                                        <div className="form-group small-section single">
                                            <label>Change Password:</label>
                                            <input type="Password" name="tel" className="form-control" placeholder="Update password" />
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="reset" className="btn submit-btn mr-2">Submit</button>
                                        <button type="reset" className="btn cancel-btn">Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



export default adminPageSetting
