import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllCategory, postInterlockDesign } from '../../../store/actions/adminActions'
import { getBase64 } from '../../common/Base64'
import Spinner from '../../common/Spinner'
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';

class AddInterlockDesign extends Component {

    constructor(props) {
        super(props)
        this.state = {
            categories: [],
            selectedCategories: [],
            image: '',
            materials: [""],
            colors: [""]
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { loading, categories } = props.admin
        if (props.hist) {
            if (props.match.path != props.hist.path) {
                props.changematch();
                props.history.push(props.hist.path);
            }
        }

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        if (JSON.stringify(categories) !== JSON.stringify(state.categories)) {
            state.categories = categories
        }
        return state
    }


    componentDidMount() {
        this.props.getAllCategory()
    }


    imageHandler = (e) => {
        this.setState({
            image: e.target.files[0]
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleValidation() {
        const fields = this.state;
        let formIsValid = true;
        if (!fields["selectedCategories"] && !fields["selectedCategories"].length) {
            formIsValid = false;
            NotificationManager.error('Category field is required')
        }

        if (!fields["image"]) {
            formIsValid = false;
            NotificationManager.error('Image is required')
        }

        if (!fields["materials"] && !fields["materials"].length) {
            formIsValid = false;
            NotificationManager.error('Material field is required')
        }

        if (!fields["colors"] && !fields["colors"].length) {
            formIsValid = false;
            NotificationManager.error('Color field is required')
        }
        return formIsValid;
    }

    submitHandler = async (e) => {
        e.preventDefault()

        if (!this.handleValidation()) {
            return
        }

        const image = await getBase64(this.state.image);

        const data = {
            category_id: this.state.selectedCategories,
            image,
            material: [...this.state.materials],
        }
        this.state.colors.filter((el) => el).forEach((color, i) => {
            data[`color${i + 1}`] = color;
        })
        console.log("data", data)
        this.props.postInterlockDesign(data, this.props.history)
    }

    addMaterial = () => {
        this.setState({
            materials: [...this.state.materials, ""]
        })
    }

    onMaterialChange = (key, event) => {
        const materials = [...this.state.materials]
        materials[key] = event.target.value
        this.setState({
            materials: materials
        })
    }
    
    addColor = () => {
        if(this.state.colors.length < 5 ) {
            this.setState({
                colors: [...this.state.colors, ""]
            })
        }
    }

    onColorChange = (key, event) => {
        const colors = [...this.state.colors]
        colors[key] = event.target.value
        this.setState({
            colors: colors
        })
    }

    customStyles = {
        control: (provided, state) => ({
          ...provided,
          background: '#fff',
          borderColor: '#9e9e9e',
          minHeight: '40px',
          height: '40px',
          boxShadow: state.isFocused ? null : null,
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          height: '40px',
          padding: '0 6px'
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: '0px',
        }),
        indicatorSeparator: state => ({
          display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: '40px',
        }),
    };


    render() {
        const { loading, materials, categories, colors } = this.state
        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="card contact-card">

                    <form onSubmit={this.submitHandler}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                <div class="form-group">
                                    <label>Select Category:</label>
                                    <Select 
                                        isMulti 
                                        styles={this.customStyles}
                                        options={categories.map((item, key) => {
                                            return { value: item.id, label: item.category }
                                        })} 
                                        onChange={(val) => {
                                            this.setState({selectedCategories: val.map((el) => el.value)})
                                        }}
                                        className="multi-categories"
                                    />
                                </div>
                                {colors?.length ? colors.map((el, i) => <div className={`form-group`}>
                                <label>Select Color {i + 1}</label>
                                <input
                                    type="color"
                                    name={`color`}
                                    value={el}
                                    onChange={(e) => this.onColorChange(i, e)}
                                    placeholder={`Choose color ${i + 1}`}
                                    className="form-control"
                                />
                            </div>) : null}
                            <div> {colors.length < 5 ? <span onClick={this.addColor} className="add-more-btn">Add color +</span> : null}</div>
                                </div>
                                <div className="col-12 col-md-6">
                                {materials?.length ? materials.map((el, i) => <div className={`form-group`}>
                                <label>Enter Material {i + 1}</label>
                                <input
                                    type="text"
                                    name={`material`}
                                    value={el}
                                    onChange={(e) => this.onMaterialChange(i, e)}
                                    className="form-control"
                                    placeholder="Enter your material"
                                />
                            </div>) : null}
                            <span onClick={this.addMaterial} className="add-more-btn">Add more +</span>
                                </div>
                            </div>

                            <div className="form-group small-section upload-file">
                                <div className="upload-content">
                                    <label>Pattern Image</label>
                                    <div className="form-group">
                                        <div className="upload-btn-wrapper">
                                            <button className="btn">{this.state?.image?.name ?? "Upload an Image"}</button>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={this.imageHandler}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn submit-btn mr-2">Submit</button>
                            <button type="reset" className="btn cancel-btn" onClick={() => this.props.history.push("/admin/interlock-design")}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        }


        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Add Interlock Design</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}

AddInterlockDesign.prototypes = {
    getAllCategory: PropTypes.func.isRequired,
    postInterlockDesign: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { getAllCategory, postInterlockDesign })(withRouter(AddInterlockDesign))