import axios from 'axios'

import {
    SET_ERRORS,
    GET_ALL_BLOCKS,
    GET_ALL_PROJECTS,
    GET_ALL_AWARDS,
    GET_ALL_INTERLOCKS,
    GET_ALL_INTERLOCK_DESIGN,
    GET_HOME_CONTENT,
    GET_ABOUT_CONTENT,
    GET_CONTACTUS_CONTENT,
    GET_SINGLE_BLOCK,
    GET_SINGLE_INTERLOCK,
    PRODUCT_PARAGRAPH,
    GET_CAREER_CONTENT
} from './types'
import { NotificationManager } from 'react-notifications';

import {
    setAdminLoading,
    clearAdminLoading
} from './adminActions'

const backendServerURL = process.env.REACT_APP_API_URL

export const getAllBlock = () => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/blocks')
        .then(res => {
            console.log(' payload: res.data', res.data)
            dispatch({ type: GET_ALL_BLOCKS, payload: res.data })
        })
        .catch(err => {
            console.log('err', err.message)
            dispatch({ type: SET_ERRORS, payload: err.message });
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const getSingleBlock = (id) => dispatch => {
    dispatch(setAdminLoading());
    axios
        .get(backendServerURL + '/blocks/' + id)
        .then(res => {
            console.log('getSingle', res.data)
            dispatch({ type: GET_SINGLE_BLOCK, payload: res.data })
        })
        .catch(err => {
            console.log('err', err.message)
            dispatch({ type: SET_ERRORS, payload: err.message });
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const getAllProject = () => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/projects')
        .then(res => {
            console.log('project', res.data)
            dispatch({ type: GET_ALL_PROJECTS, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: SET_ERRORS, payload: err.message });
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const getSingleProject = (id) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/projects/' + id)
        .then(res => {
            console.log('res', res.data)
            dispatch({ type: GET_ALL_PROJECTS, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: SET_ERRORS, payload: err.message });
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const getAllAwards = () => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/awards')
        .then(res => {
            console.log('awards', res.data)
            dispatch({ type: GET_ALL_AWARDS, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: SET_ERRORS, payload: err.message });
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const getSingleAward = (id) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/awards/' + id)
        .then(res => {
            dispatch({ type: GET_ALL_AWARDS, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: SET_ERRORS, payload: err.message });
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const getAllInterlockDesign = (category) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/interlockdesign')
        .then(res => {
            dispatch({ type: GET_ALL_INTERLOCK_DESIGN, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: SET_ERRORS, payload: err.message })
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const postInterlockDesignOrder = (orders) => dispatch => {
    return axios.post(backendServerURL + "sortData", {sortedArray: orders})
        .then(res => {
            console.log('res.data', res.data)
            return true
        })
        .catch(err => {
            console.log('err', err.message)
            NotificationManager.error(err.response.request && err.response.request.response);
        })
}

export const getSingleTypeInterlockDesign = (name) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/interlockdesign?category=' + name)
        .then(res => {
            dispatch({ type: GET_ALL_INTERLOCK_DESIGN, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: SET_ERRORS, payload: err.message })
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const getSingleInterlockDesign = (id) => dispatch => {
    dispatch(setAdminLoading());
    axios
        .get(backendServerURL + '/interlockdesign/' + id)
        .then(res => {
            dispatch({ type: GET_ALL_INTERLOCK_DESIGN, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: SET_ERRORS, payload: err.message })
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const getInterlockDesignonCategory = (category) => dispatch => {
    dispatch(setAdminLoading());
    axios
        .get(backendServerURL + '/interlockdesign?category=' + category)
        .then(res => {
            dispatch({ type: GET_ALL_INTERLOCK_DESIGN, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: SET_ERRORS, payload: err.message })
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const getAllInterlocks = () => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/interlocks')
        .then(res => {
            console.log('GET_ALL_INTERLOCKS', res.data)
            dispatch({ type: GET_ALL_INTERLOCKS, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: SET_ERRORS, payload: err.message })
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const getHomePageContent = () => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/home')
        .then(res => {
            console.log('result ', res.data)
            dispatch({ type: GET_HOME_CONTENT, payload: res.data })
        })
        .catch(err => {
            console.log('error', err.message)
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const getProductParagraph = () => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/product')
        .then(res => {
            dispatch({ type: PRODUCT_PARAGRAPH, payload: res.data })
        })
        .catch(err => {
            console.log('error', err.message)
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const getContactUsContent = () => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/contactinfo')
        .then(res => {
            console.log('contact us page content ', res.data)
            dispatch({ type: GET_CONTACTUS_CONTENT, payload: res.data })
        })
        .catch(err => {
            console.log('error', err.message)
        })
        .finally(() => dispatch(clearAdminLoading()));
}



export const getCareerContent = () => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/careerinfo')
        .then(res => {
            console.log('career us page content ', res.data)
            dispatch({ type: GET_CAREER_CONTENT, payload: res.data })
        })
        .catch(err => {
            console.log('error', err.message)
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const getAboutContent = () => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/about')
        .then(res => {
            console.log('about Page Content', res.data)
            dispatch({ type: GET_ABOUT_CONTENT, payload: res.data })
        })
        .catch(err => {
            console.log('error', err.message)
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const getSingleInterlock = (id) => dispatch => {
    dispatch(setAdminLoading());
    return axios
        .get(backendServerURL + '/interlocks/' + id)
        .then(res => {

            console.log('single Interlock mateen', res.data,)
            dispatch({ type: GET_SINGLE_INTERLOCK, payload: res.data })
            return { status: true, name: res.data.data["0"].name }
        })
        .catch(err => {
            console.log('err', err.message)
        })
        .finally(() => dispatch(clearAdminLoading()));

}

export const postContact = (data) => dispatch => {
    return axios.post(backendServerURL + '/contact', data)
        .then(res => {
            console.log('res.data', res.data)
            NotificationManager.success('Form Submitted Successfully');
            return true
        })
        .catch(err => {
            console.log('err', err.message)
            NotificationManager.error(err.response.request && err.response.request.response);
        })
}


export const postCareer = (data) => dispatch => {
    return axios.post(backendServerURL + '/career', data)
        .then(res => {
            console.log('post Career', res.data)
            NotificationManager.success('Application Submitted Successfully');
            return true
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
}


