import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { getSingleProject } from '../../../store/actions/publicActions'
import { updateProject } from '../../../store/actions/adminActions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import Spinner from '../../common/Spinner';
import { getBase64, uploadedImageName } from '../../common/Base64'
import { NotificationManager } from 'react-notifications';

class edit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            id: '',
            name: '',
            image: '',
            location: '',
            date: '',
            material: '',
            filename: '',
            projects: [],
            imageName: ""
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.hist) {
            if (props.match.path != props.hist.path) {
                props.changematch();
                props.history.push(props.hist.path);
            }
        }

        const { loading, projects } = props.admin;
        const project = projects[0];

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        if (project && JSON.stringify(project) !== JSON.stringify(state.project)) {
            state.project = project;
            state.id = project.id;
            state.name = project.name;
            state.location = project.location;
            state.date = moment(project.date, "DD-MM-YYYY").format("YYYY-MM-DD");
            state.material = project.material;
            state.imageName = project.image;
        }

        return state;
    }


    componentDidMount() {
        const id = this.props.match.params.id
        this.props.getSingleProject(id)
    }

    handleValidation() {
        const fields = this.state;
        let formIsValid = true;

        if (!fields["name"]) {
            formIsValid = false;
            NotificationManager.error('Name field is required')
        }

        if (!fields["location"]) {
            formIsValid = false;
            NotificationManager.error('Location is required')
        }

        if (!fields["material"]) {
            formIsValid = false;
            NotificationManager.error('Material field is required')
        }

        return formIsValid;
    }

    submitHandler = async (e) => {
        e.preventDefault()

        if (!this.handleValidation()) {
            return
        }

        const datestr = moment(new Date(this.state.date)).format("DD-MM-YYYY")
        const image = await getBase64(this.state.image);
        const data = {
            id: this.state.id,
            name: this.state.name,
            location: this.state.location,
            date: datestr,
            material: this.state.material,
            image
        }

        this.props.updateProject(data, this.props.history)
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    imageHandler = (e) => {
        let files = e.target.files[0];
        this.setState({
            filename: files.name,
            image: files
        })
    }

    render() {
        const { loading, name, image, location, date, material, filename } = this.state;

        console.log('state', this.state.date)

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="card contact-card">
                    <div className="card-header">
                        Edit Project
                </div>

                    <form onSubmit={this.submitHandler}>
                        <div className="card-body">
                            <div className="form-group small-section">
                                <label>Project Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    placeholder="Enter project name"
                                    value={name}
                                    onChange={this.changeHandler}
                                />
                            </div>

                            <div className="form-group small-section right-part">
                                <label>Project Location</label>
                                <input type="text"
                                    name="location"
                                    className="form-control"
                                    placeholder="Enter project location"
                                    value={location}
                                    onChange={this.changeHandler}
                                />
                            </div>

                            <div className="form-group small-section">
                                <label>Project Material:</label>
                                <input type="text"
                                    name="material"
                                    className="form-control"
                                    placeholder="Enter project date"
                                    value={material}
                                    onChange={this.changeHandler}
                                />
                            </div>

                            <div className="form-group small-section right-part">
                                <label>Project Image</label>
                                <div className="form-group">
                                    <div className="upload-btn-wrapper">
                                        <button className="btn">{this.state?.image?.name ?? uploadedImageName(this.state?.imageName)}</button>
                                        <input type="file"
                                            name="image"
                                            accept="image/*"
                                            onChange={this.imageHandler}
                                        />
                                    </div>
                                    {/* <div className="form-group">
                                        {filename}
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div className="card-footer">
                            <button type="submit" className="btn submit-btn mr-2">Submit</button>
                            <button type="reset" className="btn cancel-btn">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5> Edit Project</h5>
                            </div>
                        </div>
                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}

edit.propTypes = {
    getSingleProject: PropTypes.func.isRequired,
    updateProject: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { getSingleProject, updateProject })(withRouter(edit))