import React, { Component } from 'react'
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
import Dashboard from './dashboard'
import AddBlock from './Block/add'
import EditBlock from './Block/edit'
import AddInterlock from './Interlocks/add'
import EditInterlock from './Interlocks/edit'
import AddProject from './Project/add'
import EditProject from './Project/edit'
import Category from './category'
import AddCategory from './category/add'
import EditCategory from './category/edit'
import AddAward from './awards/add'
import EditAward from './awards/edit'
import AboutPage from './aboutPage'
import AdminSettings from './adminSettings'
import HomePage from './homePage'
import ContactUsPage from './contactUsPage'
import ContactDetail from './ContactDetail/index'
import ViewContactDetail from './ContactDetail/viewContactDetail'
import AddInterlockDesign from './InterlockDesign/add'
import EditInterlockDesign from './InterlockDesign/edit'
import Blocks from './Block/index'
import Project from './Project/index'
import Awards from './awards/index'
import Interlocks from './Interlocks/index'
import InterlockDesign from './InterlockDesign/index'
import ProductParagraph from './productParagraph'
import CareerDetail from './Career/index'
import SingleCareerDetail from './Career/viewCareerDetail'
import CareerPage from './CareerPage/index'

class Routes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            match: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ match: nextProps.match });
    }


    changematch = () => {
        this.setState({ match: '' });
    }

    render() {
        const { match } = this.state
        return (
            <Router>
                <Route
                    exact
                    path="/admin/dashboard"
                    component={
                        () => <Dashboard />
                    }
                />

                <Route exact
                    path="/admin/blocks"
                    component={
                        () => <Blocks />
                    }
                />

                <Route
                    exact
                    path="/admin/blocks/add"
                    component={
                        () => <AddBlock
                            hist={match}
                            changematch={this.changematch}
                        />
                    }
                />

                <Route
                    exact
                    path="/admin/blocks/edit/:id"
                    component={
                        () => <EditBlock
                            hist={match}
                            changematch={this.changematch}
                        />
                    }
                />


                <Route
                    exact
                    path="/admin/product-paragraph"
                    component={
                        () => <ProductParagraph />
                    }
                />


                <Route
                    exact
                    path="/admin/interlocks"
                    component={
                        () => <Interlocks />
                    }
                />

                <Route
                    exact
                    path="/admin/interlocks/add"
                    component={
                        () => <AddInterlock
                            hist={match}
                            changematch={this.changematch}
                        />
                    }
                />

                <Route
                    exact
                    path="/admin/interlocks/edit/:id"
                    component={
                        () => <EditInterlock
                            hist={match}
                            changematch={this.changematch}
                        />
                    }
                />
                <Route
                    exact
                    path="/admin/projects"
                    component={
                        () => <Project />
                    }
                />
                <Route
                    exact
                    path="/admin/projects/add"
                    component={
                        () => <AddProject
                            hist={match}
                            changematch={this.changematch}
                        />
                    }
                />
                <Route
                    exact
                    path="/admin/projects/edit/:id"
                    component={
                        () => <EditProject
                            hist={match}
                            changematch={this.changematch}
                        />
                    }
                />

                <Route
                    exact
                    path="/admin/category"
                    component={
                        () => <Category />
                    }
                />

                <Route
                    exact
                    path="/admin/category/add"
                    component={
                        () => <AddCategory />
                    }
                />

                <Route
                    exact
                    path="/admin/category/edit/:id"
                    component={
                        () => <EditCategory />
                    }
                />

                <Route exact
                    path="/admin/awards"
                    component={
                        () => <Awards />
                    }
                />

                <Route exact
                    path="/admin/awards/add"
                    component={
                        () => <AddAward
                            hist={match}
                            changematch={this.changematch}
                        />
                    }
                />

                <Route exact
                    path="/admin/awards/edit/:id"
                    component={
                        () => <EditAward
                            hist={match}
                            changematch={this.changematch}
                        />
                    }
                />

                <Route exact
                    path="/admin/about-page"
                    component={
                        () => <AboutPage />
                    }
                />

                <Route exact
                    path="/admin/home-page"
                    component={
                        () => <HomePage />
                    }
                />
                <Route exact
                    path="/admin/contact-page"
                    component={
                        () => <ContactUsPage />
                    }
                />

                <Route exact
                    path="/admin/career-page"
                    component={
                        () => <CareerPage />
                    }
                />

                <Route exact
                    path="/admin/admin-setting"
                    component={
                        () => <AdminSettings />
                    }
                />

                <Route exact
                    path="/admin/contact-details"
                    component={
                        () => <ContactDetail />
                    }
                />

                <Route exact
                    path="/admin/view-contact-details/:id"
                    component={
                        () => <ViewContactDetail
                            hist={match}
                            changematch={this.changematch}
                        />
                    }
                />



                <Route exact
                    path="/admin/career-details"
                    component={
                        () => <CareerDetail />
                    }
                />

                <Route exact
                    path="/admin/view-career-details/:id"
                    component={
                        () => <SingleCareerDetail
                            hist={match}
                            changematch={this.changematch}
                        />
                    }
                />

                <Route exact
                    path="/admin/interlock-design"
                    component={
                        () => <InterlockDesign />
                    }
                />

                <Route exact
                    path="/admin/interlock-design/add"
                    component={
                        () => <AddInterlockDesign
                            hist={match}
                            changematch={this.changematch}
                        />
                    }
                />
                <Route exact
                    path="/admin/interlock-design/edit/:id"
                    component={
                        () => <EditInterlockDesign
                            hist={match}
                            changematch={this.changematch}
                        />
                    }
                />
            </Router>
        )
    }
}

export default withRouter(Routes)
