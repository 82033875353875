import React, { Component } from 'react'
import { getSingleInterlock, getSingleTypeInterlockDesign } from '../../../../store/actions/publicActions'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import EnlargeIcon from '../../../../assets/images/enlarge.png'

class SingleInterlock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            singleInterlock: null,
            selectIndex: 0,
            interlockDesigns: [],
            selectItem: null,
            colorCheck: false,
            loadInterlockDesigns: false
        }
    }
    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const id = this.props.match.params.id
        this.props.getSingleInterlock(id)
            .then(res => {
                if (res.status) {
                    this.props.getSingleTypeInterlockDesign(res.name)
                }
            })
    }


    changeSelectItem = (key) => {
        const { singleInterlock,interlockDesigns } = this.props.admin
        const { colorCheck } = this.state;
        this.setState({ selectIndex: key })
        if(colorCheck && interlockDesigns?.length) {
            const intersect = interlockDesigns.filter((el) => {
                return el.color1 === singleInterlock.variety[key].color ||
                el.color2 === singleInterlock.variety[key].color ||
                el.color3 === singleInterlock.variety[key].color ||
                el.color4 === singleInterlock.variety[key].color ||
                el.color5 === singleInterlock.variety[key].color
            });
            this.setState({
                    interlockDesigns: intersect,
            })
        } else {
            this.setState({
                interlockDesigns: this.props.admin.interlockDesigns,
            })
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.hist) {
            if (props.match.url != props.hist.url) {
                props.changematch();
                props.history.push(props.hist.url);
            }
        }
        const { singleInterlock, interlockDesigns } = props.admin
        if (JSON.stringify(singleInterlock) !== JSON.stringify(state.singleInterlock)) {
            state.singleInterlock = singleInterlock
        }
        if (JSON.stringify(interlockDesigns) !== JSON.stringify(state.interlockDesigns) && !state.loadInterlockDesigns) {
            state.interlockDesigns = interlockDesigns
            state.loadInterlockDesigns = true

            // if (interlockDesigns.length > 6) {
            //     state.interlockDesigns = interlockDesigns.slice(0, 6)
            // }
            // else {
            //     state.interlockDesigns = interlockDesigns
            // }
        }

        return state
    }

    clickHandler = (item) => {
        this.setState({
            selectItem: item
        })
    }

    clickColorCheck = (value) => {
        const { singleInterlock, interlockDesigns, selectIndex } = this.state;
        if(value) {
            const intersect = interlockDesigns?.filter((el) => {
                return el.color1 === singleInterlock.variety[selectIndex].color ||
                el.color2 === singleInterlock.variety[selectIndex].color ||
                el.color3 === singleInterlock.variety[selectIndex].color ||
                el.color4 === singleInterlock.variety[selectIndex].color ||
                el.color5 === singleInterlock.variety[selectIndex].color
            });
            this.setState({
                    interlockDesigns: intersect,
                    colorCheck: value
            })
        } else {
            this.setState({
                interlockDesigns: this.props.admin.interlockDesigns,
                colorCheck: value
            })
        }
    }
        
        render() {
            const { singleInterlock, selectIndex, interlockDesigns, selectItem } = this.state
            // console.log("color", singleInterlock?.variety?.[selectIndex].color)
            // console.log("interlockDesigns", interlockDesigns)
        return (
            <div class="inerlock-page-container">
                <div class="container block-detail">
                    <div class="row">
                        <div class="col-12 col-sm-10 col-md-6 col-lg-5 col-12 p-0 block-img-container">
                            <div class="thermo-block">
                                <img src={singleInterlock && singleInterlock.variety && singleInterlock.variety[selectIndex].image} />
                            </div>
                            <div class="color-content">
                                {singleInterlock && singleInterlock.variety && singleInterlock.variety.map((item, key) => {
                                    return <span className='color' onClick={() => this.changeSelectItem(key)} style={{ backgroundColor: item.color }}>
                                        <div className={selectIndex === key ? "selected-border" : null}></div>
                                    </span>
                                })}
                            </div>
                        </div>

                        <div class="col-md-6 col-12 detail">
                            <h2 class="sec-title" style={{ textTransform: 'none' }}>{singleInterlock && singleInterlock.name}</h2>
                            <p class="sec-para">{singleInterlock && singleInterlock.variety && singleInterlock.variety[selectIndex].serialnumber}</p>
                            <div class="block-size-weight">
                                <div class="block">
                                    <p class="sec-para bg-clr">Dimensions</p>
                                    <div class="border-2" style={{ position: 'relative' }}>
                                        <img data-toggle="modal" data-target="#dimension" src={EnlargeIcon} style={{
                                            height: '18px', width: '18px', cursor: 'pointer',
                                            position: 'absolute',
                                            right: '0',
                                            top: '0',
                                        }} />
                                        <div style={{ height: '100%', width: '100px', cursor: 'pointer' }}>
                                            <img src={singleInterlock && singleInterlock.dimensions} className="w-100" data-toggle="modal" data-target="#dimension" style={{ height: '100%' }} />
                                        </div>

                                    </div>
                                </div>
                                <div class="block">
                                    <p class="sec-para bg-clr">Thickness</p>
                                    <div class="border-2 d-block">
                                        {/* <p class="sec-para">{singleInterlock && singleInterlock.thickness && singleInterlock.thickness.upperLimit}</p>
                                        <p class="sec-para divider"></p> */}
                                        <p class="sec-para">{singleInterlock && singleInterlock.thickness && singleInterlock.thickness}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="block-size-weight">
                                <div class="block">
                                    <p class="sec-para bg-clr">Weight/Block</p>
                                    <div class="border-2 d-block">
                                        {/* <p class="sec-para">{singleInterlock && singleInterlock.weight && singleInterlock.weight.upperLimit}</p>
                                        <p class="sec-para divider"></p> */}
                                        <p class="sec-para">{singleInterlock && singleInterlock.weight && singleInterlock.weight}</p>
                                    </div>
                                </div>
                                <div class="block">
                                    <p class="sec-para bg-clr">No/m<sup>2</sup></p>
                                    <div class="border-2 d-block">
                                        <p class="sec-para">{singleInterlock && singleInterlock.area}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="block-size-weight">
                                <input type="checkbox" name="colorCheck" value="" onChange={(e) => this.clickColorCheck(e.target.checked)} />
                                <label for="colorCheck"><strong>show only this color</strong></label>
                            </div>
                        </div>
                    </div>


                    <h2 class="sec-title mt-4" style={{ textTransform: 'none' }}>Related Patterns</h2>
                    <div class="block-size">
                        {interlockDesigns?.length ? interlockDesigns.map((item, key) => {
                            return <div key={key} class="sizing" onClick={() => this.clickHandler(item)}><img src={item.image} data-toggle="modal" data-target="#model" /> </div>
                        }) : <h2>No data found</h2>}
                    </div>
                    <div class="modal fade" id="model" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="project-modal">
                                    <div class="project-image modal-design-img">
                                        <img src={selectItem && selectItem.image} alt="Block Pattern" />
                                    </div>
                                    <h2>Materials</h2>
                                    {selectItem?.material?.length ? selectItem.material.map((value, key) => {
                                        return <div key={key}>
                                            <span >{value}</span>
                                        </div>
                                    }) : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="dimension" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="project-modal">
                                    <div class="project-image modal-design-img">
                                        <img src={singleInterlock && singleInterlock.dimensions} alt="Block Pattern" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="cmmn-btn text-center">
                    <Link to="/interlock-design">
                        <button className="btn mt-4">More Pattern</button>
                    </Link>
                </div>

                <div class="contactus-bg">
                    <div class="over-lay"></div>
                    <div class="container">
                    </div>
                </div>
            </div>
        )
    }
}

SingleInterlock.propTypes = {
    getSingleInterlock: PropTypes.func.isRequired,
    getSingleTypeInterlockDesign: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
    admin: state.admin
});
export default connect(mapStateToProps, { getSingleInterlock, getSingleTypeInterlockDesign })(withRouter(SingleInterlock))