import React, { Component } from 'react'
import { getAllAwards } from '../../../store/actions/publicActions'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
class Award extends Component {

    constructor(props) {
        super(props);
        this.state = {
            awards: [],
            upper: [],
            lower: [],
            selectItem: null,
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { awards } = props.admin
        if (JSON.stringify(awards) !== JSON.stringify(state.awards)) {
            state.awards = awards
            state.upper = awards.slice(0, 2)
            if (awards.length > 2) {
                state.lower = awards.slice(2)
            }
        }
        return state
    }

    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        this.props.getAllAwards()
    }

    clickHandler = (item) => {
        this.setState({
            selectItem: item
        })
    }
    render() {
        const { awards, lower, upper, selectItem, } = this.state
        console.log('awards', awards.length, lower.length)
        return (
            <div className="awards-page-container">
                <div className="bg-color about-main">
                    <div className="col-12 text-center">
                        <div className="title-desc">
                            <h2 className="sec-title">Recognition And Awards</h2>
                        </div>
                    </div>
                </div>
                <div className="container awards">
                    {upper.map((item, key) => {
                        return <div className="row" key={key}>
                            <div className="certificate-awards">
                                <div className="award-image">
                                    <img src={item.logo} alt="award" />
                                </div>
                                <h4 className="certificate-title">{item.name} </h4>
                                <div className="certificate-image">
                                    <img src={item.image1} alt="certificate" />
                                </div>
                            </div>
                        </div>
                    })}

                </div>

                <div className="container our-products">
                    <div className="row m-0">
                        <h2 className="sec-title">Other Awards</h2>
                        <div className="row m-0">
                            <div className="blocks-our-projects">

                                {lower.map((item, key) => {
                                    return <div className="our-project" key={key} data-toggle="modal" data-target="#model" onClick={() => this.clickHandler(item)}>
                                        <img src={item.image1} alt="dubai hotel" />
                                        <p className="project" >{item.name}</p>
                                    </div>
                                })}

                            </div>

                        </div>
                        <div className="blocks-our-projects">
                            <div className="modal fade" id="model" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="project-modal">
                                            <div className="project-image">
                                                <img src={selectItem && selectItem.image1} alt="dubai hotel" style={{ width: '100%' }} />
                                            </div>
                                            <h2 className="place">{selectItem && selectItem.name}</h2>
                                            <p className="loc-da">{selectItem && selectItem.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contactus-bg">
                        <div className="over-lay"></div>
                        <div className="container">
                        </div>
                    </div>
            </div>
        )
    }
}

Award.propTypes = {
    getAllAwards: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    admin: state.admin
});
export default connect(mapStateToProps, { getAllAwards })(withRouter(Award))