import axios from 'axios'
import {
    GET_ALL_CONTACT_US_RESPONSE,
    SET_ADMIN,
    GET_ALL_CATEGORY,
    ADMIN_LOADING,
    ADMIN_LOADED,
    DELETE_CATEGORY,
    DELETE_AWARD,
    DELETE_PROJECT,
    DELETE_INTERLOCK,
    DELETE_INTERLOCK_DESIGN,
    DELETE_BLOCK,
    GET_ALL_CAREER_RESPONSE,
    DELETE_CONTACT_US,
    DELETE_CAREER,
    GET_DASHBOARD
} from './types'

import { NotificationManager } from 'react-notifications';

const backendServerURL = process.env.REACT_APP_API_URL
const dummyURL = process.env.REACT_APP_BASE_URL

export const setAdminLoading = () => {
    return {
        type: ADMIN_LOADING
    };
};

export const clearAdminLoading = () => {
    return {
        type: ADMIN_LOADED
    };
};

export const setAdmin = () => dispatch => {
    dispatch({ type: SET_ADMIN, payload: true })

}

export const unSetAdmin = (history) => dispatch => {
    localStorage.removeItem('token');
    dispatch({ type: SET_ADMIN, payload: false })
    history.push('/admin')
}



export const getToken = () => {
    return '?api_token=' + localStorage.token
}


export const getCategory = (id) => dispatch => {
    axios.get(backendServerURL + '/interlockdesign/category?id' + id)
        .then(res => {
            console.log('hellllo', res.data)

        })
        .catch(err => {
            console.log('err', err.message)
        })
}

export const AdminLogin = (data, history) => dispatch => {
    axios
        .post(backendServerURL + "/admin", data)
        .then(res => {
            console.log("res", res)

            if (res?.data?.status !== "0") {
                const { api_token } = res.data["user data"][0]
                localStorage.setItem('token', api_token);
                dispatch(setAdmin())
                history.push('/admin/dashboard')
            } else {
                console.log("msg", res?.data?.message)
                NotificationManager.error(res?.data?.message ?? "Login Failed")
            }
        })
        .catch(err => {
            console.log("err", err)
            NotificationManager.error(err?.response?.request?.response ?? "Login Failed");
        })
}


export const getDashboard = () => dispatch => {
    dispatch(setAdminLoading());
    axios
        .get(backendServerURL + '/dashboard' + getToken())
        .then(res => {
            console.log('okkkayy', res.data)
            dispatch({ type: GET_DASHBOARD, payload: res.data })
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const getContactusResponse = () => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/contact' + getToken()
        )
        .then(res => {
            dispatch({ type: GET_ALL_CONTACT_US_RESPONSE, payload: res.data })
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const getCareerResponse = () => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/career' + getToken()
        )
        .then(res => {
            console.log('career ', res.data)
            dispatch({ type: GET_ALL_CAREER_RESPONSE, payload: res.data })
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const postBlock = (data, history) => dispatch => {
    dispatch(setAdminLoading());
    axios
        .post(
            backendServerURL + '/blocks' + getToken(),
            data
        )
        .then(res => {
            NotificationManager.success('Block Added Successfully');
            history.push('/admin/blocks')
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const updateBlock = (data, history) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .post(
            backendServerURL + '/blocks/update' + getToken(),
            data
        )
        .then(res => {
            NotificationManager.success('Block Updated Successfully');
            history.push('/admin/blocks')
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const updateProductPageParagraph = (data) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .put(
            backendServerURL + '/product' + getToken(),
            data
        )
        .then(res => {
            NotificationManager.success('Product Pragraph Updated Successfully');
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const postProject = (data, history) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .post(
            backendServerURL + '/projects' + getToken(),
            data
        )
        .then(res => {
            NotificationManager.success('Project Added Successfully');
            history.push('/admin/projects')
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const updateProject = (data, history) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .post(
            backendServerURL + '/projects/update' + getToken(),
            data
        )
        .then(res => {
            NotificationManager.success('Project Updated Successfully');
            history.push('/admin/projects')
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const postAward = (data, history) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .post(
            backendServerURL + '/awards' + getToken(),
            data
        )
        .then(res => {
            NotificationManager.success('Award Added Successfully');
            history.push('/admin/awards')
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const updateAward = (data, history) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .post(
            backendServerURL + '/awards/update' + getToken(),
            data
        )
        .then(res => {
            NotificationManager.success('Award Updated Successfully');
            history.push('/admin/awards')
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const getSingleBlock = (id) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(dummyURL + '/json/blocks.json/' + id)
        .then(res => {
            console.log('res', res.data)
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const getSingleProject = (id) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/projects/' + id)
        .then(res => {
            console.log('res', res.data)
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const updateHomePageSetting = (data) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .put(
            backendServerURL + "/home" + getToken(),
            data
        )
        .then(res => {
            NotificationManager.success('Home Page Updated Successfully');
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const updateContactUsPageSetting = (data) => dispatch => {
    dispatch(setAdminLoading());

    axios.put(
        backendServerURL + "/contactinfo" + getToken(),
        data
    )
        .then(res => {
            console.log('update contact page', res.data)
            NotificationManager.success('Contact Us Page Updated Successfully');
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const updateCareerPageSetting = (data) => dispatch => {
    dispatch(setAdminLoading());
    axios.post(
        backendServerURL + "/careerinfo" + getToken(),
        data
    )
        .then(res => {
            console.log('update career page', res.data)
            NotificationManager.success('Contact Us Page Updated Successfully');
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}



export const updateAboutPageSetting = (data) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .put(
            backendServerURL + "/about" + getToken(),
            data
        )
        .then(res => {
            NotificationManager.success('About Page Updated Successfully');
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const deleteAward = (id) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .delete(backendServerURL + '/awards' + getToken() + '&id=' + id)
        .then(res => {
            dispatch({ type: DELETE_AWARD, payload: id })
            NotificationManager.success('Award Deleted Successfully');
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const deleteProject = (id) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .delete(backendServerURL + '/projects' + getToken() + '&id=' + id)
        .then(res => {
            dispatch({ type: DELETE_PROJECT, payload: id })
            NotificationManager.success('Project Deleted Successfully');
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const deleteBlock = (id) => dispatch => {
    dispatch(setAdminLoading());
    axios
        .delete(backendServerURL + '/blocks' + getToken() + '&id=' + id)
        .then(res => {
            NotificationManager.success('Project Deleted Successfully');
            dispatch({ type: DELETE_BLOCK, payload: id })
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const deleteInterlock = (id) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .delete(backendServerURL + '/interlocks' + getToken() + '&id=' + id)
        .then(res => {
            dispatch({ type: DELETE_INTERLOCK, payload: id })
            NotificationManager.success('Interlock Deleted Successfully');
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const deleteContactUsForm = (id) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .delete(backendServerURL + '/contact' + getToken() + '&id=' + id)
        .then(res => {
            NotificationManager.success('Contact Us Form Deleted Successfully');
            dispatch({ type: DELETE_CONTACT_US, payload: id })
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const deleteCareerForm = (id) => dispatch => {
    dispatch(setAdminLoading());
    axios
        .delete(backendServerURL + '/career' + getToken() + '&id=' + id)
        .then(res => {
            console.log('res.data', res.data)
            NotificationManager.success('Career Form Deleted Successfully');
            dispatch({ type: DELETE_CAREER, payload: id })
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}




export const deleteCategory = (id) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .delete(backendServerURL + '/interlockdesign/category' + getToken() + '&id=' + id)
        .then(res => {
            dispatch({ type: DELETE_CATEGORY, payload: id })
            NotificationManager.success('Categories Deleted Successfully');
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const deleteInterlockDesign = (id) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .delete(backendServerURL + '/interlockdesign' + getToken() + '&id=' + id)
        .then(res => {
            dispatch({ type: DELETE_INTERLOCK_DESIGN, payload: id })
            NotificationManager.success('Interlock Design Deleted Successfully');
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const addCategory = (data, history) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .post(
            backendServerURL + '/interlockdesign/category' + getToken(),
            data
        )
        .then(res => {
            NotificationManager.success('Category Added Successfully');
            console.log('history', history)
            history.push('/admin/category')
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const updateCategory = (data, history) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .put(
            backendServerURL + '/interlockdesign/category' + getToken(),
            data
        )
        .then(res => {
            NotificationManager.success('Category Updated Successfully');
            history.push('/admin/category')
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const getAllCategory = () => dispatch => {
    dispatch(setAdminLoading());

    axios
        .get(backendServerURL + '/interlockdesign/category')
        .then(res => {
            console.log('Category', res.data)
            dispatch({ type: GET_ALL_CATEGORY, payload: res.data })
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const postInterlockDesign = (data, history) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .post(
            backendServerURL + '/interlockdesign' + getToken(),
            data
        )
        .then(res => {
            NotificationManager.success('Interlock Design Added Successfully');
            history.push('/admin/interlock-design')
        })
        .catch(err => {
            console.log("NotificationManager", err.response.request.response);
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const updateInterlockDesign = (data, history) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .post(
            backendServerURL + '/interlockdesign/update' + getToken(),
            data
        )
        .then(res => {
            console.log('res.data', res.data.categories)
            NotificationManager.success('Interlock Design Updated Successfully');
            history.push('/admin/interlock-design')
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}

export const postInterlock = (data, history) => dispatch => {
    dispatch(setAdminLoading());
    axios
        .post(
            backendServerURL + '/interlocks' + getToken(),
            data
        )
        .then(res => {
            NotificationManager.success('Interlock Added Successfully');
            history.push('/admin/interlocks')
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}


export const updateInterlock = (data, history) => dispatch => {
    dispatch(setAdminLoading());

    axios
        .post(
            backendServerURL + '/interlocks/update' + getToken(),
            data
        )
        .then(res => {
            console.log('ress', res)
            NotificationManager.success('Interlock Updated Successfully');
            history.push('/admin/interlocks')
        })
        .catch(err => {
            NotificationManager.error(err.response.request && err.response.request.response);
        })
        .finally(() => dispatch(clearAdminLoading()));
}