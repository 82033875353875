import React, { Component } from 'react'
import { getAllInterlockDesign, getInterlockDesignonCategory } from '../../../store/actions/publicActions'
import { getAllCategory } from '../../../store/actions/adminActions'

import { withRouter, Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class InterlockDesign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            interlockDesigns: [],
            selectItem: null,
            categories: []
        }
    }
    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        this.props.getAllInterlockDesign()
        this.props.getAllCategory()
    }

    static getDerivedStateFromProps(props, state) {
        const { interlockDesigns, categories } = props.admin
        if (JSON.stringify(interlockDesigns) !== JSON.stringify(state.interlockDesigns)) {
            state.interlockDesigns = interlockDesigns
        }
        if (JSON.stringify(categories) !== JSON.stringify(state.categories)) {
            state.categories = categories
        }
        return state
    }
    clickHandler = (item) => {
        this.setState({
            selectItem: item
        })
    }

    changeCategory = (event) => {
        this.props.getInterlockDesignonCategory(event.target.value)
    }
    render() {
        const { interlockDesigns, selectItem, categories } = this.state
        return (
            <div class="inerlock-page-container">
                <div class="container block-detail">
                    <div class="row m-0">
                        <div class="interlocks">
                            <h2 class="sec-title">Interlock Design</h2>

                            <div class="custom-select-wrapper">
                                <div class="custom-select">
                                    <select required class="custom-select__trigger" onChange={this.changeCategory}>
                                        <option value="">All Products</option>
                                        {categories.map((item, index) => {
                                            return (<option key={index} value={item.category} >{item.category}</option>)
                                        })}
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div class="blocks-our-projects">
                            {interlockDesigns?.length ? interlockDesigns.map((item, key) => {
                                return <div class="our-project" key={key} onClick={() => this.clickHandler(item)}>
                                    <img src={item.image} alt="Block Pattern" data-toggle="modal" data-target="#model" />
                                </div>
                            }) : <h2>No Record Found</h2>}

                            <div class="modal fade" id="model" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="project-modal">
                                            <div class="project-image modal-design-img">
                                                <img src={selectItem && selectItem.image} alt="Block Pattern" />
                                            </div>
                                            <h2>Materials</h2>
                                            {selectItem && selectItem.material.length && selectItem.material.map((value, key) => {
                                                return <div key={key}>
                                                    <span >{value}</span>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="contactus-bg">
                    <div class="over-lay"></div>
                    <div class="container">
                    </div>
                </div>
            </div>
        )
    }
}

InterlockDesign.propTypes = {
    getAllInterlockDesign: PropTypes.func.isRequired,
    getAllCategory: PropTypes.func.isRequired,
    getInterlockDesignonCategory: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    admin: state.admin
});
export default connect(mapStateToProps, { getAllInterlockDesign, getAllCategory, getInterlockDesignonCategory })(withRouter(InterlockDesign))