import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getAboutContent } from '../../../store/actions/publicActions'
import { updateAboutPageSetting } from '../../../store/actions/adminActions'
import PropTypes from 'prop-types';
import Spinner from '../../common/Spinner';
import { NotificationManager } from 'react-notifications';

class EditAboutPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            id: "",
            mainHeading: "",
            mainParagraph: "",
            subHeading: "",
            subParagraph: "",
            Firstbutton: "",
            subParagraph2: "",
            button2: "",
            aboutPage: {}
        }
    }

    componentDidMount() {
        this.props.getAboutContent()
    }

    static getDerivedStateFromProps(props, state) {
        const { loading, aboutPage } = props.admin;

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }
        console.log('aboutPage', aboutPage)
        if (JSON.stringify(aboutPage) !== JSON.stringify(state.aboutPage)) {
            state.aboutPage = aboutPage;
            state.id = aboutPage.id;
            state.mainHeading = aboutPage.mainHeading;
            state.mainParagraph = aboutPage.mainParagraph;
            state.subHeading = aboutPage.subHeading;
            state.subParagraph = aboutPage.subParagraph;
            state.Firstbutton = aboutPage.Firstbutton;
            state.subParagraph2 = aboutPage.subParagraph2;
            state.button2 = aboutPage.button2;
        }

        return state
    }

    handleValidation() {
        const fields = this.state;
        let formIsValid = true;

        if (!fields["mainHeading"]) {
            formIsValid = false;
            NotificationManager.error('Main heading field is required')
        }

        if (!fields["mainParagraph"]) {
            formIsValid = false;
            NotificationManager.error('Main paragraph field is required')
        }


        if (!fields["subHeading"]) {
            formIsValid = false;
            NotificationManager.error('Sub heading field is required')
        }

        if (!fields["subParagraph"]) {
            formIsValid = false;
            NotificationManager.error('Sub paragraph field is required')
        }

        if (!fields["Firstbutton"]) {
            formIsValid = false;
            NotificationManager.error('First button field is required')
        }

        if (!fields["subParagraph2"]) {
            formIsValid = false;
            NotificationManager.error('Sub paragraph 2 field is required')
        }

        if (!fields["button2"]) {
            formIsValid = false;
            NotificationManager.error('button 2 field is required')
        }


        return formIsValid;
    }

    onSubmitHandler = (e) => {
        e.preventDefault()

        if (!this.handleValidation()) {
            return
        }

        const data = {
            id: this.state.id,
            mainHeading: this.state.mainHeading,
            mainParagraph: this.state.mainParagraph,
            subHeading: this.state.subHeading,
            subParagraph: this.state.subParagraph,
            Firstbutton: this.state.Firstbutton,
            subParagraph2: this.state.subParagraph2,
            button2: this.state.button2
        }
        this.props.updateAboutPageSetting(data, this.props.history)
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const { loading, button2, subHeading, subParagraph, subParagraph2, Firstbutton, mainHeading, mainParagraph } = this.state

        let pageContent;

        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="card contact-card">
                    <form onSubmit={this.onSubmitHandler}>
                        <div className="card-body">
                            <div className="form-group small-section">
                                <label>Main Heading:</label>
                                <input type="text" name="mainHeading" value={mainHeading} className="form-control" placeholder="Enter main heading" onChange={this.onChangeHandler} />
                            </div>
                            <div className="form-group small-section right-part">
                                <label>Sub-heading:</label>
                                <input type="text" value={subHeading} name="subHeading" className="form-control" placeholder="Enter sub-heading" onChange={this.onChangeHandler} />
                            </div>

                            <div className="form-group small-section">
                                <label>Explore Button:</label>
                                <input type="text" name="Firstbutton" value={Firstbutton} className="form-control" placeholder="Enter explore button" onChange={this.onChangeHandler} />
                            </div>

                            <div className="form-group small-section right-part">
                                <label>Project Button:</label>
                                <input type="text" name="button2" value={button2} className="form-control" placeholder="Enter project button" onChange={this.onChangeHandler} />
                            </div>

                            <div className="form-group">
                                <label>Main Paragraph:</label>
                                <textarea className="form-control" value={mainParagraph} name="mainParagraph" placeholder="Enter main Paragraph" onChange={this.onChangeHandler}></textarea>
                            </div>

                            <div className="form-group">
                                <label>Sub-paragraph:</label>
                                <textarea className="form-control" name="subParagraph" value={subParagraph} placeholder="Enter Sub-paragraph" onChange={this.onChangeHandler}></textarea>
                            </div>

                            <div className="form-group">
                                <label>Second sub-paragraph:</label>
                                <textarea className="form-control" name="subParagraph2" value={subParagraph2} placeholder="Enter second sub-paragraph" onChange={this.onChangeHandler}></textarea>
                            </div>

                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn submit-btn mr-2">Submit</button>
                            <button type="reset" className="btn cancel-btn">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>About Page</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}


EditAboutPage.propTypes = {
    getAboutContent: PropTypes.func.isRequired,
    updateAboutPageSetting: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    admin: state.admin
});


export default connect(mapStateToProps, { getAboutContent, updateAboutPageSetting })(withRouter(EditAboutPage))