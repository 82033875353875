import React, { Component } from 'react'
import { getSingleBlock } from '../../../../store/actions/publicActions'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
class Block extends Component {

    constructor(props) {
        super(props)
        this.state = {
            singleBlock: null
        }
    }
    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const id = this.props.match.params.id
        this.props.getSingleBlock(id)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.hist) {
            if (props.match.url != props.hist.url) {
                props.changematch();
                props.history.push(props.hist.url);
            }
        }
        const { singleBlock } = props.admin
        if (JSON.stringify(singleBlock) !== JSON.stringify(state.singleBlock)) {
            state.singleBlock = singleBlock
        }
        return state
    }

    render() {
        const { singleBlock } = this.state
        return (
            <div>
                <div className="blocks-page-container">
                    <div className="container block-detail">
                        <div className="row">
                            <div className="col-md-6 col-lg-4 col-12 thermo-block p-0">
                                <img src={singleBlock && singleBlock.mainImage} />
                            </div>
                            <div className="col-md-6 col-lg-7 col-12 detail">
                                <h2 className="sec-title">{singleBlock && singleBlock.name}</h2>
                                <p className="sec-para">{singleBlock && singleBlock.description && singleBlock.description.description1}</p>
                                <p className="sec-para">{singleBlock && singleBlock.description && singleBlock.description.description2}</p>
                            </div>
                            <div className="block-size-weight">
                                <div className="block">
                                    <p className="sec-para bg-clr">Size (mm)</p>
                                    {singleBlock && singleBlock.sizes && singleBlock.sizes.map((item, key) => {
                                        return <div className="border-2" key={key}>
                                            <p className="sec-para clr">{item.size}</p>
                                        </div>
                                    })}
                                </div>
                                <div className="block">
                                    <p className="sec-para bg-clr">Weight (kg)</p>
                                    {singleBlock && singleBlock.sizes && singleBlock.sizes.map((item, key) => {
                                        return <div className="border-2" key={key}>
                                            <p className="sec-para clr">{item.weight}</p>
                                        </div>
                                    })}
                                </div>

                                <div className="block">
                                    <p className="sec-para bg-clr">Density (kg/m<sup>3</sup>)</p>
                                    {singleBlock && singleBlock.sizes && singleBlock.sizes.map((item, key) => {
                                        return <div className="border-2" key={key}>
                                            <p className="sec-para clr">{item.density} </p>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className="block-size-weight">


                            </div>
                        </div>
                        <div className="block-size">
                            {singleBlock && singleBlock.detailImages && singleBlock.detailImages.image2 && <div class="sizing">
                                <img src={singleBlock && singleBlock.detailImages && singleBlock.detailImages.image2} />
                            </div>
                            }
                            {singleBlock && singleBlock.detailImages && singleBlock.detailImages.image3 && <div class="sizing">
                                <img src={singleBlock && singleBlock.detailImages && singleBlock.detailImages.image3} />
                            </div>
                            }
                            {singleBlock && singleBlock.detailImages && singleBlock.detailImages.image4 && <div class="sizing">
                                <img src={singleBlock && singleBlock.detailImages && singleBlock.detailImages.image4} />
                            </div>
                            }
                        </div>
                    </div>

                    <div className="contactus-bg">
                        <div className="over-lay"></div>
                        <div className="container">
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Block.propTypes = {
    getSingleBlock: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
    admin: state.admin
});
export default connect(mapStateToProps, { getSingleBlock })(withRouter(Block))