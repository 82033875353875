import React, { Component } from 'react'
import { getHomePageContent, getAllBlock, getAllAwards, getAllInterlocks, getAllProject } from '../../../store/actions/publicActions'
import { withRouter, Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import download from '../../../assets/images/image3.jpg'
import leftArrowCar from '../../../assets/images/arrow-left-solid.svg'
import rightArrowCar from '../../../assets/images/arrow-right-solid.svg'

import rightArrow from "../../../assets/images/right-arrow.png"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

class MainPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            homePage: null,
            blocks: [],
            projects: [],
            awards: [],
            interlocks: [],
            currentAwardImageKey: 0
        }
    }
    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        this.props.getHomePageContent()
        this.props.getAllBlock()
        this.props.getAllAwards()
        this.props.getAllInterlocks()
        this.props.getAllProject()
    }

    static getDerivedStateFromProps(props, state) {
        const { homePage, blocks, projects, awards, interlocks } = props.admin
        if (JSON.stringify(homePage) !== JSON.stringify(state.homePage)) {
            state.homePage = homePage
        }

        if (JSON.stringify(blocks) !== JSON.stringify(state.blocks)) {
            if (blocks.length > 4) {
                state.blocks = blocks.slice(0, 4)
            }
            else {
                state.blocks = blocks
            }
        }

        if (JSON.stringify(projects) !== JSON.stringify(state.projects)) {
            if (projects.length > 2) {
                state.projects = projects.slice(0, 2)
            }
            else {
                state.projects = projects
            }
        }
        if (JSON.stringify(awards) !== JSON.stringify(state.awards)) {
            if (awards.length > 2) {
                state.awards = awards.slice(0, 2)
            }
            else {
                state.awards = awards
            }
        }
        if (JSON.stringify(interlocks) !== JSON.stringify(state.interlocks)) {
            if (interlocks.length > 4) {
                state.interlocks = interlocks.slice(0, 4)
            }
            else {
                state.interlocks = interlocks
            }
        }
        return state
    }


    render() {
        const { homePage, blocks, projects, interlocks, awards } = this.state
        console.log("block", blocks, "interlock", interlocks, "project", projects, "awards", awards)
        return (
            <div className="home-page-container">
                <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={45}
                    totalSlides={3}
                    infinite={true}
                    isPlaying={true}

                >
                    <Slider>
                        <Slide index={0}>
                            <div className="home-bg-img">
                                <div className="container">
                                    <div className="row">
                                        <div className="title-desc">
                                            <h1 className="title"></h1>

                                            <p className="desc"></p>

                                            <div className="cmmn-btn">
                                                {/* <Link to="/products">
                                                    <button className="btn">{homePage && homePage.bannerButton}</button>
                                                </Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={1}>
                            <div className="home-bg-img1">
                                <div className="container">
                                    <div className="row">
                                        <div className="title-desc">
                                            <h1 className="title"></h1>

                                            <p className="desc"></p>

                                            <div className="cmmn-btn">
                                                {/* <Link to="/products">
                                                    <button className="btn">{homePage && homePage.bannerButton}</button>
                                                </Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={2}>
                            <div className="home-bg-img2">
                                <div className="container">
                                    <div className="row">
                                        <div className="title-desc">
                                            <h1 className="title"></h1>

                                            <p className="desc"></p>

                                            <div className="cmmn-btn">
                                                {/* <Link to="/products">
                                                    <button className="btn">{homePage && homePage.bannerButton}</button>
                                                </Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>

                    </Slider>
                    <div className="carousel-arrows-container">
                        <div className="carousel-arrows-content">
                            <ButtonBack ><i class="fa fa-chevron-circle-left" aria-hidden="true"></i></ButtonBack>
                            <ButtonNext ><i class="fa fa-chevron-circle-right" aria-hidden="true"></i></ButtonNext>
                        </div>
                    </div>

                </CarouselProvider>

                <div className="aboutus-container">
                    <div className="row">
                        <div className="col-md-7 col-12 aboutus-txt">
                            <h3 className="h-4">ABOUT US</h3>
                            <h2 className="sec-title">{homePage && homePage.aboutHeading}</h2>
                            <p className="sec-para">{homePage && homePage.aboutParagraph}</p>
                            <p className="read-more">
                                <Link to="/about">
                                    READ MORE
                                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i> */}
                                    <img src={rightArrow} alt="arrow" />
                                </Link>
                            </p>

                        </div>
                        <div className="col-md-5 col-12 aboutus-img" >
                            <img src={download} alt="about" />
                        </div>
                    </div>
                </div>
                <div className="container our-products">
                    <div className="col-12 text-center">
                        <div className="title-desc">
                            <h2 className="sec-title">Our Products</h2>
                            <p className="sec-para">{homePage && homePage.productParagraph}</p>
                        </div>
                    </div>
                    <div className="blocks-products row">
                        {blocks.length ? blocks.map((item, key) => {
                            return <div className="col-md-3 col-6 blocks" key={key}>
                                <Link to={`/products/block/${item.id}`}>
                                    <div className="block-img">
                                        <img src={item.mainImage} alt="block" />
                                    </div>
                                    <h3 className="block-name">{item.name}</h3>
                                </Link>
                            </div>
                        }) : null}
                    </div>
                    <div className="cmmn-btn text-center">
                        <Link to="/products">
                            <button className="btn mt-4">{homePage && homePage.blockButton}</button>
                        </Link>
                    </div>
                    <div className="blocks-products row">
                        {interlocks.length ? interlocks.map((item, key) => {
                            return <div className="col-md-3 col-6 blocks">
                                <Link to={`/products/interlock/${item.id}`}>
                                    <div className="block-img">
                                        <img src={item.variety && item.variety[0].image} alt="block" />
                                    </div>
                                    <h3 className="block-name">{item.name}</h3>
                                </Link>
                            </div>
                        }) : null}
                    </div>
                    <div className="cmmn-btn text-center">
                        <Link to="/products">
                            <button className="btn mt-4">{homePage && homePage.interlockButton}</button>
                        </Link>
                    </div>
                    <div className="title-desc">
                        <h2 className="sec-title">Our Projects</h2>
                    </div>
                    <div className="row">
                        <div className="blocks-our-projects">
                            {projects.length ? projects.map((item, key) => {
                                return <div key={key}>
                                    <div className="our-project">
                                        <img src={item.image} alt="dubai hotel" />
                                        <p className="project">{item.name}</p>
                                    </div>
                                </div>
                            }) : null}

                        </div>
                    </div>
                    <div className="cmmn-btn text-center mt-4">
                        <Link to="/projects">
                            <button className="btn">{homePage && homePage.projectButton}</button>
                        </Link>
                    </div>
                    <div className="title-desc">
                        <h2 className="sec-title rec-title">Recognition And Awards</h2>
                    </div>
                    <div className="row recog-awards">
                        <div className="col-md-6 col-12 award-content-container">
                            {awards.length ? awards.map((item, key) => {
                                return <div className="awards" key={key} onClick={() => this.setState({ currentAwardImageKey: key })}>
                                    <p className="desktop-show">{item.name}</p>
                                    <div className="award-img">
                                        <img src={item.logo} alt="award" />
                                    </div>
                                    <p className="mobile-show">{item.name}</p>
                                </div>
                            }) : null}

                        </div>
                        <div className="col-md-6 col-12">
                            <div className="police-award">
                                <img src={awards.length && awards[this.state.currentAwardImageKey].image1} alt="Award" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contactus-bg">
                    <div className="over-lay"></div>
                    <div className="container">
                        <div className="title-desc">
                            <h1 className="title">{homePage && homePage.contactHeading}</h1>
                            <div className="cmmn-btn">
                                <Link to="/contact-us">
                                    <button className="btn">{homePage && homePage.contactButton}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


MainPage.propTypes = {
    getHomePageContent: PropTypes.func.isRequired,
    getAllBlock: PropTypes.func.isRequired,
    getAllAwards: PropTypes.func.isRequired,
    getAllInterlocks: PropTypes.func.isRequired,
    getAllProject: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});
export default connect(mapStateToProps, { getHomePageContent, getAllBlock, getAllAwards, getAllInterlocks, getAllProject })(withRouter(MainPage))