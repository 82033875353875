import React, { Component } from 'react'
import Logo from '../../assets/images/logo-dark.png'
import { withRouter, Link } from 'react-router-dom'
import { postContact } from '../../store/actions/publicActions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class Footer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            year: new Date().getFullYear()
        }

    }
    onSubmitHandler = (e) => {
        e.preventDefault()
        const data = {
            email: this.state.email,
            type: "",
            name: "",
            number: "",
            message: ""
        }
        console.log('this.state', this.state)
        this.props.postContact(data)
            .then(res => {
                if (res) {
                    this.setState({
                        email: '',
                    })
                }
            })
    }

    changeHanlder = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const { email, year } = this.state
        return (
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2 col-12">
                            <div className="logo-media">
                                <Link to="/">
                                    <div className="footer-logo">
                                        <img src={Logo} alt="logo" />
                                    </div>
                                </Link>
                                <div className="social-media">
                                    <a href="https://www.facebook.com/DubaiBlocksInterlocks" className="logo-1">
                                        <i className="fa fa-facebook" aria-hidden="true"></i>
                                    </a>
                                    <a href="https://www.linkedin.com/company/dubaiblocksllc" className="logo-1">
                                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                                    </a>
                                    <a href="https://twitter.com/dubaiblocksllc" className="logo-1">
                                        <i className="fa fa-twitter" aria-hidden="true"></i>
                                    </a>
                                    <a href="https://www.instagram.com/dubaiblocksllc/" className="logo-1">
                                        <i className="fa fa-instagram" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-5 col-12 footer-links">
                            <ul className="list">
                                <li className="list-item">
                                    <Link to="/about">ABOUT US</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/products">PRODUCTS</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/projects">PROJECTS</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/awards">AWARDS</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/contact-us">CONTACT US</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/join-us">CAREERS</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="email-subscribe">
                                <p className="sec-para">Keep up with our latest innovations and insider news</p>
                                <form onSubmit={this.onSubmitHandler}>
                                    <input type="text" name="email" value={email} onChange={this.changeHanlder} placeholder="Email Address" />
                                    <input className="subscribe-btn" type="submit" value="SUBSCRIBE" />
                                </form>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <hr />
                    <p className="bottom-line">© {year} DUBAI BLOCKS LLC. ALL rights reserved</p>
                </div>
            </footer>
        )
    }
}

Footer.propTypes = {
    postContact: PropTypes.func.isRequired
}

export default connect(null, { postContact })(withRouter(Footer))