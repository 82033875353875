import React, { Component } from 'react'
import { getAllInterlockDesign, postInterlockDesignOrder } from '../../../store/actions/publicActions'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Spinner from "../../common/Spinner";
import { deleteInterlockDesign } from '../../../store/actions/adminActions'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

class InterlockDesign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interlockDesigns: [],
            loadDesigns: false,
        }
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        this.props.getAllInterlockDesign()
    }
    componentWillUnmount() {
        this.setState({
            loadDesigns: false,
        });
    }

    static getDerivedStateFromProps(props, state) {
        const { loading, interlockDesigns } = props.admin;

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        console.log("state.loadDesigns", state.loadDesigns, state.interlockDesigns, interlockDesigns)
        if (JSON.stringify(interlockDesigns) !== JSON.stringify(state.interlockDesigns) && !state.loadDesigns && interlockDesigns?.length > 1) {
            state.interlockDesigns = interlockDesigns;
            state.loadDesigns = true;
        }

        return state
    }

    editHandler = (id) => {
        this.props.history.push("/admin/interlock-design/edit/" + id)
    }

    onDragEnd(result) {
        console.log("here")
        if (!result.destination) {
            return;
        }
        
        const items = reorder(
            this.state.interlockDesigns,
            result.source.index,
            result.destination.index
        );    
        this.setState({
            interlockDesigns: items,
        });

        if (items.length) {
            this.props.postInterlockDesignOrder(items.map((item, i) => ({ id: item.id, sort: i })))
        }
    }

    render() {
        const { loading, interlockDesigns } = this.state;
        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="add-btn-container">
                    <Link to="/admin/interlock-design/add">
                        <button type="button">Interlock Designs</button>
                    </Link>
                </div>
                <div className="card contact-card message-response all-interlock-design">
                    <div className="card-body">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            >

                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" className="heading-col">Image</th>
                                    <th scope="col" className="material-col">Material</th>
                                    <th scope="col" className="category-col">Category</th>
                                    <th scope="col" className="category-col">Colors</th>
                                    <th scope="col" className="action-col">Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {Array.isArray(interlockDesigns) ?
                                    interlockDesigns.map((item, index) => (
                                        <Draggable key={(item.id).toString()} draggableId={(item.id).toString()} index={index}>
                                        {(provided, snapshot) => (
                                            <tr ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <td className="dp-col">
                                                    <span>
                                                        <img alt="#" src={item.image} className="green-img" height={100} width={100} />
                                                    </span>
                                                </td>
                                                <td>{item.material.map(item => item).join(", ")}</td>
                                                <td>{item.category_id?.length ? item.category_id.map((el) => el?.category_id?.id).join(", ") : ""}</td>
                                                <td className="color-content" >
                                                    {/* {item.colors.map((value, key) => ( */}
                                                        <span className="color" style={{ backgroundColor: item.color1, width:"35px", height:"35px" }}></span>
                                                        <span className="color" style={{ backgroundColor: item.color2, width:"35px", height:"35px" }}></span>
                                                        <span className="color" style={{ backgroundColor: item.color3, width:"35px", height:"35px" }}></span>
                                                        <span className="color" style={{ backgroundColor: item.color4, width:"35px", height:"35px" }}></span>
                                                        <span className="color" style={{ backgroundColor: item.color5, width:"35px", height:"35px" }}></span>
                                                    {/* ))} */}
                                                </td>
                                                <td className="action-btn">
                                                    <button type="button" onClick={() => { this.editHandler(item.id) }} className="form-control">Edit</button>
                                                    <button type="button" onClick={() => { if (window.confirm('Delete the Interlock Design?')) { this.props.deleteInterlockDesign(item.id) } }} className="form-control"> Delete</button>
                                                </td>
                                            </tr>
                                            )}
                                        </Draggable>
                                    ))
                                    :
                                    <tr>No Interlock Design Available</tr>
                                }
                            </tbody>
                        </table>
                            {provided.placeholder}
                                </div>
                            )}
                            </Droppable>
                        </DragDropContext>

                    </div>
                </div>
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Interlock Designs</h5>
                            </div>
                        </div>
                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}
InterlockDesign.prototypes = {
    getAllInterlockDesign: PropTypes.func.isRequired,
    postInterlockDesignOrder: PropTypes.func.isRequired,
    deleteInterlockDesign: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { getAllInterlockDesign, postInterlockDesignOrder, deleteInterlockDesign })(withRouter(InterlockDesign))