import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllCategory, deleteCategory } from '../../../store/actions/adminActions'
import Spinner from '../../common/Spinner';

class Category extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            categories: [],
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { loading, categories } = props.admin

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        if (JSON.stringify(categories) !== JSON.stringify(state.categories)) {
            state.categories = categories
        }

        return state
    }

    componentDidMount() {
        this.props.getAllCategory()
    }

    editHandler = (id) => {
        this.props.history.push('/admin/category/edit/' + id)
    }

    render() {
        const { loading, categories } = this.state

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="add-btn-container">
                    <Link to="/admin/category/add">
                        <button type="button">Add Category</button>
                    </Link>
                </div>
                <div className="card contact-card message-response all-block">
                    <div className="card-body">
                        {categories && categories.length ?
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="name">Name</th>
                                        <th scope="col" className="action-col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categories.map((item, key) => {
                                        return <tr key={key}>
                                            <td>{item.category}</td>
                                            <td className="action-btn">
                                                <button type="button" onClick={() => { this.editHandler(item.id) }} className="form-control">Edit</button>
                                                <button type="button" onClick={() => { if (window.confirm('Delete the Category?')) { this.props.deleteCategory(item.id) } }} className="form-control"> Delete</button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table> : <h2>No Category Available</h2>}
                    </div>
                </div>
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5> Categories </h5>
                            </div>
                        </div>

                        {pageContent}

                    </div>
                </div>
            </div>
        )
    }
}


Category.prototypes = {
    getAllCategory: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { getAllCategory, deleteCategory })(withRouter(Category))