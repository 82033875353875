import React, { Component } from 'react'
import Logo from '../../assets/images/logo-dark.png'
import { getAllBlock, getAllInterlocks } from '../../store/actions/publicActions'
import { withRouter, Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blocks: [],
            interlocks: []
        }
    }
    componentDidMount() {
        this.props.getAllBlock()
        this.props.getAllInterlocks()
    }

    static getDerivedStateFromProps(props, state) {
        const { blocks, interlocks } = props.admin
        if (JSON.stringify(blocks) !== JSON.stringify(state.blocks)) {
            state.blocks = blocks
        }


        if (JSON.stringify(interlocks) !== JSON.stringify(state.interlocks)) {
            state.interlocks = interlocks
        }
        return state
    }



    render() {
        const { blocks, interlocks } = this.state
        return (
            <nav className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand pl-2 pt-2" to="/">
                    <img src={Logo} alt="logo" />
                </Link>
                <button className="navbar-toggler pr-3" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="icon-bar top-bar"></span>
                    <span className="icon-bar middle-bar"></span>
                    <span className="icon-bar bottom-bar"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto px-2 pb-3">
                        <li className="nav-item">
                            <NavLink exact className="nav-link" to="/about">ABOUT US <span className="sr-only">(current)</span></NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <NavLink exact className="nav-link mob-based-icon" to="/products" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                PRODUCTS
                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                            </NavLink>
                            <div className="dropdown-menu all-items" aria-labelledby="navbarDropdown">
                                <span className="dropdown-item mob-screenxyz">BLOCKS</span>
                                <div className="first-item">
                                <span className="dropdown-item desktop-screenxyz">BLOCKS</span>
                                    <div className="right-first-dropdown" aria-labelledby="navbarDropdown">
                                        {blocks?.length ? blocks.map((item, key) => {
                                            return <Link key={key} className="dropdown-item" to={`/products/block/${item.id}`}>
                                                <img src={item.mainImage} alt="blocks" /> {item.name}
                                            </Link>
                                        }) : null}
                                    </div>
                                </div>
                                <div className="dropdown-divider"></div>
                                <span className="dropdown-item second-part mob-screenxyz" >INTERLOCKS</span>
                                <div className="second-item">
                                <span className="dropdown-item second-part desktop-screenxyz" >INTERLOCKS</span>
                                    <div className="right-second-dropdown" aria-labelledby="navbarDropdown">
                                        {interlocks?.length ? interlocks.map((item, key) => {
                                            return <Link key={key} className="dropdown-item" to={`/products/interlock/${item.id}`}>
                                                <img src={item?.variety?.[0]?.image} alt="interlocks" /> {item.name}
                                            </Link>
                                        }) : null}
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/interlock-design">INTERLOCK DESIGN</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact className="nav-link" to="/projects">PROJECTS</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact className="nav-link" to="/awards">AWARDS</NavLink>
                        </li>
                        <li className="nav-item ">
                            <NavLink exact className="nav-link" to="/contact-us">CONTACT US</NavLink>
                        </li>
                    </ul>
                </div>
            </nav>

        )
    }
}


Header.propTypes = {
    getAllBlock: PropTypes.func.isRequired,
    getAllInterlocks: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});
export default connect(mapStateToProps, { getAllBlock, getAllInterlocks })(withRouter(Header))
