import React, { Component } from 'react'
import { getAllBlock } from '../../../store/actions/publicActions'
import { deleteBlock } from '../../../store/actions/adminActions'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Spinner from '../../common/Spinner'

class Block extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            blocks: []
        }
    }
    componentDidMount() {
        this.props.getAllBlock()
    }

    static getDerivedStateFromProps(props, state) {
        const { loading, blocks } = props.admin;
        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        if (JSON.stringify(blocks) !== JSON.stringify(state.blocks)) {
            state.blocks = blocks
        }

        return state
    }

    editHandler = (id) => {
        this.props.history.push('/admin/blocks/edit/' + id)
    }

    deleteHandler = (id) => {
        this.props.deleteBlock(id)
    }

    render() {
        const { loading, blocks } = this.state;

        console.log('blocks', blocks)

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="add-btn-container">
                    <Link to="/admin/blocks/add">
                        <button type="button">Add Block</button>
                    </Link>
                </div>
                <div className="card contact-card message-response all-block">
                    <div className="card-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" className="heading-name">Heading</th>
                                    <th scope="col" className="image-col">Image</th>
                                    <th scope="col" className="size-col">Size</th>
                                    <th scope="col" className="action-col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(blocks) && blocks.map((item, key) => {
                                    return <tr key={key}>
                                        <td>{item.name}</td>
                                        <td className="dp-col">
                                            <span>
                                                <img alt="#" src={item.mainImage} className="green-img" />
                                            </span>
                                        </td>
                                        <td>
                                            {item.sizes.map((value, index) => (
                                                <li><span> Size: {value.size} </span><span style={{ marginLeft: '16px', marginRight: '16px' }}> Weight: {value.weight} </span><span> Density: {value.density}</span></li>
                                            ))}
                                        </td>
                                        <td className="action-btn">
                                            <button type="button" onClick={() => this.editHandler(item.id)} className="form-control">Edit</button>
                                            <button type="button" onClick={() => { if (window.confirm('Delete the Block?')) { this.deleteHandler(item.id) } }} className="form-control"> Delete</button>
                                        </td>
                                    </tr>
                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Dubai Blocks</h5>
                            </div>
                        </div>
                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}

Block.propTypes = {
    getAllBlock: PropTypes.func.isRequired,
    deleteBlock: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { getAllBlock, deleteBlock })(withRouter(Block))
