import {
    ADMIN_LOADING,
    ADMIN_LOADED,
    PRODUCT_PARAGRAPH,
    GET_ALL_CONTACT_US_RESPONSE,
    GET_ALL_BLOCKS,
    GET_ALL_PROJECTS,
    GET_ALL_AWARDS,
    GET_ALL_INTERLOCKS,
    GET_ALL_CATEGORY,
    GET_ALL_INTERLOCK_DESIGN,
    GET_HOME_CONTENT,
    GET_ABOUT_CONTENT,
    GET_CONTACTUS_CONTENT,
    DELETE_CATEGORY,
    DELETE_INTERLOCK,
    DELETE_PROJECT,
    DELETE_AWARD,
    DELETE_INTERLOCK_DESIGN,
    GET_SINGLE_BLOCK,
    GET_SINGLE_INTERLOCK,
    DELETE_BLOCK,
    GET_ALL_CAREER_RESPONSE,
    DELETE_CAREER,
    DELETE_CONTACT_US,
    GET_CAREER_CONTENT,
    GET_DASHBOARD
} from '../actions/types';

const initialState = {
    loading: false,
    contactResponse: [],
    careerResponse: [],
    productParagraph: {},
    blocks: [],
    projects: [],
    awards: [],
    interlocks: [],
    singleAward: null,
    categories: [],
    homePage: {},
    contactUsPage: {},
    careerPage: {},
    aboutPage: {},
    singleBlock: {},
    singleInterlock: {},
    interlockDesigns: [],
    dashboard: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADMIN_LOADING:
            return {
                ...state,
                loading: true
            };

        case GET_DASHBOARD: {
            return {
                ...state,
                dashboard: action.payload.data === null ? {} : action.payload.data[0]
            }
        }

        case GET_SINGLE_BLOCK: {
            return {
                ...state,
                singleBlock: action.payload.data === null ? {} : action.payload.data[0]
            }
        }
        case GET_SINGLE_INTERLOCK: {
            return {
                ...state,
                singleInterlock: action.payload.data === null ? {} : action.payload.data[0]
            }
        }

        case ADMIN_LOADED:
            return {
                ...state,
                loading: false
            };
        case GET_ALL_CATEGORY:
            return {
                ...state,
                categories: action.payload.data === null ? [] : action.payload.data
            };
        case DELETE_CATEGORY:
            return {
                ...state,
                categories: state.categories.filter(item => item.id !== action.payload)
            };
        case PRODUCT_PARAGRAPH:
            return {
                ...state,
                productParagraph: action.payload.data[0]
            }
        case GET_ALL_AWARDS:
            return {
                ...state,
                awards: action.payload.data === null ? [] : action.payload.data
            }
        case DELETE_AWARD:
            return {
                ...state,
                awards: state.awards.filter(item => item.id !== action.payload)
            }
        case GET_ALL_BLOCKS:
            return {
                ...state,
                blocks: action.payload && action.payload.data === null ? [] : action.payload.data
            }
        case DELETE_BLOCK:
            return {
                ...state,
                blocks: state.blocks.filter(item => item.id !== action.payload)
            }
        case DELETE_CONTACT_US:
            return {
                ...state,
                contactResponse: state.contactResponse.filter(item => item.id !== action.payload)
            }
        case DELETE_CAREER:
            return {
                ...state,
                careerResponse: state.careerResponse.filter(item => item.id !== action.payload)
            }
        case GET_ALL_INTERLOCKS:
            return {
                ...state,
                interlocks: action.payload && action.payload.data === null ? [] : action.payload.data
            }
        case DELETE_INTERLOCK:
            return {
                ...state,
                interlocks: state.interlocks.filter(item => item.id !== action.payload)
            }
        case GET_ALL_PROJECTS:
            return {
                ...state,
                projects: action.payload.data === null ? [] : action.payload.data
            }
        case DELETE_PROJECT:
            return {
                ...state,
                projects: state.projects.filter(item => item.id !== action.payload)
            }
        case GET_ALL_INTERLOCK_DESIGN:
            return {
                ...state,
                interlockDesigns: action.payload.data === null ? [] : action.payload.data
            }
        case DELETE_INTERLOCK_DESIGN:
            return {
                ...state,
                interlockDesigns: state.interlockDesigns.filter(item => item.id !== action.payload)
            }
        case GET_ABOUT_CONTENT:
            return {
                ...state,
                aboutPage: action.payload.data[0]
            }
        case GET_CONTACTUS_CONTENT:
            return {
                ...state,
                contactUsPage: action.payload.data[0]
            }

        case GET_CAREER_CONTENT:
            return {
                ...state,
                careerPage: action.payload.data[0]
            }
        case GET_HOME_CONTENT:
            return {
                ...state,
                homePage: action.payload.data[0]
            }
        case GET_ALL_CONTACT_US_RESPONSE:
            return {
                ...state,
                contactResponse: action.payload.data === null ? [] : action.payload.data
            }

        case GET_ALL_CAREER_RESPONSE:
            return {
                ...state,
                careerResponse: action.payload.data === null ? [] : action.payload.data
            }

        default:
            return state
    }
}