import React, { Component } from 'react'
import { updateInterlock } from '../../../store/actions/adminActions'
import { getSingleInterlock } from '../../../store/actions/publicActions'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../common/Spinner';
import { getBase64, uploadedImageName } from '../../common/Base64'
import { NotificationManager } from 'react-notifications';

class EditInterlocks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            id: '',
            name: "",
            dimensions: '',
            dimensionsName: '',
            weight: '',
            thickness: '',
            area: '',
            variety: [],
            singleInterlock: {},
            interlock: {},
            deletedVariety: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.hist) {
            if (props.match.path != props.hist.path) {
                props.changematch();
                props.history.push(props.hist.path);
            }
        }

        const { loading, singleInterlock } = props.admin;

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        // console.log("here",state.variety )

        if (singleInterlock && JSON.stringify(singleInterlock) !== JSON.stringify(state.interlock)) {
            console.log('single', singleInterlock)
            state.interlock = singleInterlock;
            state.id = singleInterlock.id;
            state.name = singleInterlock.name;
            state.dimensionsName = state.dimensions === "" ? singleInterlock.dimensions.slice(-23) : state.dimensionsName;
            state.weight = singleInterlock.weight;
            //state.weightUpperLimit = singleInterlock.weight.upperLimit;
            state.thickness = singleInterlock.thickness;
            //state.thicknessUpperLimit = singleInterlock.thickness.upperLimit;
            state.area = singleInterlock.area;
            for (let i = 0; i < singleInterlock.variety.length; i++) {
                singleInterlock.variety[i].uploadImageName = singleInterlock.variety[i].image
                singleInterlock.variety[i].image = ""
            }
            state.variety = singleInterlock.variety

        }
        return state
    }

    addVarity = () => {
        const add = {
            image: '',
            color: '',
            serialnumber: '',
        }

        this.setState({
            variety: [...this.state.variety, add]
        })
        console.log("Add", this.state.variety)

    }

    removeVarity = (id, index) => {
        const oldVariety = [...this.state.variety];
        const deletedVariety = [...this.state.deletedVariety]
        deletedVariety.push(id)
        oldVariety.splice(index, 1);
        this.setState({
            variety: oldVariety,
            deletedVariety: deletedVariety
        })
    }

    componentDidMount() {
        const id = this.props.match.params.id
        this.props.getSingleInterlock(id)

    }

    handleValidation() {
        const fields = this.state;
        let formIsValid = true;

        if (!fields["name"]) {
            formIsValid = false;
            NotificationManager.error('Name field is required')
        }


        if (!fields["weight"]) {
            formIsValid = false;
            NotificationManager.error('Weight   field is required')
        }


        if (!fields["thickness"]) {
            formIsValid = false;
            NotificationManager.error('Thickness field is required')
        }

        if (!fields["area"]) {
            formIsValid = false;
            NotificationManager.error('Area field is required')
        }

        console.log('fields["variety"].length === 0', fields["variety"].length === 0)

        if (fields["variety"].length === 0) {
            formIsValid = false;
            NotificationManager.error('verity field is required')
        }
        else {
            fields["variety"].map(item => {
                if (!item["color"]) {
                    formIsValid = false;
                    NotificationManager.error('color field is required')
                }

                if (!item["serialnumber"]) {
                    formIsValid = false;
                    NotificationManager.error('serialnumber field is required')
                }

            })
        }

        return formIsValid;
    }

    submitHandler = async (e) => {
        e.preventDefault()

        if (!this.handleValidation()) {
            return
        }

        const data = {
            id: this.state.id,
            name: this.state.name,
            description: this.state.description,
            area: parseInt(this.state.area),
            dimensions: this.state.dimensions,
            weight: this.state.weight,
            thickness: this.state.thickness,
            variety: this.state.variety,
            deletedVariety: this.state.deletedVariety
        }
        console.log('update Interlock', data)
        this.props.updateInterlock(data, this.props.history)
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    ChangeVarityValue = (key, event) => {
        const variety = [...this.state.variety]
        variety[key][event.target.name] = event.target.value
        this.setState({
            variety: variety
        })
    }

    changeVarityImage = async (key, event) => {
        const variety = [...this.state.variety]

        const image = await getBase64(event.target.files[0])
        variety[key][event.target.name] = image
        variety[key]["imageName"] = event.target?.files?.[0]?.name

        this.setState({
            variety: variety
        })
    }

    changeDimensionImage = async (event) => {
        const image = await getBase64(event.target.files[0])
        this.setState({
            dimensions: image,
            dimensionsName: event.target.files[0].name
        })
    }

    render() {
        const { loading, name, variety, area, thickness, weight, dimensionsName, dimensions } = this.state
        console.log('variety', variety, 'thickness', thickness)
        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="card contact-card">
                    <div className="card-header">
                        Edit Interlock
                </div>
                    <form onSubmit={this.submitHandler}>
                        <div className="card-body">
                            <div className="form-group small-section">
                                <label>Interlock Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={this.onChangeHandler}
                                    className="form-control"
                                    placeholder="Enter interlock name"
                                />
                            </div>
                            <div className="form-group small-section right-part">
                                <label>Enter No/m<sup>2</sup>:</label>
                                <input
                                    type="text"
                                    name="area"
                                    value={area}
                                    onChange={this.onChangeHandler}
                                    className="form-control"
                                    placeholder="Enter no/m"
                                />
                            </div>

                            <div className="form-group size-fields">
                                <label>Enter Dimension:</label>
                                <div className="dimension-section fifty-percent">

                                    <div className="size-field-container">
                                        <div className="form-group">
                                            <div className="upload-btn-wrapper">
                                                <button className="btn">{dimensionsName ? dimensionsName : "Upload an Image"}</button>
                                                <input
                                                    type="file"
                                                    name="dimensions"
                                                    onChange={(e) => this.changeDimensionImage(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group size-fields">
                                <div className="dimension-section fifty-percent">
                                    <div className="size-field-container">
                                        <label>Enter Thickness:</label>
                                        <input
                                            type="text"
                                            name="thickness"
                                            onChange={this.onChangeHandler}
                                            value={thickness}
                                            className="form-control"
                                            placeholder="Enter thickness"
                                        />
                                    </div>
                                    <div className="size-field-container">
                                        <label>Enter Weight/Block:</label>
                                        <input
                                            type="text"
                                            name="weight"
                                            onChange={this.onChangeHandler}
                                            value={weight}
                                            className="form-control"
                                            placeholder="Enter Weight"
                                        />
                                    </div>
                                </div>
                            </div>
                            {variety.map((item, key) => (
                                <div className="form-group size-fields" key={key}>
                                    <div className="add-more-btn-container">
                                        <span className="add-more-btn" onClick={() => this.removeVarity(item.id, key)}>Remove -</span>
                                    </div>
                                    <div className="dimension-section">
                                        <div className="size-field-container">
                                            <label>Choose Color:</label>
                                            <input
                                                type="color"
                                                name="color"
                                                value={item.color}
                                                onChange={(e) => this.ChangeVarityValue(key, e)}
                                                className="form-control" placeholder="Choose color"
                                            />
                                        </div>
                                        <div className="size-field-container">
                                            <label>Enter Serial Number</label>
                                            <input
                                                type="text"
                                                name="serialnumber"
                                                value={item.serialnumber}
                                                onChange={(e) => this.ChangeVarityValue(key, e)}
                                                className="form-control"
                                                placeholder="Enter serial number"
                                            />
                                        </div>
                                        <div className="size-field-container">
                                            <label>Interlock Image</label>
                                            <div className="form-group">
                                                <div className="upload-btn-wrapper">
                                                    <button className="btn">{item?.imageName ?? uploadedImageName(item.uploadImageName)}</button>
                                                    <input
                                                        type="file"
                                                        name="image"
                                                        onChange={(e) => this.changeVarityImage(key, e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <span onClick={this.addVarity} className="add-more-btn">Add more +</span>

                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn submit-btn mr-2">Submit</button>
                            <button type="reset" className="btn cancel-btn" onClick={() => this.props.history.push("/admin/interlocks")}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Edit Interlock</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}


EditInterlocks.prototypes = {
    updateInterlock: PropTypes.func.isRequired,
    getSingleInterlock: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { updateInterlock, getSingleInterlock })(withRouter(EditInterlocks))