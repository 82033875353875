import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getContactUsContent } from '../../../store/actions/publicActions'
import { updateContactUsPageSetting } from '../../../store/actions/adminActions'
import PropTypes from 'prop-types';
import Spinner from '../../common/Spinner';
import { NotificationManager } from 'react-notifications';

class ContactusPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            id: '',
            phone: '',
            email: '',
            emailHelp: ['1', '2', '3', '4'],
            fax: '',
            address: '',
            hrEmail: '',
            saleEmail: '',
            contactUsPage: {}
        }
    }

    componentDidMount() {
        this.props.getContactUsContent()
    }
    static getDerivedStateFromProps(props, state) {
        const { loading, contactUsPage } = props.admin;

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        if (JSON.stringify(contactUsPage) !== JSON.stringify(state.contactUsPage)) {
            state.contactUsPage = contactUsPage;
            state.id = contactUsPage.id;
            state.phone = contactUsPage.phone;
            //state.email = contactUsPage.email.join("\n");
            if (contactUsPage.email) {
                let helpemail = "";
                for (let i = 0; i < contactUsPage.email.length; i++) {
                    if (i == 0) {
                        helpemail = contactUsPage.email[i].email
                    }
                    else {
                        helpemail = helpemail + "\n" + contactUsPage.email[i].email
                    }
                }
                state.email = helpemail
            }
            state.fax = contactUsPage.fax;
            state.address = contactUsPage.address;
            state.hrEmail = contactUsPage.hrEmail;
            state.saleEmail = contactUsPage.saleEmail;
        }

        return state
    }

    handleValidation() {
        const fields = this.state;
        let formIsValid = true;

        if (!fields["phone"]) {
            formIsValid = false;
            NotificationManager.error('Phone field is required')
        }

        if (!fields["email"]) {
            formIsValid = false;
            NotificationManager.error('Email field is required')
        }


        if (!fields["fax"]) {
            formIsValid = false;
            NotificationManager.error('Fax field is required')
        }

        if (!fields["address"]) {
            formIsValid = false;
            NotificationManager.error('Address field is required')
        }

        if (!fields["hrEmail"]) {
            formIsValid = false;
            NotificationManager.error('Hr email field is required')
        }

        if (!fields["saleEmail"]) {
            formIsValid = false;
            NotificationManager.error('Sale email field is required')
        }

        return formIsValid;
    }

    onSubmitHandler = (e) => {
        e.preventDefault()

        if (!this.handleValidation()) {
            return
        }

        const email = this.state.email.split(/\r?\n/)
        const data = {
            id: this.state.id,
            phone: this.state.phone,
            email: email,
            fax: this.state.fax,
            address: this.state.address,
            hrEmail: this.state.hrEmail,
            saleEmail: this.state.saleEmail
        }

        console.log('data', data)
        this.props.updateContactUsPageSetting(data, this.props.history)
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    render() {
        const { loading, phone, email, address, hrEmail, saleEmail, fax } = this.state;
        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="card contact-card">
                    <form onSubmit={this.onSubmitHandler}>
                        <div className="card-body">
                            <div className="form-group small-section">
                                <label>phone:</label>
                                <input type="text" name="phone"
                                    value={phone}
                                    className="form-control"
                                    placeholder="Enter tel number"
                                    onChange={this.onChangeHandler}
                                />

                            </div>

                            <div className="form-group small-section right-part">
                                <label>Fax:</label>
                                <input type="text" onChange={this.onChangeHandler} name="fax" value={fax} className="form-control" placeholder="Enter fax number" />
                            </div>
                            <div className="form-group small-section">
                                <label>Address:</label>
                                <input type="text" onChange={this.onChangeHandler} name="address" value={address} className="form-control" placeholder="Enter box size" />
                            </div>
                            <div className="form-group small-section right-part">
                                <label>Email:</label>
                                <textarea type="email" onChange={this.onChangeHandler} name="email" value={email} className="form-control" placeholder="Enter email" ></textarea>
                            </div>
                            <div className="form-group small-section">
                                <label>Sales Department:</label>
                                <input type="text" onChange={this.onChangeHandler} name="saleEmail" value={saleEmail} className="form-control" placeholder="Enter sales department" />
                            </div>
                            <div className="form-group small-section right-part">
                                <label>HR Department:</label>
                                <input type="text" onChange={this.onChangeHandler} name="hrEmail" value={hrEmail} className="form-control" placeholder="Enter HR department" />
                            </div>
                        </div>

                        <div className="card-footer">
                            <button type="submit" className="btn submit-btn mr-2">Submit</button>
                            <button type="reset" className="btn cancel-btn">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Contact Us Page</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}

ContactusPage.propTypes = {
    getContactUsContent: PropTypes.func.isRequired,
    updateContactUsPageSetting: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { getContactUsContent, updateContactUsPageSetting })(withRouter(ContactusPage))