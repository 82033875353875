import React, { Component } from 'react'
import { getContactusResponse, deleteContactUsForm } from '../../../store/actions/adminActions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import Spinner from '../../common/Spinner';
import moment from 'moment'

class ContactDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            contactResponse: [],
            contactAllResponse: [],
            setValue: true
        }
    }
    componentDidMount() {
        this.props.getContactusResponse()
    }

    static getDerivedStateFromProps(props, state) {
        const { loading, contactResponse } = props.admin;


        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }
        if (contactResponse && state.setValue && JSON.stringify(state.contactResponse) !== JSON.stringify(contactResponse)) {
            state.contactResponse = contactResponse
            state.contactAllResponse = contactResponse
            state.setValue = false
        }

        return state
    }

    SelectBox = (e) => {
        if (e.target.value !== "all") {
            const result = this.state.contactAllResponse.filter(function (el) {
                if (e.target.value === "contactus")
                    return el.type != "";
                else
                    return el.type === ""
            });
            this.setState({
                contactResponse: result
            })
        }
        else {
            this.setState({
                contactResponse: this.state.contactAllResponse
            })
        }

    }
    render() {
        const { loading, contactResponse } = this.state;

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                {contactResponse.length ? <div className="select-box-admin">
                    <select onChange={this.SelectBox} className="select-box-admin-select" >
                        <option value="all">All </option>
                        <option value="contactus">Contact Us</option>
                        <option value="subscriber">Subscriber </option>
                    </select>
                </div> : null}

                {contactResponse.length ? <div className="card contact-card message-response">

                    <div className="card-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" className="company-name">Company Name</th>
                                    <th scope="col" className="contact-no">Contact No.</th>
                                    <th scope="col" className="email">Email</th>
                                    <th scope="col" className="message-col">Messages</th>
                                    <th scope="col" className="message-col">Location</th>
                                    <th scope="col" className="message-col">Hear About Us</th>
                                    <th scope="col" className="message-col">Date</th>
                                    <th scope="col" className="action-col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contactResponse.map((item, key) => {
                                    return <tr key={key}>
                                        <td>{item.name}</td>
                                        <td>{item.number}</td>
                                        <td>{item.email}</td>
                                        <td>{item.message}</td>
                                        <td>{item.location ?? ""}</td>
                                        <td>{item.advertisement}</td>
                                        <td>{item.created_at ? moment(item.created_at).format('LLL') : null}</td>
                                        <td className="action-btn">
                                            <button type="button" className="form-control" onClick={() => this.props.history.push(`/admin/view-contact-details/${item.id}`)}>View</button>
                                            <button type="button" onClick={() => { if (window.confirm('Are You Sure?')) { this.props.deleteContactUsForm(item.id) } }} className="form-control"> Delete</button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div> : <h2>No Record is Available</h2>}
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Contact us Forms</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}

ContactDetail.propTypes = {
    getContactusResponse: PropTypes.func.isRequired,
    deleteContactUsForm: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { getContactusResponse, deleteContactUsForm })(withRouter(ContactDetail))
