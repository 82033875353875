import React, { Component } from 'react'
import { getContactusResponse, deleteContactUsForm } from '../../../store/actions/adminActions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import Spinner from '../../common/Spinner';

class ViewContactDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            contactResponse: []
        }
    }
    componentDidMount() {
        this.props.getContactusResponse()
    }

    static getDerivedStateFromProps(props, state) {
        const { loading, contactResponse } = props.admin;
        if (props.hist) {
            if (props.match.path != props.hist.path) {
                props.changematch();
                props.history.push(props.hist.path);
            }
        }


        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        if (contactResponse && JSON.stringify(state.contactResponse) !== JSON.stringify(contactResponse)) {
            state.contactResponse = contactResponse
        }

        return state
    }

    render() {
        const { loading, contactResponse } = this.state;
        const id = parseInt(this.props.match?.params?.id);
        const contactData = contactResponse?.length ? contactResponse.find((el) => el.id === id) : {};

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                {contactData ? <div className="card contact-card message-response">
                    <div className="card card-custom">
                        <div className="card-header h-auto py-4">
                            <div className="card-title">
                                <h3 className="card-label">
                                    <span className="d-block text-muted pt-2 font-size-sm">Contact Details</span></h3>
                            </div>
                        </div>

                        <div className="card-body py-4">
                            <div className="form-group row my-2">
                                <label className="col-4 col-form-label">Contact Person:</label>
                                <div className="col-8">
                                    <span className="form-control-plaintext font-weight-bolder">{contactData.name}</span>
                                </div>
                            </div>
                            <div className="form-group row my-2">
                                <label className="col-4 col-form-label">Phone:</label>
                                <div className="col-8">
                                    <span className="form-control-plaintext font-weight-bolder">{contactData.number}</span>
                                </div>
                            </div>
                            <div className="form-group row my-2">
                                <label className="col-4 col-form-label">Email:</label>
                                <div className="col-8">
                                    <span className="form-control-plaintext font-weight-bolder">{contactData.email}</span>
                                </div>
                            </div>
                            <div className="form-group row my-2">
                                <label className="col-4 col-form-label">Type:</label>
                                <div className="col-8">
                                    <span className="form-control-plaintext font-weight-bolder">{contactData.type}</span>
                                </div>
                            </div>
                            <div className="form-group row my-2">
                                <label className="col-4 col-form-label">Message:</label>
                                <div className="col-8">
                                    <span className="form-control-plaintext font-weight-bolder">{contactData.message}</span>
                                </div>
                            </div>
                            <div className="form-group row my-2">
                                <label className="col-4 col-form-label">Location:</label>
                                <div className="col-8">
                                    <span className="form-control-plaintext font-weight-bolder">{contactData.location ?? ""}</span>
                                </div>
                            </div>
                            <div className="form-group row my-2">
                                <label className="col-4 col-form-label">Hear About Us:</label>
                                <div className="col-8">
                                    <span className="form-control-plaintext font-weight-bolder">{contactData.advertisement ?? ""}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <h2>No Record is Available</h2>}
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>View Contact Details</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}

ViewContactDetail.propTypes = {
    getContactusResponse: PropTypes.func.isRequired,
    deleteContactUsForm: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { getContactusResponse, deleteContactUsForm })(withRouter(ViewContactDetail))
