import { SET_ADMIN } from '../actions/types'

const initialState = {
    isAuthenticated: false,
    admin: {}
}

export default function (state = initialState, action) {
    switch (action.type) {

        case SET_ADMIN:
            return {
                ...state,
                isAuthenticated: action.payload
            }
        default:
            return state
    }
}