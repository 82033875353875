import React from 'react';

export const getBase64 = file => {
    if (file === "") {
        return ''
    }

    console.log('file', file)

    return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);
        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            //console.log("Called", reader);
            baseURL = reader.result;
            console.log('baseURl', baseURL);
            resolve(baseURL);
        };
        //console.log(fileInfo);
    });
};

export const uploadedImageName = (img) => {
    if (img) {
        let imageLength = img?.split("/")
        return imageLength.length ? imageLength[imageLength.length - 1] : "Upload an Image"
    }
    return "Upload a file"
}