import React, { Component } from 'react'
import { getCareerResponse, deleteContactUsForm } from '../../../store/actions/adminActions'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import Spinner from '../../common/Spinner';
import axios from 'axios'
import * as moment from 'moment'

class ViewCareerDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            careerResponse: []
        }
    }
    componentDidMount() {
        this.props.getCareerResponse()
    }

    static getDerivedStateFromProps(props, state) {
        if (props.hist) {
            if (props.match.path != props.hist.path) {
                props.changematch();
                props.history.push(props.hist.path);
            }
        }
        const { loading, careerResponse } = props.admin;


        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        if (careerResponse && JSON.stringify(state.careerResponse) !== JSON.stringify(careerResponse)) {
            state.careerResponse = careerResponse
        }

        return state
    }

    download1 = url => {
        // var element = document.createElement("a");
        // var file = new Blob(
        //     [
        //         url
        //     ]
        // );
        // element.href = URL.createObjectURL(file);
        // element.download = "image.jpg";
        // element.click();

        // console.log('url', url)
        // axios({
        //     url: url,
        //     method: 'GET',
        //     responseType: 'blob', // Important
        // }).then((response) => {
        //     console.log('response', response)
        //     //FileDownload(response.data, 'report.csv');
        // }).catch(err => { console.log('err', err) })
    }

    // download = e => {
    //     console.log(e);
    //     alert('hello')
    //     fetch(e, {
    //         method: "GET",
    //         headers: {
    //             "access-control-allow-origin": "*",
    //             "Content-type": "application/json; charset=UTF-8"
    //         },
    //         mode: 'no-cors'
    //     })
    //         .then(response => {
    //             console.log('hello')
    //             response.arrayBuffer().then(function (buffer) {
    //                 console.log('buffer', buffer)
    //                 const url = window.URL.createObjectURL(new Blob([buffer]));
    //                 const link = document.createElement("a");
    //                 link.href = url;
    //                 link.setAttribute("download", "image.png"); //or any other extension
    //                 document.body.appendChild(link);
    //                 //link.click();
    //             });
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         });
    // };

    download = (url) => {
        fetch(url)
            .then(response => {
                response.blob().then(blob => {
                    console.log('blob', blob)
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'image.pdf';
                    a.click();
                });
                //window.location.href = response.url;
            }).catch(err => {
                console.log('err', err)
            })
    }


    render() {
        const { loading, careerResponse } = this.state;
        const id = parseInt(this.props.match?.params?.id);
        const careerData = careerResponse?.length ? careerResponse.find((el) => el.id === id) : {};

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                {careerData ? <div className="card contact-card message-response">
                    <div className="card card-custom">
                        <div className="card-header h-auto py-4">
                            <div className="card-title">
                                <h3 className="card-label">
                                    <span className="d-block text-muted pt-2 font-size-sm">Career Details</span></h3>
                            </div>
                        </div>

                        <div className="card-body py-4">
                            <div className="form-group row my-2">
                                <label className="col-4 col-form-label">Contact Person:</label>
                                <div className="col-8">
                                    <span className="form-control-plaintext font-weight-bolder">{careerData.name}</span>
                                </div>
                            </div>
                            <div className="form-group row my-2">
                                <label className="col-4 col-form-label">Phone:</label>
                                <div className="col-8">
                                    <span className="form-control-plaintext font-weight-bolder">{careerData.number}</span>
                                </div>
                            </div>
                            <div className="form-group row my-2">
                                <label className="col-4 col-form-label">Country:</label>
                                <div className="col-8">
                                    <span className="form-control-plaintext font-weight-bolder">{careerData.country}</span>
                                </div>
                            </div>
                            <div className="form-group row my-2">
                                <label className="col-4 col-form-label">Designation:</label>
                                <div className="col-8">
                                    <span className="form-control-plaintext font-weight-bolder">{careerData.designation}</span>
                                </div>
                            </div>
                            <div className="form-group row my-2">
                                <label className="col-4 col-form-label">Apply Date:</label>
                                <div className="col-8">
                                    <span className="form-control-plaintext font-weight-bolder">{moment(careerData.created_at).format("DD-MMMM-YYYY")}</span>
                                </div>
                            </div>
                            <div className="form-group row my-2">
                                <label className="col-4 col-form-label">Message:</label>
                                <div className="col-8">
                                    <span className="form-control-plaintext font-weight-bolder">{careerData.message}</span>
                                </div>
                            </div>
                            <div className="form-group row my-2">
                                <label className="col-4 col-form-label">CV:</label>
                                <div className="col-8">
                                    <span className="form-control-plaintext font-weight-bolder">
                                        {/* <a href="#" download target="_blank" onClick={() => { this.download(careerData.cv) }}  >Download  <i className="fa fa-download" /></a> */}
                                        <a href={careerData.cv} target="_blank">Download  <i className="fa fa-download" /></a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <h2>No Record is Available</h2>}
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>View Career Details</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}

ViewCareerDetail.propTypes = {
    getCareerResponse: PropTypes.func.isRequired,
    deleteContactUsForm: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { getCareerResponse, deleteContactUsForm })(withRouter(ViewCareerDetail))
