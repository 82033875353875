import React, { Component } from 'react'
import { getAllProject } from '../../../store/actions/publicActions'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            selectItem: null,
        }
    }
    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        this.props.getAllProject()
    }

    static getDerivedStateFromProps(props, state) {
        const { projects } = props.admin
        if (JSON.stringify(projects) !== JSON.stringify(state.projects)) {
            state.projects = projects
        }
        return state
    }

    clickHandler = (item) => {
        this.setState({
            selectItem: item
        })
    }

    render() {
        const { projects, selectItem } = this.state
        return (
            <div className="projects-page-container">
                <div className="bg-color">
                    <div className="container our-products">
                        <div className="row">

                            <h2 className="sec-title">Our Projects</h2>
                            <div className="blocks-our-projects row">
                                {projects?.length ? projects.map((item, key) => {
                                    return <div key={key} className="col-6 col-sm-6 col-md-4 our-project" data-toggle="modal" data-target="#model" onClick={() => this.clickHandler(item)}>
                                        <img src={item.image} alt="dubai hotel" />
                                        <p className="project">{item.name}</p>
                                    </div>
                                }) : null}

                                <div className="modal fade" id="model" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="project-modal">
                                                <div className="project-image">
                                                    <img src={selectItem && selectItem.image} alt="dubai hotel" style={{ width: '100%' }} />
                                                </div>
                                                <h2 className="place">{selectItem && selectItem.name}</h2>
                                                <p className="loc-da">Location : <span>{selectItem && selectItem.location}</span></p>
                                                <p className="loc-da">Material : <span>{selectItem && selectItem.material}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="cmmn-btn load-more">
                                <a href="#">
                                    <button className="btn">LOAD MORE PROJECTS</button>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="contactus-bg">
                    <div className="over-lay"></div>
                    <div className="container">
                    </div>
                </div>
            </div>
        )
    }
}


Project.propTypes = {
    getAllProject: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
    admin: state.admin
});
export default connect(mapStateToProps, { getAllProject })(withRouter(Project))