import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { postAward } from '../../../store/actions/adminActions'
import PropTypes from 'prop-types';
import Spinner from '../../common/Spinner';
import { getBase64 } from '../../common/Base64'
import { NotificationManager } from 'react-notifications';


class AddAward extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            image: '',
            logo: "",
            name: "",
            description: ""
        }
    }

    handleValidation() {
        const fields = this.state;
        let formIsValid = true;


        if (!fields["name"]) {
            formIsValid = false;
            NotificationManager.error('Name is required')
        }

        if (!fields["image"]) {
            formIsValid = false;
            NotificationManager.error('Image is required')
        }

        if (!fields["logo"]) {
            formIsValid = false;
            NotificationManager.error('logo  is required')
        }


        if (!fields["description"]) {
            formIsValid = false;
            NotificationManager.error('Description field is required')
        }


        return formIsValid;
    }

    onSubmitHandler = async (e) => {
        e.preventDefault()

        if (!this.handleValidation()) {
            return
        }

        const image = await getBase64(this.state.image);
        const logo = await getBase64(this.state.logo);

        const data = {
            name: this.state.name,
            description: this.state.description,
            logo,
            image,
        }

        this.props.postAward(data, this.props.history)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.hist) {
            if (props.match.path != props.hist.path) {
                props.changematch();
                props.history.push(props.hist.path);
            }
        }

        const { loading } = props.admin;

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        return state
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    imageHandler = (e) => {
        let files = e.target.files[0];
        this.setState({
            image: files
        })
    }

    logoHandler = (e) => {
        let files = e.target.files[0];
        this.setState({
            logo: files
        })
    }
    render() {
        const { loading, name, description } = this.state;

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="card contact-card">
                    <div className="card-header">
                        Add Award
                </div>

                    <form onSubmit={this.onSubmitHandler}>
                        <div className="card-body">
                            <div className="form-group small-section single">
                                <label>Award Name</label>
                                <input type="text" name="name" value={name} onChange={this.onChangeHandler} className="form-control" placeholder="Enter awrad name" />
                            </div>

                            <div className="form-group small-section">
                                <label>Award Image</label>
                                <div className="form-group">
                                    <div className="upload-btn-wrapper">
                                        <button className="btn">{this.state?.image?.name ?? "Upload an Image"}</button>
                                        <input type="file"
                                            name="image"
                                            accept="image/*"
                                            onChange={this.imageHandler}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group small-section right-part">
                                <label>Award Logo Image</label>
                                <div className="form-group">
                                    <div className="upload-btn-wrapper">
                                        <button className="btn">{this.state?.logo?.name ?? "Upload an Image"}</button>
                                        <input type="file"
                                            name="logo"
                                            accept="image/*"
                                            onChange={this.logoHandler}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group award-desc">
                                <label>Award Description</label>
                                <textarea value={description} name="description" onChange={this.onChangeHandler} className="form-control" placeholder="Add award description"></textarea>
                            </div>

                        </div>

                        <div className="card-footer">
                            <button type="submit" className="btn submit-btn mr-2">Submit</button>
                            <button type="reset" className="btn cancel-btn">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Add Award</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}


AddAward.propTypes = {
    postAward: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { postAward })(withRouter(AddAward))
