import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getProductParagraph } from '../../../store/actions/publicActions'
import { updateProductPageParagraph } from '../../../store/actions/adminActions'
import PropTypes from 'prop-types';
import Spinner from '../../common/Spinner';

class ProductParagraph extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            id: "",
            paragraph: "",
            p: {}
        }
    }

    componentDidMount() {
        this.props.getProductParagraph()
    }

    static getDerivedStateFromProps(props, state) {
        const { loading, productParagraph } = props.admin;

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }


        if (JSON.stringify(productParagraph) !== JSON.stringify(state.p)) {
            state.p = productParagraph
            // state.id = paragraph.id
            state.paragraph = productParagraph.paragraph
        }

        return state
    }

    onSubmitHandler = (e) => {
        e.preventDefault()
        const data = {
            id: 1,
            paragraph: this.state.paragraph
        }

        this.props.updateProductPageParagraph(data, this.props.history)
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const { loading, paragraph } = this.state

        let pageContent;

        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="card contact-card">
                    <form onSubmit={this.onSubmitHandler}>
                        <div className="card-body">

                            <div className="form-group">
                                <label>Page Paragraph:</label>
                                <textarea
                                    className="form-control"
                                    name="paragraph"
                                    value={paragraph}
                                    placeholder="Enter product page paragraph"
                                    onChange={this.onChangeHandler}
                                >
                                </textarea>
                            </div>

                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn submit-btn mr-2">Submit</button>
                            <button type="reset" className="btn cancel-btn">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Product Paragraph</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}


ProductParagraph.propTypes = {
    getProductParagraph: PropTypes.func.isRequired,
    updateProductPageParagraph: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    admin: state.admin
});


export default connect(mapStateToProps, { getProductParagraph, updateProductPageParagraph })(withRouter(ProductParagraph))