import React, { Component } from 'react'
import { postInterlock } from '../../../store/actions/adminActions'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getBase64 } from '../../common/Base64'
import { connect } from 'react-redux'
import Spinner from '../../common/Spinner'
import { NotificationManager } from 'react-notifications';

class addInterlock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            name: "",
            dimensions: '',
            thickness: '',
            weight: '',
            area: '',
            variety: [
                {
                    image: '',
                    color: '',
                    serialnumber: ''
                }
            ],
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.hist) {
            if (props.match.path != props.hist.path) {
                props.changematch();
                props.history.push(props.hist.path);
            }
        }

        const { loading } = props.admin;

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        return state;
    }

    addVarity = () => {
        const add = {
            image: '',
            color: '',
            serialnumber: ''
        }
        this.setState({
            variety: [...this.state.variety, add]
        })
    }

    removeVarity = index => {
        const oldVariety = [...this.state.variety];

        oldVariety.splice(index, 1);

        this.setState({
            variety: oldVariety
        })
    }

    handleValidation() {
        const fields = this.state;
        let formIsValid = true;

        if (!fields["name"]) {
            formIsValid = false;
            NotificationManager.error('Name field is required')
        }

        if (!fields["dimensions"]) {
            formIsValid = false;
            NotificationManager.error('Dimension field is required')
        }


        if (!fields["thickness"]) {
            formIsValid = false;
            NotificationManager.error('Thickness lower limit field is required')
        }

        if (!fields["weight"]) {
            formIsValid = false;
            NotificationManager.error('Thickness upper limit field is required')
        }

        if (!fields["area"]) {
            formIsValid = false;
            NotificationManager.error('Area field is required')
        }

        if (fields["variety"].length === 0) {
            formIsValid = false;
            NotificationManager.error('verity field is required')
        }
        else {
            fields["variety"].map(item => {
                if (!item["color"]) {
                    formIsValid = false;
                    NotificationManager.error('color field is required')
                }

                if (!item["serialnumber"]) {
                    formIsValid = false;
                    NotificationManager.error('serialnumber field is required')
                }

                if (!item["image"]) {
                    formIsValid = false;
                    NotificationManager.error('image field is required')
                }
            })
        }

        return formIsValid;
    }

    submitHandler = async (e) => {
        e.preventDefault()

        if (!this.handleValidation()) {
            return
        }
        const dimensions = await getBase64(this.state.dimensions)
        const data = {
            name: this.state.name,
            description: this.state.description,
            area: parseInt(this.state.area),
            dimensions: dimensions,
            weight: this.state.weight,
            thickness: this.state.thickness,
            variety: this.state.variety
        }

        this.props.postInterlock(data, this.props.history)
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    ChangeVarityValue = (key, event) => {

        const variety = [...this.state.variety]
        variety[key][event.target.name] = event.target.value
        this.setState({
            variety: variety
        })
    }

    changeVarityImage = async (key, event) => {
        const variety = [...this.state.variety]

        const image = await getBase64(event.target.files[0])
        variety[key][event.target.name] = image
        variety[key]["imageName"] = event.target?.files?.[0]?.name

        this.setState({
            variety: variety
        })
    }


    changeDimensionImage = async (event) => {

        this.setState({
            dimensions: event.target.files[0]
        })
    }

    render() {
        const { loading, name, variety, area, thickness, weight, dimensions } = this.state

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="card contact-card">
                    <div className="card-header">
                        Add Interlock
                </div>
                    <form onSubmit={this.submitHandler}>
                        <div className="card-body">
                            <div className="form-group small-section">
                                <label>Interlock Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={this.onChangeHandler}
                                    className="form-control"
                                    placeholder="Enter interlock heading"
                                />
                            </div>
                            <div className="form-group small-section right-part">
                                <label>Enter No/m<sup>2</sup>:</label>
                                <input
                                    type="text"
                                    name="area"
                                    value={area}
                                    onChange={this.onChangeHandler}
                                    className="form-control"
                                    placeholder="Enter no/m"
                                />
                            </div>
                            <div className="form-group size-fields">
                                <label>Enter Dimension:</label>
                                <div className="dimension-section fifty-percent">

                                    <div className="size-field-container">
                                        <div className="form-group">
                                            <div className="upload-btn-wrapper">
                                                <button className="btn">{dimensions ? dimensions.name : "Upload an Image"}</button>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    name="dimensions"
                                                    onChange={(e) => this.changeDimensionImage(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group size-fields">
                                <div className="dimension-section fifty-percent">
                                    <div className="size-field-container">
                                        <label>Enter Thickness:</label>
                                        <input
                                            type="text"
                                            name="thickness"
                                            onChange={this.onChangeHandler}
                                            value={thickness}
                                            className="form-control"
                                            placeholder="Enter start value"
                                        />
                                    </div>
                                    <div className="size-field-container">
                                        <label>Enter Weight/Block:</label>
                                        <input
                                            type="text"
                                            name="weight"
                                            onChange={this.onChangeHandler}
                                            value={weight}
                                            className="form-control"
                                            placeholder="Enter end value"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                {variety.map((item, key) => (
                                    <div className="form-group size-fields" key={key}>
                                        <div className="add-more-btn-container">
                                            <span className="add-more-btn" onClick={() => this.removeVarity(key)}>Remove -</span>
                                        </div>
                                        <div className="dimension-section">
                                            <div className="size-field-container">

                                                <label>Choose Color:</label>
                                                <input
                                                    type="color"
                                                    name="color"
                                                    value={item.color}
                                                    onChange={(e) => this.ChangeVarityValue(key, e)}
                                                    className="form-control" placeholder="Choose color"
                                                />
                                            </div>
                                            <div className="size-field-container">
                                                <label>Enter Serial Number</label>
                                                <input
                                                    type="text"
                                                    name="serialnumber"
                                                    value={item.serialnumber}
                                                    onChange={(e) => this.ChangeVarityValue(key, e)}
                                                    className="form-control"
                                                    placeholder="Enter serial number"
                                                />
                                            </div>
                                            <div className="size-field-container">
                                                <label>Interlock Image</label>
                                                <div className="form-group">
                                                    <div className="upload-btn-wrapper">
                                                        <button className="btn">{item?.imageName ?? "Upload an Image"}</button>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            name="image"
                                                            onChange={(e) => this.changeVarityImage(key, e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <span onClick={this.addVarity} className="add-more-btn">Add more +</span>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn submit-btn mr-2">Submit</button>
                            <button type="reset" className="btn cancel-btn" onClick={() => this.props.history.push("/admin/interlocks")}>Cancel</button>
                        </div>
                    </form>
                </div >
            </div >

        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Add Interlock</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}

addInterlock.prototypes = {
    postInterlock: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { postInterlock })(withRouter(addInterlock))