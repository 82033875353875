import React, { Component } from 'react'
import WhiteLogo from '../../assets/images/white-logo-1.png'
import { Link, withRouter, NavLink } from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux'
import { unSetAdmin } from '../../store/actions/adminActions'


class sideBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            side: false,
            activePage: 0
        }

        if (window.location.href.indexOf("/dashboard") > -1) {
            this.state.activePage = 1;
        }
    }

    toggle = () => {
        console.log('state', this.state.side)
        this.setState({
            side: !this.state.side
        }, () => { console.log('state', this.state.side) })
    }

    componentDidMount() {
        $(document).ready(function () {
            $(".products").click(function () {
                $(".product-menu").toggle(500);
                $(".product-angle").toggleClass("rotate");
            });
            $(".interlock").click(function () {
                $(".interlock-menu").toggle(500);
                $(".interlock-angle").toggleClass("rotate");
            });
        });

    }


    logout = () => {
        this.props.unSetAdmin(this.props.history)
    }


    render() {
        const { location } = this.props;

        console.log('location', location.pathname)
        return (
            <div className="dashboard-container">
                <div className="dashboard-aside">
                    <div className="brand-aside">
                        <NavLink to="/admin/login">
                            <img src={WhiteLogo} alt="logo" />
                        </NavLink>
                    </div>

                    <div className="aside-menus">
                        <div className="aside-item">
                            <NavLink exact to="/admin/dashboard">
                                <span className="svg-icon menu-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                            <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fillRule="nonzero" className="blue-svg"
                                            ></path>
                                            <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" opacity="0.3" className="light-blue-svg"></path>
                                        </g>
                                    </svg>
                                </span>
                                <span className="menu-text">Dashboard</span>
                            </NavLink>
                        </div>

                        <div className="aside-item">
                            <span className="svg-icon menu-icon">
                                <a className="project-items products">
                                    <span className="svg-icon menu-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"></rect>
                                                <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3"></path>
                                            </g>
                                        </svg>
                                    </span>
                                    <span>Products</span>
                                    <i className="fa fa-angle-right ml-auto product-angle" aria-hidden="true"></i>
                                </a>
                                <div className="product-menu">
                                    <NavLink to="/admin/product-paragraph" className="dropdown-toggle-custom">
                                        <span className="svg-icon menu-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path d="M11.2928932,5.63603897 L18.363961,12.7071068 L12,19.0710678 L4.92893219,12 L11.2928932,5.63603897 Z M8.46446609,11.2928932 C8.0739418,11.6834175 8.0739418,12.3165825 8.46446609,12.7071068 C8.85499039,13.0976311 9.48815536,13.0976311 9.87867966,12.7071068 C10.2692039,12.3165825 10.2692039,11.6834175 9.87867966,11.2928932 C9.48815536,10.9023689 8.85499039,10.9023689 8.46446609,11.2928932 Z M11.2928932,8.46446609 C10.9023689,8.85499039 10.9023689,9.48815536 11.2928932,9.87867966 C11.6834175,10.2692039 12.3165825,10.2692039 12.7071068,9.87867966 C13.0976311,9.48815536 13.0976311,8.85499039 12.7071068,8.46446609 C12.3165825,8.0739418 11.6834175,8.0739418 11.2928932,8.46446609 Z M11.2928932,14.1213203 C10.9023689,14.5118446 10.9023689,15.1450096 11.2928932,15.5355339 C11.6834175,15.9260582 12.3165825,15.9260582 12.7071068,15.5355339 C13.0976311,15.1450096 13.0976311,14.5118446 12.7071068,14.1213203 C12.3165825,13.7307961 11.6834175,13.7307961 11.2928932,14.1213203 Z M14.1213203,11.2928932 C13.7307961,11.6834175 13.7307961,12.3165825 14.1213203,12.7071068 C14.5118446,13.0976311 15.1450096,13.0976311 15.5355339,12.7071068 C15.9260582,12.3165825 15.9260582,11.6834175 15.5355339,11.2928932 C15.1450096,10.9023689 14.5118446,10.9023689 14.1213203,11.2928932 Z" className="blue-svg" opacity="0.3" />
                                                    <path d="M10.5150629,20.4145579 C8.57369375,21.7007639 5.93228695,21.4886361 4.22182541,19.7781746 C2.51136387,18.0677131 2.2992361,15.4263063 3.58544211,13.4849371 L10.5150629,20.4145579 Z" className="blue-svg" />
                                                    <path d="M12.7778303,4.29254889 C14.7191995,3.00634288 17.3606063,3.21847065 19.0710678,4.92893219 C20.7815294,6.63939373 20.9936571,9.28080053 19.7074511,11.2221697 L12.7778303,4.29254889 Z" className="blue-svg" />
                                                </g>
                                            </svg>
                                        </span>
                                        <span>Page Paragraph</span>
                                    </NavLink>

                                    <NavLink to="/admin/interlocks" className="dropdown-toggle-custom">
                                        <span className="svg-icon menu-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path d="M11.2928932,5.63603897 L18.363961,12.7071068 L12,19.0710678 L4.92893219,12 L11.2928932,5.63603897 Z M8.46446609,11.2928932 C8.0739418,11.6834175 8.0739418,12.3165825 8.46446609,12.7071068 C8.85499039,13.0976311 9.48815536,13.0976311 9.87867966,12.7071068 C10.2692039,12.3165825 10.2692039,11.6834175 9.87867966,11.2928932 C9.48815536,10.9023689 8.85499039,10.9023689 8.46446609,11.2928932 Z M11.2928932,8.46446609 C10.9023689,8.85499039 10.9023689,9.48815536 11.2928932,9.87867966 C11.6834175,10.2692039 12.3165825,10.2692039 12.7071068,9.87867966 C13.0976311,9.48815536 13.0976311,8.85499039 12.7071068,8.46446609 C12.3165825,8.0739418 11.6834175,8.0739418 11.2928932,8.46446609 Z M11.2928932,14.1213203 C10.9023689,14.5118446 10.9023689,15.1450096 11.2928932,15.5355339 C11.6834175,15.9260582 12.3165825,15.9260582 12.7071068,15.5355339 C13.0976311,15.1450096 13.0976311,14.5118446 12.7071068,14.1213203 C12.3165825,13.7307961 11.6834175,13.7307961 11.2928932,14.1213203 Z M14.1213203,11.2928932 C13.7307961,11.6834175 13.7307961,12.3165825 14.1213203,12.7071068 C14.5118446,13.0976311 15.1450096,13.0976311 15.5355339,12.7071068 C15.9260582,12.3165825 15.9260582,11.6834175 15.5355339,11.2928932 C15.1450096,10.9023689 14.5118446,10.9023689 14.1213203,11.2928932 Z" className="blue-svg" opacity="0.3" />
                                                    <path d="M10.5150629,20.4145579 C8.57369375,21.7007639 5.93228695,21.4886361 4.22182541,19.7781746 C2.51136387,18.0677131 2.2992361,15.4263063 3.58544211,13.4849371 L10.5150629,20.4145579 Z" className="blue-svg" />
                                                    <path d="M12.7778303,4.29254889 C14.7191995,3.00634288 17.3606063,3.21847065 19.0710678,4.92893219 C20.7815294,6.63939373 20.9936571,9.28080053 19.7074511,11.2221697 L12.7778303,4.29254889 Z" className="blue-svg" />
                                                </g>
                                            </svg>
                                        </span>
                                        <span>Interlock</span>
                                    </NavLink>

                                    <NavLink to="/admin/blocks" className="dropdown-toggle-custom">
                                        <span className="svg-icon menu-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path d="M5.5,4 L9.5,4 C10.3284271,4 11,4.67157288 11,5.5 L11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L5.5,8 C4.67157288,8 4,7.32842712 4,6.5 L4,5.5 C4,4.67157288 4.67157288,4 5.5,4 Z M14.5,16 L18.5,16 C19.3284271,16 20,16.6715729 20,17.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,17.5 C13,16.6715729 13.6715729,16 14.5,16 Z" className="blue-svg" />
                                                    <path d="M5.5,10 L9.5,10 C10.3284271,10 11,10.6715729 11,11.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,12.5 C20,13.3284271 19.3284271,14 18.5,14 L14.5,14 C13.6715729,14 13,13.3284271 13,12.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z" className="blue-svg" opacity="0.3" />
                                                </g>
                                            </svg>
                                        </span>
                                        <span>Block</span>
                                    </NavLink>
                                </div>
                            </span>
                        </div>

                        <div className="aside-item">
                            <span className="svg-icon menu-icon">
                                <a className="project-items interlock">
                                    <span className="svg-icon menu-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"></rect>
                                                <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3"></path>
                                            </g>
                                        </svg>
                                    </span>
                                    <span>Interlock Design</span>
                                    <i className="fa fa-angle-right ml-auto interlock-angle" aria-hidden="true"></i>
                                </a>
                                <div className="interlock-menu">
                                    <NavLink to="/admin/category" className="dropdown-toggle-custom">
                                        <span className="svg-icon menu-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path opacity="0.3" d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z" className="blue-svg" />
                                                    <path className="blue-svg" opacity="1" d="M11,13 L11,11 C11,10.4477153 11.4477153,10 12,10 C12.5522847,10 13,10.4477153 13,11 L13,13 L15,13 C15.5522847,13 16,13.4477153 16,14 C16,14.5522847 15.5522847,15 15,15 L13,15 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,15 L9,15 C8.44771525,15 8,14.5522847 8,14 C8,13.4477153 8.44771525,13 9,13 L11,13 Z" />
                                                </g>
                                            </svg>
                                        </span>
                                        <span>Category</span>
                                    </NavLink>

                                    <NavLink to="/admin/interlock-design" className="dropdown-toggle-custom">
                                        <span className="svg-icon menu-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path d="M12,10.9996338 C12.8356605,10.3719448 13.8743941,10 15,10 C17.7614237,10 20,12.2385763 20,15 C20,17.7614237 17.7614237,20 15,20 C13.8743941,20 12.8356605,19.6280552 12,19.0003662 C11.1643395,19.6280552 10.1256059,20 9,20 C6.23857625,20 4,17.7614237 4,15 C4,12.2385763 6.23857625,10 9,10 C10.1256059,10 11.1643395,10.3719448 12,10.9996338 Z M13.3336047,12.504354 C13.757474,13.2388026 14,14.0910788 14,15 C14,15.9088933 13.7574889,16.761145 13.3336438,17.4955783 C13.8188886,17.8206693 14.3938466,18 15,18 C16.6568542,18 18,16.6568542 18,15 C18,13.3431458 16.6568542,12 15,12 C14.3930587,12 13.8175971,12.18044 13.3336047,12.504354 Z" className="blue-svg" opacity="0.3" fillRule="nonzero" />
                                                    <circle className="blue-svg" cx="12" cy="9" r="5" />
                                                </g>
                                            </svg>
                                        </span>
                                        <span>Interlock Design</span>
                                    </NavLink>
                                </div>
                            </span>
                        </div>

                        <div className="aside-item">
                            <span className="svg-icon menu-icon">
                                <NavLink to="/admin/projects" className="dropdown-toggle-custom">
                                    <span className="svg-icon menu-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <rect x="4" y="4" width="16" height="16" rx="2" className="blue-svg" opacity="0.3" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span>Project</span>
                                </NavLink>
                            </span>
                        </div>


                        <div className="aside-item">
                            <span className="svg-icon menu-icon">
                                <NavLink to="/admin/awards" className="dropdown-toggle-custom">
                                    <span className="svg-icon menu-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M8.08113883,20 L15.9188612,20 C16.5645068,20 17.137715,19.5868549 17.3418861,18.9743416 L19.6721428,11.9835717 C19.8694432,11.3916705 19.6797482,10.7394436 19.1957765,10.3456849 L12.9561839,5.26916104 C12.4053757,4.82102426 11.6158052,4.82050247 11.0644052,5.26791085 L4.80622561,10.345825 C4.32117072,10.7394007 4.13079092,11.3923728 4.32832067,11.984962 L6.65811388,18.9743416 C6.86228495,19.5868549 7.43549322,20 8.08113883,20 Z" className="blue-svg"
                                                />
                                            </g>
                                        </svg>
                                    </span>
                                    <span>Award</span>
                                </NavLink>
                            </span>
                        </div>

                        <div className="aside-item">
                            <span className="svg-icon menu-icon">
                                <NavLink to="/admin/about-page" className="dropdown-toggle-custom">
                                    <span className="svg-icon menu-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <rect className="blue-svg" x="4" y="4" width="7" height="7" rx="1.5"></rect>
                                                <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" className="blue-svg" opacity="0.3"></path>
                                            </g>
                                        </svg>

                                    </span>
                                    <span>About Page</span>
                                </NavLink>
                            </span>
                        </div>

                        <div className="aside-item">
                            <span className="svg-icon menu-icon">
                                <NavLink to="/admin/contact-page" className="dropdown-toggle-custom">
                                    <span className="svg-icon menu-icon">

                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <circle className="blue-svg" opacity="0.3" cx="12" cy="12" r="10" />
                                                <path d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 L7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z" className="blue-svg" />
                                            </g>
                                        </svg>

                                    </span>
                                    <span>Contact Us Page</span>
                                </NavLink>
                            </span>
                        </div>


                        <div className="aside-item">
                            <span className="svg-icon menu-icon">
                                <NavLink to="/admin/career-page" className="dropdown-toggle-custom">
                                    <span className="svg-icon menu-icon">

                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <circle className="blue-svg" opacity="0.3" cx="12" cy="12" r="10" />
                                                <path d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 L7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z" className="blue-svg" />
                                            </g>
                                        </svg>

                                    </span>
                                    <span>Career Page</span>
                                </NavLink>
                            </span>
                        </div>

                        <div className="aside-item">
                            <span className="svg-icon menu-icon">
                                <NavLink to="/admin/home-page" className="dropdown-toggle-custom">
                                    <span className="svg-icon menu-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M3.95709826,8.41510662 L11.47855,3.81866389 C11.7986624,3.62303967 12.2013376,3.62303967 12.52145,3.81866389 L20.0429,8.41510557 C20.6374094,8.77841684 21,9.42493654 21,10.1216692 L21,19.0000642 C21,20.1046337 20.1045695,21.0000642 19,21.0000642 L4.99998155,21.0000673 C3.89541205,21.0000673 2.99998155,20.1046368 2.99998155,19.0000673 L2.99999828,10.1216672 C2.99999935,9.42493561 3.36258984,8.77841732 3.95709826,8.41510662 Z M10,13 C9.44771525,13 9,13.4477153 9,14 L9,17 C9,17.5522847 9.44771525,18 10,18 L14,18 C14.5522847,18 15,17.5522847 15,17 L15,14 C15,13.4477153 14.5522847,13 14,13 L10,13 Z" className="blue-svg" />
                                            </g>
                                        </svg>

                                    </span>
                                    <span>Home Page</span>
                                </NavLink>
                            </span>
                        </div>

                        <div className="aside-item">
                            <span className="svg-icon menu-icon">
                                <NavLink to="/admin/contact-details" className="dropdown-toggle-custom">
                                    <span className="svg-icon menu-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" className="blue-svg" opacity="0.3" />
                                                <path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" className="blue-svg" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span>Messages Response</span>
                                </NavLink>
                            </span>
                        </div>

                        <div className="aside-item">
                            <span className="svg-icon menu-icon">
                                <NavLink to="/admin/career-details" className="dropdown-toggle-custom">
                                    <span className="svg-icon menu-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" className="blue-svg" opacity="0.3" />
                                                <path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" className="blue-svg" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span>Career Response</span>
                                </NavLink>
                            </span>
                        </div>

                        <div className="aside-item">
                            <span className="svg-icon menu-icon">
                                <NavLink to="/admin/" className="dropdown-toggle-custom" onClick={this.logout}>
                                    <span className="svg-icon menu-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" className="blue-svg" opacity="0.3" />
                                                <path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" className="blue-svg" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span>Logout</span>
                                </NavLink>
                            </span>
                        </div>



                    </div>
                </div>


                <div className="dashboard-content">
                    <div className={this.state.side ? 'page-overlay show' : 'page-overlay'}></div>
                    <div className="dashboard-mob-nav">
                        <div className="dashboard-mob-content">
                            <NavLink to="/admin/login">
                                <img src={WhiteLogo} alt="logo" />
                            </NavLink>
                            <div className="burger-part">
                                <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">
                                    <span onClick={this.toggle}></span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className={this.state.side ? `mob-sidebar custom-left` : 'mob-sidebar'}>
                        <div className="logo">
                            <NavLink to="/admin/login">
                                <img src={WhiteLogo} alt="logo" />
                            </NavLink>

                        </div>
                        <ul>
                            <li>
                                <div className="aside-item active">
                                    <NavLink to="/admin/dashboard">
                                        <span className="svg-icon menu-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                    <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fillRule="nonzero" className="blue-svg"
                                                    ></path>
                                                    <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" opacity="0.3" className="light-blue-svg"></path>
                                                </g>
                                            </svg>
                                        </span>
                                        <span className="menu-text">Dashboard</span>
                                    </NavLink>
                                </div>
                            </li>

                            <li>
                                <div className="aside-item">
                                    <span className="svg-icon menu-icon">
                                        <a className="project-items">
                                            <span className="svg-icon menu-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                        <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"></rect>
                                                        <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3"></path>
                                                    </g>
                                                </svg>
                                            </span>
                                            <span>Products</span>
                                            <i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                                        </a>
                                        <div className="dropdown-menu1">
                                            <li>
                                                <span className="svg-icon menu-icon">
                                                    <NavLink to="/admin/product-paragraph" className="dropdown-toggle-custom">
                                                        <span className="svg-icon menu-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <path d="M11.2928932,5.63603897 L18.363961,12.7071068 L12,19.0710678 L4.92893219,12 L11.2928932,5.63603897 Z M8.46446609,11.2928932 C8.0739418,11.6834175 8.0739418,12.3165825 8.46446609,12.7071068 C8.85499039,13.0976311 9.48815536,13.0976311 9.87867966,12.7071068 C10.2692039,12.3165825 10.2692039,11.6834175 9.87867966,11.2928932 C9.48815536,10.9023689 8.85499039,10.9023689 8.46446609,11.2928932 Z M11.2928932,8.46446609 C10.9023689,8.85499039 10.9023689,9.48815536 11.2928932,9.87867966 C11.6834175,10.2692039 12.3165825,10.2692039 12.7071068,9.87867966 C13.0976311,9.48815536 13.0976311,8.85499039 12.7071068,8.46446609 C12.3165825,8.0739418 11.6834175,8.0739418 11.2928932,8.46446609 Z M11.2928932,14.1213203 C10.9023689,14.5118446 10.9023689,15.1450096 11.2928932,15.5355339 C11.6834175,15.9260582 12.3165825,15.9260582 12.7071068,15.5355339 C13.0976311,15.1450096 13.0976311,14.5118446 12.7071068,14.1213203 C12.3165825,13.7307961 11.6834175,13.7307961 11.2928932,14.1213203 Z M14.1213203,11.2928932 C13.7307961,11.6834175 13.7307961,12.3165825 14.1213203,12.7071068 C14.5118446,13.0976311 15.1450096,13.0976311 15.5355339,12.7071068 C15.9260582,12.3165825 15.9260582,11.6834175 15.5355339,11.2928932 C15.1450096,10.9023689 14.5118446,10.9023689 14.1213203,11.2928932 Z" className="blue-svg" opacity="0.3" />
                                                                    <path d="M10.5150629,20.4145579 C8.57369375,21.7007639 5.93228695,21.4886361 4.22182541,19.7781746 C2.51136387,18.0677131 2.2992361,15.4263063 3.58544211,13.4849371 L10.5150629,20.4145579 Z" className="blue-svg" />
                                                                    <path d="M12.7778303,4.29254889 C14.7191995,3.00634288 17.3606063,3.21847065 19.0710678,4.92893219 C20.7815294,6.63939373 20.9936571,9.28080053 19.7074511,11.2221697 L12.7778303,4.29254889 Z" className="blue-svg" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span>Page Paragraph</span>
                                                    </NavLink>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="svg-icon menu-icon">
                                                    <NavLink to="/admin/interlocks" className="dropdown-toggle-custom">
                                                        <span className="svg-icon menu-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <path d="M11.2928932,5.63603897 L18.363961,12.7071068 L12,19.0710678 L4.92893219,12 L11.2928932,5.63603897 Z M8.46446609,11.2928932 C8.0739418,11.6834175 8.0739418,12.3165825 8.46446609,12.7071068 C8.85499039,13.0976311 9.48815536,13.0976311 9.87867966,12.7071068 C10.2692039,12.3165825 10.2692039,11.6834175 9.87867966,11.2928932 C9.48815536,10.9023689 8.85499039,10.9023689 8.46446609,11.2928932 Z M11.2928932,8.46446609 C10.9023689,8.85499039 10.9023689,9.48815536 11.2928932,9.87867966 C11.6834175,10.2692039 12.3165825,10.2692039 12.7071068,9.87867966 C13.0976311,9.48815536 13.0976311,8.85499039 12.7071068,8.46446609 C12.3165825,8.0739418 11.6834175,8.0739418 11.2928932,8.46446609 Z M11.2928932,14.1213203 C10.9023689,14.5118446 10.9023689,15.1450096 11.2928932,15.5355339 C11.6834175,15.9260582 12.3165825,15.9260582 12.7071068,15.5355339 C13.0976311,15.1450096 13.0976311,14.5118446 12.7071068,14.1213203 C12.3165825,13.7307961 11.6834175,13.7307961 11.2928932,14.1213203 Z M14.1213203,11.2928932 C13.7307961,11.6834175 13.7307961,12.3165825 14.1213203,12.7071068 C14.5118446,13.0976311 15.1450096,13.0976311 15.5355339,12.7071068 C15.9260582,12.3165825 15.9260582,11.6834175 15.5355339,11.2928932 C15.1450096,10.9023689 14.5118446,10.9023689 14.1213203,11.2928932 Z" className="blue-svg" opacity="0.3" />
                                                                    <path d="M10.5150629,20.4145579 C8.57369375,21.7007639 5.93228695,21.4886361 4.22182541,19.7781746 C2.51136387,18.0677131 2.2992361,15.4263063 3.58544211,13.4849371 L10.5150629,20.4145579 Z" className="blue-svg" />
                                                                    <path d="M12.7778303,4.29254889 C14.7191995,3.00634288 17.3606063,3.21847065 19.0710678,4.92893219 C20.7815294,6.63939373 20.9936571,9.28080053 19.7074511,11.2221697 L12.7778303,4.29254889 Z" className="blue-svg" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span>Interlock</span>
                                                    </NavLink>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="svg-icon menu-icon">
                                                    <NavLink to="/admin/blocks" className="dropdown-toggle-custom">
                                                        <span className="svg-icon menu-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <path d="M5.5,4 L9.5,4 C10.3284271,4 11,4.67157288 11,5.5 L11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L5.5,8 C4.67157288,8 4,7.32842712 4,6.5 L4,5.5 C4,4.67157288 4.67157288,4 5.5,4 Z M14.5,16 L18.5,16 C19.3284271,16 20,16.6715729 20,17.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,17.5 C13,16.6715729 13.6715729,16 14.5,16 Z" className="blue-svg" />
                                                                    <path d="M5.5,10 L9.5,10 C10.3284271,10 11,10.6715729 11,11.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,12.5 C20,13.3284271 19.3284271,14 18.5,14 L14.5,14 C13.6715729,14 13,13.3284271 13,12.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z" className="blue-svg" opacity="0.3" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span>Block</span>
                                                    </NavLink>
                                                </span>
                                            </li>
                                        </div>
                                    </span>
                                </div>
                            </li>

                            <li>
                                <div className="aside-item">
                                    <span className="svg-icon menu-icon">
                                        <NavLink to="/admin/category" className="dropdown-toggle-custom">
                                            <span className="svg-icon menu-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path opacity="0.3" d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z" className="blue-svg" />
                                                        <path className="blue-svg" opacity="1" d="M11,13 L11,11 C11,10.4477153 11.4477153,10 12,10 C12.5522847,10 13,10.4477153 13,11 L13,13 L15,13 C15.5522847,13 16,13.4477153 16,14 C16,14.5522847 15.5522847,15 15,15 L13,15 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,15 L9,15 C8.44771525,15 8,14.5522847 8,14 C8,13.4477153 8.44771525,13 9,13 L11,13 Z" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span>Category</span>
                                        </NavLink>
                                    </span>
                                </div>
                            </li>

                            <li>
                                <div className="aside-item">
                                    <span className="svg-icon menu-icon">
                                        <NavLink to="/admin/interlock-design" className="dropdown-toggle-custom">
                                            <span className="svg-icon menu-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M12,10.9996338 C12.8356605,10.3719448 13.8743941,10 15,10 C17.7614237,10 20,12.2385763 20,15 C20,17.7614237 17.7614237,20 15,20 C13.8743941,20 12.8356605,19.6280552 12,19.0003662 C11.1643395,19.6280552 10.1256059,20 9,20 C6.23857625,20 4,17.7614237 4,15 C4,12.2385763 6.23857625,10 9,10 C10.1256059,10 11.1643395,10.3719448 12,10.9996338 Z M13.3336047,12.504354 C13.757474,13.2388026 14,14.0910788 14,15 C14,15.9088933 13.7574889,16.761145 13.3336438,17.4955783 C13.8188886,17.8206693 14.3938466,18 15,18 C16.6568542,18 18,16.6568542 18,15 C18,13.3431458 16.6568542,12 15,12 C14.3930587,12 13.8175971,12.18044 13.3336047,12.504354 Z" className="blue-svg" opacity="0.3" fillRule="nonzero" />
                                                        <circle className="blue-svg" cx="12" cy="9" r="5" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span>Interlock Design</span>
                                        </NavLink>
                                    </span>
                                </div>
                            </li>

                            <li>
                                <div className="aside-item">
                                    <span className="svg-icon menu-icon">
                                        <NavLink to="/admin/projects" className="dropdown-toggle-custom">
                                            <span className="svg-icon menu-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <rect x="4" y="4" width="16" height="16" rx="2" className="blue-svg" opacity="0.3" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span>Project</span>
                                        </NavLink>
                                    </span>
                                </div>
                            </li>

                            <li>
                                <div className="aside-item">
                                    <span className="svg-icon menu-icon">
                                        <NavLink to="/admin/awards" className="dropdown-toggle-custom">
                                            <span className="svg-icon menu-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M8.08113883,20 L15.9188612,20 C16.5645068,20 17.137715,19.5868549 17.3418861,18.9743416 L19.6721428,11.9835717 C19.8694432,11.3916705 19.6797482,10.7394436 19.1957765,10.3456849 L12.9561839,5.26916104 C12.4053757,4.82102426 11.6158052,4.82050247 11.0644052,5.26791085 L4.80622561,10.345825 C4.32117072,10.7394007 4.13079092,11.3923728 4.32832067,11.984962 L6.65811388,18.9743416 C6.86228495,19.5868549 7.43549322,20 8.08113883,20 Z" className="blue-svg"
                                                        />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span>Award</span>
                                        </NavLink>
                                    </span>
                                </div>
                            </li>

                            <li>
                                <div className="aside-item">
                                    <span className="svg-icon menu-icon">
                                        <NavLink to="/admin/about-page" className="dropdown-toggle-custom">
                                            <span className="svg-icon menu-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                        <rect className="blue-svg" x="4" y="4" width="7" height="7" rx="1.5"></rect>
                                                        <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" className="blue-svg" opacity="0.3"></path>
                                                    </g>
                                                </svg>
                                            </span>
                                            <span>About Page</span>
                                        </NavLink>
                                    </span>
                                </div>
                            </li>

                            <li>
                                <div className="aside-item">
                                    <span className="svg-icon menu-icon">
                                        <NavLink to="/admin/contact-page" className="dropdown-toggle-custom">
                                            <span className="svg-icon menu-icon">
                                                {/* <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-02-01-052524/theme/html/demo1/dist/../src/media/svg/icons/Communication/Contact1.svg--> */}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <circle className="blue-svg" opacity="0.3" cx="12" cy="12" r="10" />
                                                        <path d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 L7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z" className="blue-svg" />
                                                    </g>
                                                </svg>
                                                {/* <!--end::Svg Icon--> */}
                                            </span>
                                            <span>Contact Us Page</span>
                                        </NavLink>
                                    </span>
                                </div>
                            </li>

                            <li>
                                <div className="aside-item">
                                    <span className="svg-icon menu-icon">
                                        <NavLink to="/admin/career-page" className="dropdown-toggle-custom">
                                            <span className="svg-icon menu-icon">
                                                {/* <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-02-01-052524/theme/html/demo1/dist/../src/media/svg/icons/Communication/Contact1.svg--> */}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <circle className="blue-svg" opacity="0.3" cx="12" cy="12" r="10" />
                                                        <path d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 L7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z" className="blue-svg" />
                                                    </g>
                                                </svg>
                                                {/* <!--end::Svg Icon--> */}
                                            </span>
                                            <span>Career Page</span>
                                        </NavLink>
                                    </span>
                                </div>
                            </li>

                            <li>
                                <div className="aside-item">
                                    <span className="svg-icon menu-icon">
                                        <NavLink to="/admin/home-page" className="dropdown-toggle-custom">
                                            <span className="svg-icon menu-icon">
                                                {/* <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-02-01-052524/theme/html/demo1/dist/../src/media/svg/icons/Home/Home.svg--> */}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M3.95709826,8.41510662 L11.47855,3.81866389 C11.7986624,3.62303967 12.2013376,3.62303967 12.52145,3.81866389 L20.0429,8.41510557 C20.6374094,8.77841684 21,9.42493654 21,10.1216692 L21,19.0000642 C21,20.1046337 20.1045695,21.0000642 19,21.0000642 L4.99998155,21.0000673 C3.89541205,21.0000673 2.99998155,20.1046368 2.99998155,19.0000673 L2.99999828,10.1216672 C2.99999935,9.42493561 3.36258984,8.77841732 3.95709826,8.41510662 Z M10,13 C9.44771525,13 9,13.4477153 9,14 L9,17 C9,17.5522847 9.44771525,18 10,18 L14,18 C14.5522847,18 15,17.5522847 15,17 L15,14 C15,13.4477153 14.5522847,13 14,13 L10,13 Z" className="blue-svg" />
                                                    </g>
                                                </svg>
                                                {/* <!--end::Svg Icon--> */}
                                            </span>
                                            <span>Home Page</span>
                                        </NavLink>
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div className="aside-item">
                                    <span className="svg-icon menu-icon">
                                        <NavLink to="/admin/contact-details" className="dropdown-toggle-custom">
                                            <span className="svg-icon menu-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" className="blue-svg" opacity="0.3" />
                                                        <path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" className="blue-svg" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span>Messages Response</span>
                                        </NavLink>
                                    </span>
                                </div>
                            </li>
                        </ul >
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, { unSetAdmin })(withRouter(sideBar))