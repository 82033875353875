import React, { Component } from 'react'
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
import ContactUs from './contactus/index'
import Awards from './award/index'
import Home from './main/index'
import About from './about/index'
import Project from './project/index'
import Product from './products/index'
import Block from './products/block/index'
import InterlockDesign from './interlockDesign/index'
import Interlock from './products/interlock/index'
import Career from './career/index'
class Routes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            match: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ match: nextProps.match });
    }

    changematch = () => {
        this.setState({ match: '' });
    }

    render() {
        const { match } = this.state
        return (
            <Router>
                <Route
                    exact
                    path="/"
                    component={
                        () => <Home />
                    }
                />

                <Route
                    exact
                    path="/about"
                    component={
                        () => <About />
                    }
                />

                <Route
                    exact
                    path="/interlock-design"
                    component={
                        () => <InterlockDesign />
                    }
                />



                <Route
                    exact
                    path="/products/interlock/:id"
                    component={
                        () => <Interlock
                            hist={match}
                            changematch={this.changematch}
                        />
                    }
                />



                <Route
                    exact
                    path="/join-us"
                    component={
                        () => <Career />
                    }
                />

                <Route
                    exact
                    path="/products/block/:id"
                    component={
                        () => <Block
                            hist={match}
                            changematch={this.changematch}
                        />
                    }
                />

                <Route
                    exact
                    path="/products"
                    component={
                        () => <Product />
                    }
                />



                <Route
                    exact
                    path="/projects"
                    component={
                        () => <Project />
                    }
                />

                <Route
                    exact
                    path="/contact-us"
                    component={
                        () => <ContactUs />
                    }
                />

                <Route
                    exact
                    path="/awards"
                    component={
                        () => <Awards />
                    }
                />
            </Router>
        )
    }
}

export default withRouter(Routes)