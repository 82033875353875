import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getHomePageContent } from '../../../store/actions/publicActions'
import { updateHomePageSetting } from '../../../store/actions/adminActions'
import PropTypes from 'prop-types'
import Spinner from '../../common/Spinner';
import { NotificationManager } from 'react-notifications';

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            bannerHeading: "",
            bannerParagraph: "",
            bannerButton: "",
            aboutHeading: "",
            aboutParagraph: "",
            productParagraph: "",
            blockButton: "",
            interlockButton: "",
            projectButton: "",
            contactHeading: "",
            contactButton: "",
            homePage: ""
        }
    }
    componentDidMount() {
        this.props.getHomePageContent()
    }

    static getDerivedStateFromProps(props, state) {
        const { loading, homePage } = props.admin;

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        if (JSON.stringify(homePage) !== JSON.stringify(state.homePage)) {
            state.homePage = homePage;
            state.id = homePage.id;
            state.bannerHeading = homePage.bannerHeading;
            state.bannerParagraph = homePage.bannerParagraph;
            state.bannerButton = homePage.bannerButton;
            state.aboutHeading = homePage.aboutHeading;
            state.aboutParagraph = homePage.aboutParagraph;
            state.productParagraph = homePage.productParagraph;
            state.blockButton = homePage.blockButton;
            state.interlockButton = homePage.interlockButton;
            state.projectButton = homePage.projectButton;
            state.contactHeading = homePage.contactHeading;
            state.contactButton = homePage.contactButton;
        }

        return state
    }

    handleValidation() {
        const fields = this.state;
        let formIsValid = true;

        if (!fields["bannerHeading"]) {
            formIsValid = false;
            NotificationManager.error('Banner heading field is required')
        }

        if (!fields["bannerParagraph"]) {
            formIsValid = false;
            NotificationManager.error('Banner paragraph field is required')
        }


        if (!fields["bannerButton"]) {
            formIsValid = false;
            NotificationManager.error('Banner button field is required')
        }

        if (!fields["aboutHeading"]) {
            formIsValid = false;
            NotificationManager.error('About heading field is required')
        }

        if (!fields["aboutParagraph"]) {
            formIsValid = false;
            NotificationManager.error('About paragraph field is required')
        }

        if (!fields["productParagraph"]) {
            formIsValid = false;
            NotificationManager.error('Product paragraph field is required')
        }

        if (!fields["blockButton"]) {
            formIsValid = false;
            NotificationManager.error('Block button field is required')
        }

        if (!fields["interlockButton"]) {
            formIsValid = false;
            NotificationManager.error('Interlock button field is required')
        }

        if (!fields["projectButton"]) {
            formIsValid = false;
            NotificationManager.error('Project button field is required')
        }

        if (!fields["contactHeading"]) {
            formIsValid = false;
            NotificationManager.error('Contact heading field is required')
        }

        if (!fields["contactButton"]) {
            formIsValid = false;
            NotificationManager.error('contact button field is required')
        }


        return formIsValid;
    }


    onSubmitHandler = (e) => {
        e.preventDefault()

        if (!this.handleValidation()) {
            return
        }

        const data = {
            id: this.state.id,
            bannerHeading: this.state.bannerHeading,
            bannerParagraph: this.state.bannerParagraph,
            bannerButton: this.state.bannerButton,
            aboutHeading: this.state.aboutHeading,
            aboutParagraph: this.state.aboutParagraph,
            productParagraph: this.state.productParagraph,
            blockButton: this.state.blockButton,
            interlockButton: this.state.interlockButton,
            projectButton: this.state.projectButton,
            contactHeading: this.state.contactHeading,
            contactButton: this.state.contactButton
        }
        this.props.updateHomePageSetting(data, this.props.history)
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const { loading, bannerHeading, contactButton, contactHeading, projectButton, interlockButton, bannerParagraph, bannerButton, aboutHeading, aboutParagraph, productParagraph, blockButton } = this.state

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="card contact-card">
                    <form onSubmit={this.onSubmitHandler}>
                        <div className="card-body">
                            <div className="form-group small-section" style={{ display: 'none' }}>
                                <label>Main Banner Heading:</label>
                                <input type="text"
                                    name="bannerHeading"
                                    className="form-control"
                                    value={bannerHeading}
                                    placeholder="Enter main banner heading"
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className="form-group small-section right-part" style={{ display: 'none' }}>
                                <label>Main Banner Button:</label>
                                <input type="text" name="bannerButton"
                                    className="form-control"
                                    placeholder="Enter banner button"
                                    value={bannerButton}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className="form-group small-section">
                                <label>About Heading:</label>
                                <input type="text"
                                    name="aboutHeading"
                                    className="form-control"
                                    placeholder="Enter sub-heading"
                                    value={aboutHeading}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className="form-group small-section right-part">
                                <label>Our Block Button:</label>
                                <input type="text"
                                    name="blockButton"
                                    className="form-control"
                                    placeholder="Enter block button"
                                    value={blockButton}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className="form-group small-section">
                                <label>See Interlocks Button:</label>
                                <input type="text"
                                    name="interlockButton"
                                    value={interlockButton}
                                    className="form-control"
                                    placeholder="Enter interlock button"
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className="form-group small-section right-part">
                                <label>Enter Project Button:</label>
                                <input type="text"
                                    value={projectButton}
                                    name="projectButton"
                                    className="form-control"
                                    placeholder="Enter project button"
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className="form-group small-section">
                                <label>Contact Heading:</label>
                                <input type="text"
                                    name="contactHeading"
                                    className="form-control"
                                    value={contactHeading}
                                    placeholder="Enter Contact Heading"
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className="form-group small-section right-part">
                                <label>Contact Us Button:</label>
                                <input
                                    type="text"
                                    name="contactButton"
                                    className="form-control"
                                    placeholder="Enter contact us button"
                                    value={contactButton}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className="form-group" style={{ display: 'none' }}>
                                <label>Main Banner Paragraph:</label>
                                <textarea name="bannerParagraph"
                                    value={bannerParagraph}
                                    className="form-control"
                                    placeholder="Enter main banner paragraph"
                                    onChange={this.onChangeHandler}
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label>About Paragraph:</label>
                                <textarea
                                    name="aboutParagraph"
                                    value={aboutParagraph}
                                    className="form-control"
                                    placeholder="Enter About paragraph"
                                    onChange={this.onChangeHandler}
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label>Our Product Graph:</label>
                                <textarea
                                    name="productParagraph"
                                    value={productParagraph}
                                    className="form-control"
                                    onChange={this.onChangeHandler}
                                    placeholder="Enter product paragraph"></textarea>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn submit-btn mr-2">Submit</button>
                            <button type="reset" className="btn cancel-btn">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Home Page</h5>
                            </div>
                        </div>
                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}

HomePage.propTypes = {
    getHomePageContent: PropTypes.func.isRequired,
    updateHomePageSetting: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    admin: state.admin
});


export default connect(mapStateToProps, { getHomePageContent, updateHomePageSetting })(withRouter(HomePage))