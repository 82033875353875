import React, { Component } from 'react'
import { getAllProject } from '../../../store/actions/publicActions'
import { deleteProject } from '../../../store/actions/adminActions'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Spinner from '../../common/Spinner';

class Project extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            projects: []
        }
    }

    componentDidMount() {
        this.props.getAllProject()
    }

    static getDerivedStateFromProps(props, state) {
        const { loading, projects } = props.admin;

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        if (JSON.stringify(projects) !== JSON.stringify(state.projects)) {
            state.projects = projects
        }

        return state
    }

    EditHandler = (id) => {
        this.props.history.push("/admin/projects/edit/" + id)
    }

    render() {
        const { loading, projects } = this.state;

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="add-btn-container">
                    <Link to="/admin/projects/add">
                        <button type="button">Add Projects</button>
                    </Link>
                </div>
                <div className="card contact-card message-response all-projects">
                    <div className="card-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" className="name">Name</th>
                                    <th scope="col" className="image">Image</th>
                                    <th scope="col" className="location">Location</th>
                                    <th scope="col" className="material">Material</th>
                                    <th scope="col" className="action-col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {projects.map((item, key) => {
                                    return <tr key={key}>
                                        <td>{item.name}</td>
                                        <td className="image-td">
                                            <img alt="#" src={item.image} className="green-img" />
                                        </td>
                                        <td>{item.location}</td>
                                        <td>{item.material}</td>
                                        <td className="action-btn">
                                            <button type="button" onClick={() => this.EditHandler(item.id)} className="form-control">Edit</button>
                                            <button type="button" onClick={() => { if (window.confirm('Delete the Project?')) { this.props.deleteProject(item.id) } }} className="form-control"> Delete</button>
                                        </td>
                                    </tr>
                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Projects</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}

Project.propTypes = {
    getAllProject: PropTypes.func.isRequired,
    deleteProject: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { getAllProject, deleteProject })(withRouter(Project))
