import React, { Component } from 'react'
import { getAllInterlocks } from '../../../store/actions/publicActions'
import { deleteInterlock } from '../../../store/actions/adminActions'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import Spinner from '../../common/Spinner'

class Interlocks extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            interlocks: []
        }
    }

    componentDidMount() {
        this.props.getAllInterlocks()
    }


    static getDerivedStateFromProps(props, state) {
        const { loading, interlocks } = props.admin

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        if (JSON.stringify(interlocks) !== JSON.stringify(state.interlocks)) {
            state.interlocks = interlocks
        }
        return state
    }

    editHandler = (id) => {
        this.props.history.push("/admin/interlocks/edit/" + id)
    }

    render() {
        const { loading, interlocks } = this.state;

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="add-btn-container">
                    <Link to="/admin/interlocks/add">
                        <button type="button">Add Interlock</button>
                    </Link>
                </div>
                <div className="card contact-card message-response all-interlock-name">
                    <div className="card-body">
                        {interlocks && interlocks.length ?
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="name">Heading</th>
                                        <th scope="col" className="dimension" >Dimension</th>
                                        <th scope="col" className="thickness" >Thickness</th>
                                        <th scope="col" className="weight" >Weight</th>
                                        <th scope="col" className="color">Color</th>
                                        <th scope="col" className="action-col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {interlocks.map((item, key) => {
                                        return <tr key={key}>
                                            <td>{item.name}</td>
                                            <td className="dp-col">
                                                <span>
                                                    <img alt="#" src={item.dimensions} className="green-img-interlock-table" />
                                                </span>
                                            </td>
                                            <td>Weight: {item.weight}</td>
                                            <td>Thickness: {item.thickness} </td>
                                            <td className="color-content" >
                                                {item.variety.map((value, key) => (
                                                    <span key={key} style={{ backgroundColor: value.color }}></span>
                                                ))}
                                            </td>

                                            <td className="action-btn">
                                                <button type="button" onClick={() => { this.editHandler(item.id) }} className="form-control">Edit</button>
                                                <button type="button" onClick={() => { if (window.confirm('Delete the Interlock?')) { this.props.deleteInterlock(item.id) } }} className="form-control"> Delete</button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table> : <h2>No Interlock Available</h2>}

                    </div>
                </div>
            </div>
        }

        return (
            <div className="dashboard-container">
                <div className="dashboard-content">
                    <div className="content-header">
                        <div className="top-content">
                            <h5>Interlocks</h5>
                        </div>
                    </div>

                    {pageContent}

                </div>
            </div>
        )
    }
}


Interlocks.propTypes = {
    getAllInterlocks: PropTypes.func.isRequired,
    deleteInterlock: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { getAllInterlocks, deleteInterlock })(withRouter(Interlocks))