// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.upload-btn-wrapper .btn {
    color: #3699ff;
    background-color: #e1f0ff;
    border-color: transparent;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
}

.upload-btn-wrapper:hover .btn {
    color: #e1f0ff;
    background-color: #3699ff;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/admin/award.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,UAAU;IACV,eAAe;AACnB","sourcesContent":[".upload-btn-wrapper {\n    position: relative;\n    overflow: hidden;\n    display: inline-block;\n    cursor: pointer;\n}\n\n.upload-btn-wrapper .btn {\n    color: #3699ff;\n    background-color: #e1f0ff;\n    border-color: transparent;\n    padding: 8px 20px;\n    border-radius: 8px;\n    font-size: 12px;\n    font-weight: 500;\n}\n\n.upload-btn-wrapper:hover .btn {\n    color: #e1f0ff;\n    background-color: #3699ff;\n}\n\n.upload-btn-wrapper input[type=file] {\n    font-size: 100px;\n    position: absolute;\n    left: 0;\n    top: 0;\n    opacity: 0;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
