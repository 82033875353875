import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import '../src/assets/css/admin/bootstrap.min.css'

import '../src/assets/css/user/common.css'
import '../src/assets/css/admin/common.css'
import '../src/assets/css/user/page-1.css'
import '../src/assets/css/user/page-2.css'
import '../src/assets/css/user/page-3.css'
import '../src/assets/css/user/page-4.css'
import '../src/assets/css/user/page-5.css'
import '../src/assets/css/user/page-6.css'
import '../src/assets/css/user/page-7.css'
import '../src/assets/css/user/page-8.css'
import '../src/assets/css/user/page-10.css'

import '../src/assets/css/admin/award.css'
import '../src/assets/css/admin/login.css'
import '../src/assets/css/admin/message-response.css'
import '../src/assets/css/admin/dashboard.css'
import '../src/assets/css/admin/contact-us.css'
import 'react-notifications/lib/notifications.css';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'


// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.render(
      <App />,
    document.getElementById('root')
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
