import React, { Component } from 'react'
import WhiteLogo from '../../../assets/images/white-logo-1.png'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { AdminLogin } from '../../../store/actions/adminActions'
import PropTypes from 'prop-types'
class LoginAdmin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            emailError: false,
            passwordError: false,
        }
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/admin/dashboard')
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    submitHandler = (e) => {
        e.preventDefault()
        const { email, password } = this.state
        if(!email || (email && !this.validateEmail(email))) {
            this.setState({ emailError: true })
            password.length < 4 ? this.setState({ passwordError: true }) : this.setState({ passwordError: false })
            return
        }else{ 
            this.setState({ emailError: false })
        }
        if(password.length < 4) {
            this.setState({ passwordError: true })
            return
        }else{ 
            this.setState({ passwordError: false })
        }

        const data = {
            email: this.state.email,
            password: this.state.password
        }
        this.props.AdminLogin(data, this.props.history)
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    render() {
        const { email, password, emailError, passwordError } = this.state
        return (
            <div className="login-container">
                <div className="login-aside">
                    <div className="logo-content">
                        <Link to="/admin">
                            <img src={WhiteLogo} alt="logo" />
                        </Link>
                    </div>

                    <h3 className="tag-line">Amazing construction with best material</h3>

                    <div className="aside-bottom">

                    </div>
                </div>

                <div className="login-content d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
                    <div className="login-form">
                        <form onSubmit={this.submitHandler}>
                            <div className="login-form-text">
                                <h3>Welcome to Dubai Blocks</h3>
                            </div>

                            <div className="form-group form-field">
                                <label>Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    value={email}
                                    onChange={this.onChangeHandler}
                                    onBlur={() => email && this.validateEmail(email) ? this.setState({ emailError: false }) : this.setState({ emailError: true })}
                                />
                                {!email && emailError ? <span className="error-helper-text">
                                    Enter valid email address
                                </span> : email && !this.validateEmail(email) ? <span className="error-helper-text">
                                    Invalid Email
                                </span> : null}
                            </div>

                            <div className="form-group form-field">
                                <input
                                    type="Password"
                                    className="form-control"
                                    name="password"
                                    value={password}
                                    onChange={this.onChangeHandler}
                                    onBlur={() => password.length < 4 ? this.setState({ passwordError: false }) : this.setState({ passwordError: true })}
                                />
                                {passwordError && <span className="error-helper-text">
                                    Enter valid password
                                </span>}
                            </div>

                            <div className="login-button">
                                <button type="submit" className="sign-in">Sign In</button>
                            </div>

                        </form>
                    </div>
                    <div className="login-footer">
                        <div className="signature">
                            <span className="copywright">2021&copy;</span>
                            <Link to="#">Dubai Blocks</Link>
                        </div>
                        {/* <div className="footer-links">
                            <Link to="#" className="ml-0">Terms</Link>
                            <Link to="#">Plans</Link>
                            <Link to="#">Contact Us</Link>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

LoginAdmin.propTypes = {
    AdminLogin: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});


export default connect(mapStateToProps, { AdminLogin })(withRouter(LoginAdmin))
