import './App.css';
import Login from './components/admin/login/index'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import Admin from './components/admin'
import { Provider } from 'react-redux'
import store from './store'
import { setAdmin } from './store/actions/adminActions'
import ProtectRoute from './utils/protectedRoute'
import Frontend from './components/public/index'
import { NotificationContainer } from 'react-notifications';

function App() {
  if (localStorage.token) {
    store.dispatch(setAdmin())
  }

  return (
    <Provider store={store}>
      <Router history={createBrowserHistory}>
        <Route exact
          path="/admin/login"
          component={Login}
        />
        <Route exact
          path="/"
          component={Frontend}
        />

        <Route exact
          path="/about"
          component={Frontend}
        />

        <Route exact
          path="/projects"
          component={Frontend}
        />

        <Route exact
          path="/interlock-design"
          component={Frontend}
        />

        <Route exact
          path="/products"
          component={Frontend}
        />

        <Route exact
          path="/products/block/:id"
          component={Frontend}
        />

        <Route exact
          path="/products/interlock/:id"
          component={Frontend}
        />

        <Route exact
          path="/join-us"
          component={Frontend}
        />

        <Route exact
          path="/contact-us"
          component={Frontend}
        />
        <Route exact
          path="/awards"
          component={Frontend}
        />
        <Route exact
          path="/admin"
          component={Login}
        />
        <ProtectRoute exact
          path="/admin/dashboard"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/blocks"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/product-paragraph"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/interlock-design"
          component={Admin}
        />
        <ProtectRoute exact
          path="/admin/interlock-design/add"
          component={Admin}
        />
        <ProtectRoute exact
          path="/admin/interlock-design/edit/:id"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/blocks/add"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/blocks/edit/:id"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/interlocks"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/interlocks/add"
          component={Admin}
        />
        <ProtectRoute exact
          path="/admin/interlocks/edit/:id"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/projects"
          component={Admin}
        />
        <ProtectRoute exact
          path="/admin/projects/add"
          component={Admin}
        />
        <ProtectRoute exact
          path="/admin/projects/edit/:id"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/category"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/category/add"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/category/edit/:id"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/awards"
          component={Admin}
        />
        <ProtectRoute exact
          path="/admin/awards/add"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/awards/edit/:id"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/about-page"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/home-page"
          component={Admin}
        />
        <ProtectRoute exact
          path="/admin/contact-page"
          component={Admin}
        />
        <ProtectRoute exact
          path="/admin/career-page"
          component={Admin}
        />
        <ProtectRoute exact
          path="/admin/admin-setting"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/contact-details"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/view-contact-details/:id"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/career-details"
          component={Admin}
        />

        <ProtectRoute exact
          path="/admin/view-career-details/:id"
          component={Admin}
        />

      </Router>
      <NotificationContainer />
    </Provider>
  );
}

export default App;
