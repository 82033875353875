import React, { Component } from 'react'
import SideBar from './sideBar'
import Routes from './Routes'
import { withRouter } from 'react-router'
import { NotificationContainer } from 'react-notifications';


class Admin extends Component {
    render() {
        return (
            <div>
                <SideBar />
                <Routes />
                <NotificationContainer />
            </div>
        )
    }
}

export default withRouter(Admin)
