import React, { Component } from 'react'
import Header from '../common/Header'
import Footer from "../common/Footer";
import Routes from './Routes'
import { withRouter } from 'react-router'
import { NotificationContainer } from 'react-notifications';



class Frontend extends Component {


    render() {
        return (
            <div>
                <Header />
                <Routes />
                <NotificationContainer />
                <Footer />
            </div>
        )
    }
}

export default withRouter(Frontend)
