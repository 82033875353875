import React, { Component } from 'react'
import { postBlock } from '../../../store/actions/adminActions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Spinner from '../../common/Spinner';
import { getBase64 } from '../../common/Base64'
import { NotificationManager } from 'react-notifications';

class AddBlock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            mainImage: '',
            image2: "",
            image3: "",
            image4: "",
            name: "",
            description1: '',
            description2: '',
            sizes: [
                {
                    size: '',
                    weight: '',
                    density: ''
                }
            ]
        }
    }

    handleValidation() {
        const fields = this.state;
        let formIsValid = true;

        if (!fields["name"]) {
            formIsValid = false;
            NotificationManager.error('Name field is required')
        }

        if (!fields["description1"]) {
            formIsValid = false;
            NotificationManager.error('Description 1 field is required')
        }


        if (!fields["description2"]) {
            formIsValid = false;
            NotificationManager.error('Description 2 field is required')
        }

        if (!fields["mainImage"]) {
            formIsValid = false;
            NotificationManager.error('Main image is required')
        }



        if (fields["sizes"].length === 0) {
            formIsValid = false;
            NotificationManager.error('sizes field is required')
        }
        else {
            fields["sizes"].map(item => {
                if (!item["size"]) {
                    formIsValid = false;
                    NotificationManager.error('color field is required')
                }

                if (!item["weight"]) {
                    formIsValid = false;
                    NotificationManager.error('weight field is required')
                }

                if (!item["density"]) {
                    formIsValid = false;
                    NotificationManager.error('density field is required')
                }
            })
        }

        return formIsValid;
    }

    submitHandler = async (e) => {
        e.preventDefault()

        if (!this.handleValidation()) {
            return
        }

        const mainImage = await getBase64(this.state.mainImage);
        const image2 = await getBase64(this.state.image2);
        const image3 = await getBase64(this.state.image3);
        const image4 = await getBase64(this.state.image4);

        const data = {
            name: this.state.name,
            description: {
                description1: this.state.description1,
                description2: this.state.description2
            },
            sizes: this.state.sizes,
            mainImage,
            detailImages: {
                image2,
                image3,
                image4
            }
        }
        this.props.postBlock(data, this.props.history)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.hist) {
            if (props.match.path != props.hist.path) {
                props.changematch();
                props.history.push(props.hist.path);
            }
        }

        const { loading } = props.admin;

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        return state;
    }


    addSizes = () => {
        const add = {
            size: '',
            weight: '',
            density: ''
        }
        this.setState({
            sizes: [...this.state.sizes, add]
        })
    }

    removeSizes = index => {
        const oldsizes = [...this.state.sizes];

        oldsizes.splice(index, 1);

        this.setState({
            sizes: oldsizes
        })
    }

    onChange = (e) => {
        if ((e.target.name === "description1" && e.target.value.length > 1000) || (e.target.name === "description2" && e.target.value.length > 1000)) {
            return
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    ChangeSize = (key, event) => {
        const sizes = [...this.state.sizes]
        sizes[key][event.target.name] = event.target.value

        this.setState({
            sizes: sizes
        })
    }

    imageHandler = event => {
        // console.log("event" ,  event.target.files)
        this.setState({
            [event.target.name]: event.target.files[0]
        })

    }

    render() {
        const { loading, name, description1, description2, sizes } = this.state;

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="card contact-card">
                    <div className="card-header">
                        Add Blocks
                    </div>
                    <form onSubmit={this.submitHandler}>
                        <div className="card-body">
                            <div className="form-group small-section">
                                <label>Block Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={this.onChange}
                                    className="form-control"
                                    placeholder="Enter block Name"
                                />
                            </div>


                            <div className="form-group small-section right-part">
                                <label>Main Image</label>
                                <div className="upload-btn-wrapper">
                                    <button className="btn">{this.state.mainImage?.name ? this.state.mainImage?.name : "Upload an Image"}</button>
                                    <input
                                        type="file"
                                        name="mainImage"
                                        accept="image/*"
                                        onChange={this.imageHandler}
                                    />
                                </div>

                            </div>



                            <div className="form-group small-section">
                                <label>Detail Image 2</label>
                                <div className="form-group">
                                    <div className="upload-btn-wrapper">
                                        <button className="btn">{this.state.image2?.name ? this.state.image2?.name : "Upload an Image"}</button>
                                        <input
                                            type="file"
                                            name="image2"
                                            accept="image/*"
                                            onChange={this.imageHandler}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="form-group small-section right-part">
                                <label>Detail Image 3</label>
                                <div className="form-group">
                                    <div className="upload-btn-wrapper">
                                        <button className="btn">{this.state.image3?.name ? this.state.image3?.name : "Upload an Image"}</button>
                                        <input
                                            type="file"
                                            name="image3"
                                            accept="image/*"
                                            onChange={this.imageHandler}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group small-section" style={{ width: '100%' }}>
                                <div style={{ width: '49%' }} >
                                    <label>Detail Image 4</label>
                                    <div className="form-group">
                                        <div className="upload-btn-wrapper">
                                            <button className="btn">{this.state.image4?.name ? this.state.image4?.name : "Upload an Image"}</button>
                                            <input
                                                type="file"
                                                name="image4"
                                                accept="image/*"
                                                onChange={this.imageHandler}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <label>Enter Size:</label>
                                {sizes.map((item, index) => (<div className="form-group size-fields" key={index}>
                                    <div className="add-more-btn-container">
                                        <span className="add-more-btn" onClick={() => this.removeSizes(index)}>Remove -</span>
                                    </div>

                                    <div className="dimension-section">
                                        <div className="size-field-container">
                                            <label>Size (mm):</label>
                                            <input
                                                type="text"
                                                name="size"
                                                onChange={(e) => { this.ChangeSize(index, e) }}
                                                value={item.size}
                                                className="form-control"
                                                placeholder="Enter size"
                                            />
                                        </div>
                                        <div className="size-field-container">
                                            <label>weight (kg):</label>
                                            <input
                                                type="text"
                                                name="weight"
                                                value={item.weight}
                                                onChange={(e) => { this.ChangeSize(index, e) }}
                                                className="form-control"
                                                placeholder="Enter weight"
                                            />
                                        </div>
                                        <div className="size-field-container">
                                            <label>Depth (kg/m<sup>3</sup>):</label>
                                            <input
                                                type="text"
                                                name="density"
                                                value={item.density}
                                                onChange={(e) => { this.ChangeSize(index, e) }}
                                                className="form-control"
                                                placeholder="Enter depth"
                                            />
                                        </div>
                                    </div>
                                </div>
                                ))}
                                <div className="add-more-btn-container">
                                    <span className="add-more-btn" onClick={() => this.addSizes()}>Add more +</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Add Block Description 1:</label>
                                <textarea
                                    className="form-control"
                                    name="description1"
                                    value={description1}
                                    onChange={this.onChange}
                                    placeholder="Enter block description 1"

                                >
                                </textarea>
                            </div>

                            <div className="form-group">
                                <label>Add Block Description 2:</label>
                                <textarea
                                    className="form-control"
                                    name="description2"
                                    value={description2}
                                    onChange={this.onChange}
                                    placeholder="Enter block description 2"

                                >
                                </textarea>
                            </div>

                        </div>

                        <div className="card-footer">
                            <button type="submit" className="btn submit-btn mr-2">Submit</button>
                            <button type="reset" className="btn cancel-btn" onClick={() => this.props.history.push("/admin/blocks")}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="page-overlay"></div>
                        <div className="content-header">
                            <div className="top-content">
                                <h5> Add Blocks</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}

AddBlock.propTypes = {
    postBlock: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});


export default connect(mapStateToProps, { postBlock })(withRouter(AddBlock))