import React, { Component } from 'react'
import { getCareerResponse, deleteCareerForm } from '../../../store/actions/adminActions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import Spinner from '../../common/Spinner';
import * as moment from 'moment'

class CareerDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            careerResponse: []
        }
    }
    componentDidMount() {
        this.props.getCareerResponse()
    }

    static getDerivedStateFromProps(props, state) {
        const { loading, careerResponse } = props.admin;

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }
        if (careerResponse && JSON.stringify(state.careerResponse) !== JSON.stringify(careerResponse)) {
            state.careerResponse = careerResponse
        }

        return state
    }

    render() {
        const { loading, careerResponse } = this.state;

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                {careerResponse.length ? <div className="card contact-card message-response">
                    <form>
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="company-name">Name</th>
                                        <th scope="col" className="contact-no">Country</th>
                                        <th scope="col" className="email">Number</th>
                                        <th scope="col" className="designation">Designation</th>
                                        <th scope="col" className="message-col">Messages</th>
                                        <th scope="col" className="email">Apply Date</th>
                                        <th scope="col" className="action-col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {careerResponse.map((item, key) => {
                                        return <tr key={key}>
                                            <td>{item.name}</td>
                                            <td>{item.country}</td>
                                            <td>{item.number}</td>
                                            <td>{item.designation}</td>
                                            <td>{item.message}</td>
                                            <td>{moment(item.created_at).format("DD-MMMM-YYYY")}</td>
                                            <td className="action-btn">
                                                <button type="button" className="form-control" onClick={() => this.props.history.push(`/admin/view-career-details/${item.id}`)}>View</button>
                                                <button type="button" onClick={() => { if (window.confirm('Are you sure?')) { this.props.deleteCareerForm(item.id) } }} className="form-control"> Delete</button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div> : <h2>No Record is Available</h2>}
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Career Forms</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}

CareerDetail.propTypes = {
    getCareerResponse: PropTypes.func.isRequired,
    deleteCareerForm: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { getCareerResponse, deleteCareerForm })(withRouter(CareerDetail))
