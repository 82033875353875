import React, { Component } from 'react'
import { getProductParagraph, getAllBlock, getAllInterlocks } from '../../../store/actions/publicActions'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
class Products extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paragraph: null,
            blocks: [],
            interlocks: []
        }
    }

    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        this.props.getProductParagraph()
        this.props.getAllBlock()
        this.props.getAllInterlocks()
    }

    static getDerivedStateFromProps(props, state) {
        const { productParagraph, blocks, interlocks } = props.admin

        if (productParagraph.paragraph && JSON.stringify(productParagraph.paragraph) !== JSON.stringify(state.paragraph)) {
            state.paragraph = productParagraph.paragraph
        }

        if (JSON.stringify(blocks) !== JSON.stringify(state.blocks)) {
            state.blocks = blocks
        }

        if (JSON.stringify(interlocks) !== JSON.stringify(state.interlocks)) {
            state.interlocks = interlocks
        }

        return state
    }

    render() {
        const { paragraph, interlocks, blocks } = this.state
        console.log('blocks', blocks)
        return (
            <div>
                <div className="products-page-container">
                    <div className="bg-color-products">
                        <div className="col-12 text-center">
                            <div className="title-desc">
                                <h2 className="sec-title mt-5">Our Products</h2>
                                <p className="sec-para">{paragraph}</p>
                            </div>
                        </div>
                    </div>
                    <div className="container our-products">
                        <h2 className="sec-title">Blocks</h2>
                        <div className="blocks-products row m-0">
                            {blocks.map((item, key) => {
                                return <div className="col-md-3 col-12 blocks">
                                    <Link to={`/products/block/${item.id}`}>
                                        <div className="block-img">
                                            <img src={item.mainImage} alt="block" />
                                        </div>
                                        <h3 className="block-name">{item.name}</h3>
                                    </Link>
                                </div>
                            })}

                        </div>
                        <h2 className="sec-title">Interlocks</h2>
                        <div className="blocks-products row m-0">
                            {interlocks.map((item, key) => {
                                return <div className="col-md-3 col-12 blocks" key={key}>
                                    <Link to={`/products/interlock/${item.id}`}>
                                        <div className="block-img">
                                            <img src={item.variety && item.variety[0].image} alt="block" />
                                        </div>
                                        <h3 className="block-name">{item.name}</h3>
                                    </Link>
                                </div>
                            })}

                        </div>
                    </div>

                    <div className="contactus-bg">
                        <div className="over-lay"></div>
                        <div className="container">
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


Products.propTypes = {
    getProductParagraph: PropTypes.func.isRequired,
    getAllBlock: PropTypes.func.isRequired,
    getAllInterlocks: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});
export default connect(mapStateToProps, { getProductParagraph, getAllBlock, getAllInterlocks })(withRouter(Products))