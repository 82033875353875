import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateCategory, getCategory } from '../../../store/actions/adminActions'
import Spinner from '../../common/Spinner';
import { NotificationManager } from 'react-notifications';

class EditCategory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            id: props.match.params.id,
            category: ''
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.hist) {
            if (props.match.path != props.hist.path) {
                props.changematch();
                props.history.push(props.hist.path);
            }
        }

        const { loading, categories } = props.admin

        if (JSON.stringify(loading) !== JSON.stringify(state.loading)) {
            state.loading = loading
        }

        if (categories.length && JSON.stringify(categories) !== JSON.stringify(state.categories)) {
            state.categories = categories
            const index = categories.findIndex(item => item.id === parseInt(state.id))
            if (index > -1) {
                state.category = categories[index].category
            }
        }

        return state
    }

    submitHandler = (e) => {
        e.preventDefault()

        if (!this.state.category) {
            NotificationManager.error('Category field is required')
            return
        }

        const data = {
            id: this.state.id,
            category: this.state.category
        }
        this.props.updateCategory(data, this.props.history)
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        const id = this.props.match.params.id
        this.props.getCategory(id)
    }

    render() {
        const { loading, category } = this.state

        console.log('history', this.props.history)

        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="card contact-card">
                    <div className="card-header">
                        Edit Category
                </div>
                    <form onSubmit={this.submitHandler}>
                        <div className="card-body">
                            <div className="form-group small-section single">
                                <label>Add Category</label>
                                <input type="text" name="category" value={category}
                                    className="form-control" placeholder="Enter category"
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>

                        <div className="card-footer">
                            <button type="submit" className="btn submit-btn mr-2">Submit</button>
                            <button type="reset" className="btn cancel-btn" onClick={() => this.props.history.push("/admin/category")}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        }

        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5> Edit Category</h5>
                            </div>
                        </div>
                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}


EditCategory.prototypes = {
    updateCategory: PropTypes.func.isRequired,
    getCategory: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { updateCategory, getCategory })(withRouter(EditCategory))