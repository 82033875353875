import React, { Component } from 'react'
import { getAboutContent, getAllProject } from '../../../store/actions/publicActions'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
class About extends Component {

    constructor(props) {
        super(props);
        this.state = {
            aboutPage: null,
            projects: [],
        }
    }
    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        this.props.getAboutContent()
        this.props.getAllProject()
    }

    static getDerivedStateFromProps(props, state) {
        const { aboutPage, projects } = props.admin
        if (JSON.stringify(aboutPage) !== JSON.stringify(state.aboutPage)) {
            state.aboutPage = aboutPage
        }
        if (JSON.stringify(projects) !== JSON.stringify(state.projects)) {
            if (projects.length > 2) {
                state.projects = projects.slice(0, 2)
            }
            else {
                state.projects = projects
            }
        }

        return state
    }

    render() {
        const { aboutPage, projects } = this.state
        console.log('about', aboutPage, 'project', projects)
        return (
            <div className="aboutus-page-container">
                <div className="home-bg-img">
                    <div className="container">
                        <div className="row"></div>
                    </div>
                </div>

                <div className="container">
                    <div className="content">
                        <h2 className="sec-title">Dubai Blocks LLC</h2>
                        <h4 className="sec-h5">{aboutPage && aboutPage.mainHeading}</h4>
                        <p className="sec-para">{aboutPage && aboutPage.mainParagraph}</p>

                        <h4 className="sec-h5">{aboutPage && aboutPage.subHeading}</h4>

                        <p className="sec-para">{aboutPage && aboutPage.subParagraph}</p>
                        <div className="cmmn-btn">
                            <Link to="/products">
                                <button className="btn">{aboutPage && aboutPage.Firstbutton}</button>
                            </Link>
                        </div>
                        <p className="sec-para">{aboutPage && aboutPage.subParagraph2}</p>
                    </div>
                    <h2 className="sec-title">Dubai Blocks Projects</h2>
                </div>
                <div className="container our-products">
                    <div className="row m-0">
                        <div className="blocks-our-projects">
                            {projects.map((item, key) => {
                                return <div className="our-project pr-0" key={key}>
                                    <img src={item.image} alt="dubai hotel" />
                                    <p className="project">{item.name}</p>
                                </div>
                            })}

                        </div>
                        <div className="cmmn-btn mt-4">
                            <Link to="/projects">
                                <button className="btn">{aboutPage && aboutPage.button2}</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="contactus-bg">
                    <div className="over-lay"></div>
                    <div className="container">
                    </div>
                </div>
            </div>
        )
    }
}


About.propTypes = {
    getAboutContent: PropTypes.func.isRequired,
    getAllProject: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});
export default connect(mapStateToProps, { getAboutContent, getAllProject })(withRouter(About))