import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllCategory, updateInterlockDesign } from '../../../store/actions/adminActions'
import { getSingleInterlockDesign } from '../../../store/actions/publicActions'
import { getBase64, uploadedImageName } from '../../common/Base64'
import Spinner from '../../common/Spinner'
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';

class EditInterlockDesign extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: [],
            id: props.match.params.id,
            name: '',
            selectedCategories: [],
            image: '',
            imageName: '',
            material: [],
            colors: [],
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { categories, interlockDesigns } = props.admin
        const interlockDesign = interlockDesigns[0]

        if (props.hist) {
            if (props.match.path != props.hist.path) {
                props.changematch();
                props.history.push(props.hist.path);
            }
        }

        if (JSON.stringify(categories) !== JSON.stringify(state.categories)) {
            state.categories = categories
        }

        if (interlockDesign && JSON.stringify(interlockDesign) !== JSON.stringify(state.interlockDesign)) {
            console.log(interlockDesign, "interlockDesign")
            state.interlockDesign = interlockDesign
            state.selectedCategories = interlockDesign.category_id.map((el) => ({ value: el?.category_id?.id, label: el?.category_id?.category }))
            state.material = interlockDesign.material
            state.imageName = interlockDesign.image
            
            let colorsArr = [];
            if (interlockDesign.color1) {
                colorsArr.push(interlockDesign.color1)
            }
            if (interlockDesign.color2) {
                colorsArr.push(interlockDesign.color2)
            }
            if (interlockDesign.color3) {
                colorsArr.push(interlockDesign.color3)
            }
            if (interlockDesign.color4) {
                colorsArr.push(interlockDesign.color4)
            }
            if (interlockDesign.color5) {
                colorsArr.push(interlockDesign.color5)
            }
            state.colors = colorsArr;
        }
        return state
    }

    componentDidMount() {
        const id = this.props.match.params.id
        this.props.getSingleInterlockDesign(id)
        this.props.getAllCategory()
    }


    imageHandler = (e) => {
        let files = e.target.files[0];
        this.setState({
            filename: files.name,
            image: files
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleValidation() {
        const fields = this.state;
        let formIsValid = true;

        if (!fields["selectedCategories"]) {
            formIsValid = false;
            NotificationManager.error('Category field is required')
        }

        if (!fields["material"] && !fields["material"].length) {
            formIsValid = false;
            NotificationManager.error('Material field is required')
        }
        if (!fields["colors"] && !fields["colors"].length) {
            formIsValid = false;
            NotificationManager.error('Colors field is required')
        }


        return formIsValid;
    }

    submitHandler = async (e) => {
        e.preventDefault()

        if (!this.handleValidation()) {
            return
        }

        const image = await getBase64(this.state.image);

        const data = {
            id: this.state.id,
            category_id: this.state.selectedCategories.map((el) => el.value),
            image,
            material: [...this.state.material],
        }
        this.state.colors.filter((el) => el).forEach((color, i) => {
            data[`color${i + 1}`] = color;
        })
        // console.log("updated data", data)
        this.props.updateInterlockDesign(data, this.props.history)
    }

    addMaterial = () => {
        this.setState({
            material: [...this.state.material, ""]
        })
    }

    onMaterialChange = (key, event) => {
        const material = [...this.state.material]
        material[key] = event.target.value
        this.setState({
            material: material
        })
    }

    addColor = () => {
        if(this.state.colors.length < 5 ) {
            this.setState({
                colors: [...this.state.colors, ""]
            })
        }
    }

    onColorChange = (key, event) => {
        const colors = [...this.state.colors]
        colors[key] = event.target.value
        this.setState({
            colors: colors
        })
    }

    removeColor = index => {
        const oldcolor = [...this.state.colors];

        oldcolor.splice(index, 1);
        this.setState({
            colors: oldcolor
        })
    }

    removeMaterial = index => {
        const oldmaterial = [...this.state.material];

        oldmaterial.splice(index, 1);
        this.setState({
            material: oldmaterial
        })
    }

    customStyles = {
        control: (provided, state) => ({
          ...provided,
          background: '#fff',
          borderColor: '#9e9e9e',
          minHeight: '40px',
          height: '40px',
          boxShadow: state.isFocused ? null : null,
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          height: '40px',
          padding: '0 6px'
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: '0px',
        }),
        indicatorSeparator: state => ({
          display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: '40px',
        }),
      };

    render() {
        const { loading, selectedCategories, categories, material, colors } = this.state
        let pageContent;
        if (loading) {
            pageContent = <Spinner />
        }
        else {
            pageContent = <div className="dashboard-main-content">
                <div className="card contact-card">
                    <form onSubmit={this.submitHandler}>
                        <div className="card-body">

                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div class="form-group">
                                    <label>Select Categories:</label>
                                    <Select 
                                        isMulti 
                                        styles={this.customStyles}
                                        value={selectedCategories}
                                        options={categories.map((item, key) => {
                                            return { value: item.id, label: item.category }
                                        })} 
                                        onChange={(val) => {
                                            this.setState({selectedCategories: val})
                                        }}
                                        className="multi-categories"
                                    />
                                    </div>
                                    {colors?.length ? colors.map((el, i) => <div className={`form-group`}>
                                        <span className="add-more-btn" onClick={() => this.removeColor(i)}>Remove -</span>
                                        <label>Select Color {i + 1}</label>
                                        <input
                                            type="color"
                                            name={`color`}
                                            value={el}
                                            onChange={(e) => this.onColorChange(i, e)}
                                            placeholder={`Choose color ${i + 1}`}
                                            className="form-control"
                                        />
                                    </div>) : null}
                                    <div> {colors.length < 5 ? <span onClick={this.addColor} className="add-more-btn">Add color +</span> : null}</div>
                                </div>
                                <div className="col-12 col-md-6">
                                {material?.length ? material.map((el, i) => <div className={`form-group inside-remove`}>
                                <span className="add-more-btn" onClick={() => this.removeMaterial(i)}>Remove -</span>

                                <label>Enter Material {i + 1}</label>
                                <input
                                    type="text"
                                    name={`material`}
                                    value={el}
                                    onChange={(e) => this.onMaterialChange(i, e)}
                                    className="form-control"
                                    placeholder="Enter your material"
                                />
                            </div>) : null}
                            <span onClick={this.addMaterial} className="add-more-btn">Add more +</span>
                                </div>
                            </div>

                            <div className="form-group small-section upload-file">
                                <div className="upload-content">
                                    <label>Pattern Image</label>
                                    <div className="form-group">
                                        <div className="upload-btn-wrapper">
                                            <button className="btn">{this.state?.image?.name ?? uploadedImageName(this.state.imageName)}</button>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={this.imageHandler}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn submit-btn mr-2">Submit</button>
                            <button type="reset" className="btn cancel-btn" onClick={() => this.props.history.push("/admin/interlock-design")}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        }


        return (
            <div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="content-header">
                            <div className="top-content">
                                <h5>Edit Interlock Design</h5>
                            </div>
                        </div>

                        {pageContent}
                    </div>
                </div>
            </div>
        )
    }
}

EditInterlockDesign.prototypes = {
    getAllCategory: PropTypes.func.isRequired,
    updateInterlockDesign: PropTypes.func.isRequired,
    getSingleInterlockDesign: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, { getAllCategory, updateInterlockDesign, getSingleInterlockDesign })(withRouter(EditInterlockDesign))