import React, { Component } from 'react'
import { getContactUsContent, postContact } from '../../../store/actions/publicActions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

// const IFrame = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6801.503478095964!2d74.34218322304469!3d31.53097787029326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391904e556d7eee1%3A0xe3eabb251a6f86d7!2sMain%20Gulberg%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1614322715864!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy">.</iframe>'
class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contactUsPage: null,
            email: '',
            type: 'Company',
            name: '',
            number: "",
            message: "",
            location: ""
        }

    }
    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        this.props.getContactUsContent()

    }

    initMap() {
        // const myLatLng = { lat: -25.363, lng: 131.044 };
        // const map = new google.maps.Map(document.getElementById("map"), {
        //     zoom: 4,
        //     center: myLatLng,
        // });
        // new google.maps.Marker({
        //     position: myLatLng,
        //     map,
        //     title: "Hello World!",
        // });
    }



    static getDerivedStateFromProps(props, state) {
        const { contactUsPage } = props.admin;
        if (JSON.stringify(contactUsPage) !== JSON.stringify(state.contactUsPage)) {
            console.log('contactUsPage', contactUsPage)
            state.contactUsPage = contactUsPage
        }
        return state
    }

    onSubmitHandler = (e) => {
        e.preventDefault()
        const data = {
            email: this.state.email,
            type: this.state.type,
            name: this.state.name,
            number: this.state.number,
            message: this.state.message,
            location: this.state.location,
            advertisement: this.state.advertisement,
        }
        console.log('data', data)
        this.props.postContact(data)
            .then(res => {
                if (res) {
                    this.setState({
                        email: '',
                        type: 'Company',
                        name: '',
                        number: "",
                        message: "",
                        location: "",
                        advertisement: ""
                    })
                }
            });
    }


    onChange = (e) => {
        e.preventDefault()
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    render() {
        const { contactUsPage, email, type, message, number, name, location, advertisement } = this.state
        return (
            <div className="contactus-page-container">
                <div className="bg-image">
                    <div className="over-lay">
                        <div className="container contactus">
                            <div className="row m-0">
                                <div className="col-lg-6 col-12 contact">
                                    <h2 className="sec-title">Get In Touch</h2>
                                    <div className="phone-email">
                                        <div className="contact-number-mail">
                                            <h5 className="h-6">Phone</h5>
                                            <p className="sec-para">
                                                <a href={`tel:${contactUsPage && contactUsPage.phone}`}>{contactUsPage && contactUsPage.phone}</a>

                                            </p>
                                        </div>
                                        <div className="contact-number-mail">
                                            <h5 className="h-6">Email</h5>

                                            {contactUsPage && contactUsPage.email && contactUsPage.email.length && contactUsPage.email.map((item, key) => {
                                                return <p className="sec-para" key={key}>
                                                    <a href={`mailto:${item.email}`}>{item.email}</a>
                                                </p>
                                            })}

                                            <p className="sec-para">
                                                <a href="/#">&nbsp;</a>
                                            </p>

                                        </div>
                                        {/* <div className="row"> */}
                                            {/* <div className="fax-bottom contact-number-mail">
                                                <h5 className="h-6">Fax</h5>
                                                <p className="sec-para">{contactUsPage && contactUsPage.fax}</p>
                                            </div> */}
                                            <div className="contact-number-mail w-100">
                                                <h5 className="h-6">Address</h5>
                                                <p className="sec-para">{contactUsPage && contactUsPage.address}</p>
                                            </div>
                                        {/* </div> */}
                                        <div className="contact-number-mail">
                                            <h4 className="sec-h5">HR DEPARTMENT</h4>
                                            <h5 className="h-6">Email</h5>
                                            <p className="sec-para">
                                                <a href={`mailto:${contactUsPage && contactUsPage.hrEmail}`}>{contactUsPage && contactUsPage.hrEmail}</a>
                                            </p>
                                        </div>
                                        <div className="contact-number-mail">
                                            <h4 className="sec-h5">SALES DEPARTMENT</h4>
                                            <h5 className="h-6">Email</h5>
                                            <p className="sec-para">
                                                <a href={`mailto:${contactUsPage && contactUsPage.saleEmail}`}>{contactUsPage && contactUsPage.saleEmail}</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="map mob-hide-form col-md-6 col-12">

                        <div className="form">
                            <form onSubmit={this.onSubmitHandler}>
                                <h2 className="sec-title">Contact Us</h2>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput1">Email address</label>
                                    <input type="email"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Email address"
                                        name="email"
                                        value={email}
                                        required
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="contact-us-radio">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="type" id="exampleRadios1" value="Company" onChange={this.onChange} checked={type === "Company"} />
                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                            Company
                            </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="type" id="exampleRadios2" value="Home owner" onChange={this.onChange} checked={type === "Home owner"} />
                                        <label className="form-check-label" htmlFor="exampleRadios2">
                                            Home owner
                            </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput1">Name</label>
                                    <input type="text"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter name"
                                        value={name}
                                        name="name"
                                        required
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput1">Contact No</label>
                                    <input type="tel" className="form-control"
                                        id="exampleFormControlInput1" placeholder="Enter contact number"
                                        name="number"
                                        value={number}
                                        required
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput1">Project Location</label>
                                    <input type="text"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter project location"
                                        value={location}
                                        name="location"
                                        required
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>How Did You Hear About Us:</label>
                                    <select
                                        name="advertisement"
                                        value={advertisement}
                                        className="form-control"
                                        style={{ height: "40px" }}
                                        onChange={this.onChange}
                                        required
                                        >
                                        <option value=''>Please Select</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Linkedin">LinkedIn</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="Google Ad">Google Ads</option>
                                        <option value="Friends">Friends</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlTextarea1">Your message</label>
                                    <textarea className="form-control"
                                        id="textarea1" rows="3" placeholder="Tell us about request"
                                        name="message"
                                        value={message}
                                        onChange={this.onChange}
                                        // style={{fontSize: }}
                                        required
                                    ></textarea>
                                </div>
                                <div className="cmmn-btn">
                                    <button className="btn" type="submit">SEND</button>
                                </div>
                            </form>
                        </div>

                    </div>


                </div>
                <div className="live-map">
                    <div className="mobile-design"></div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3596.609661196967!2d55.78577550000001!3d25.6510775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjXCsDM5JzAzLjkiTiA1NcKwNDcnMDguOCJF!5e0!3m2!1sen!2s!4v1616676653737!5m2!1sen!2s?q=77,69" className="iframe-style" id='iframe_id' allowfullscreen="" loading="lazy">
                    </iframe>
                </div>
                <div className="contactus-footer">
                </div>
            </div>
        )
    }
}



Contact.propTypes = {
    getContactUsContent: PropTypes.func.isRequired,
    postContact: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    admin: state.admin
});
export default connect(mapStateToProps, { getContactUsContent, postContact })(withRouter(Contact))